

import Vue from 'vue'

export default Vue.extend(
  {
    name: 'phoneInput',
    props: {
      value: {
        required: true,
        type: String
      },
      valueWithDial: {
        required: true,
        type: String
      },
      isValid: {
        required: true,
        type: Boolean
      },
      disabled: {
        default: false,
        type: Boolean
      }
    },
    data () {
      return {
        isFocused: false,
        firstTimeFocused: false,
        haveError: false,
        translations: {
          countrySelectorLabel: '',
          countrySelectorError: 'countrySelectorError',
          phoneNumberLabel: ''
        }
      }
    },
    methods: {
      onUpdate (data: any) {
        this.$emit('update:isValid', data.isValid)
        if (data.e164) {
          this.$emit('update:valueWithDial', data.e164)
        }
      },
      emitValue (value: string | null) {
        if (typeof value === 'string') {
          this.firstTimeFocused = true
          this.$emit('input', value)
        } else {
          this.$emit('update:isValid', false)
          this.$emit('input', '')
        }
      }
    },
    mounted () {
      const phoneInput = this.$refs.phoneInput.$el.querySelector('.input-tel__input') as HTMLInputElement
      if (phoneInput) {
        phoneInput.placeholder = this.$t('registration.phone')
      }
    }
  }
)
