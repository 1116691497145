
import Vue from 'vue'
import { mapGetters } from 'vuex'
import { LevelStatuses } from '../../types'
import loadLine from './../loadLine/index.vue'

export default Vue.extend({
  name: 'kyc-basic',
  data () {
    return {
      openedPanel: null as number | null
    }
  },
  props: {
    status: {
      type: String,
      required: true,
      default: ''
    },
    levelData: {
      type: Object,
      required: true,
      default: null
    }
  },
  mounted () {
    const openedPanelValue = this.status === LevelStatuses.inProgress ? 0 : 1
    this.openedPanel = openedPanelValue
  },
  computed: {
    ...mapGetters('user', ['getUserData'])
  },
  components: { loadLine }
})
