var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('vue-scroll-component',{staticClass:"account",attrs:{"onlyVertical":"","scrollTo":_vm.scrollTo}},[_c('h2',{staticClass:"account_header mb-3"},[_vm._v(_vm._s(_vm.getUserFullName))]),(_vm.getUserData.promo)?_c('div',{staticClass:"referal-link mb-5"},[_vm._v(" "+_vm._s(_vm.$t('registration.refCode'))+" "),_c('div',{staticClass:"referal-link__content"},[_c('v-tooltip',{attrs:{"bottom":"","max-width":"196"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" "+_vm._s(_vm.getUserData.promo)+" ")])]}}],null,false,193336132)},[_c('span',{staticClass:"referal-link__tooltip"},[_vm._v(_vm._s(_vm.tooltipPromocode))])])],1)]):_vm._e(),_c('div',{staticClass:"account_element",class:{
      'mb-3': _vm.maw(460),
      'mb-10': _vm.miw(460)
    }},[_c('socialNetworks')],1),_c('accessRepairStatus',{attrs:{"accessRepairData":_vm.accessRepairData,"accessRepairAccordionStatus":_vm.accessRepairOpen},on:{"updateInfo":_vm.getAccessInfo,"hideAccessRepairPanel":() => _vm.accessRepairOpen = false}}),_vm._l((_vm.getPassport),function(notification){return _c('div',{key:notification.id,staticClass:"account_element account_element--card",class:{
      'mb-3': _vm.maw(460),
      'mb-6': _vm.miw(460)
    }},[_c('PassportNotification',{attrs:{"notification":notification}})],1)}),_c('div',{staticClass:"account_element account_element--card",class:{
      'mb-3': _vm.maw(460),
      'mb-6': _vm.miw(460)
    }},[(_vm.getUserData.verificationRights.levelName)?_c('kyc',{attrs:{"kycLevels":_vm.kycLevels,"kycAccordionStatus":_vm.kycOpen},on:{"hideKycPanel":function($event){return _vm.changeKycAccordionStatus(false)},"openAccessRepair":function($event){_vm.kycOpen = true},"getAccessInfo":_vm.getAccessInfo}}):_vm._e()],1),_c('div',{staticClass:"account_element account_element--card mb-5"},[_c('safety',{attrs:{"currentKycLevelData":_vm.currentKycLevelData,"changeKycAccordionStatus":_vm.changeKycAccordionStatus},on:{"onExtendedVerify":function($event){return _vm.changeKycAccordionStatus(true)},"scrollToRepair":_vm.scrollToCurrentRepair}})],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }