import Vue from 'vue'
import { IQuestionItem } from '@/components/pages/whitelistID/components/account/components/access-repair/accessRepairQuestionnaire/accessRepairQuestions/types'

export const coinsListMixin = Vue.extend({
  watch: {
    'form.fields.questionnaire.spotCoins': {
      handler (newState) {
        this.form.fields.questionnaire.spotCoins.valid = newState.subfields.some((item: any) => item.name.length > 0 && item.value > 0) || newState.value
      },
      deep: true
    },
    'form.fields.questionnaire.futuresCoins': {
      handler (newState) {
        this.form.fields.questionnaire.futuresCoins.valid = newState.subfields.some((item: any) => item.name.length > 0 && item.value > 0) || newState.value
      },
      deep: true
    }
  },
  methods: {
    toMDspotCoins (field: IQuestionItem): any {
      return this.toMDCoinsList(field)
    },
    toMDfuturesCoins (field: IQuestionItem): any {
      return this.toMDCoinsList(field)
    },
    toMDCoinsList (field: IQuestionItem): any {
      if (!field) return []
      const result = [
        {
          type: 'heading',
          depth: 3,
          children: [
            {
              type: 'text',
              value: field.title
            }
          ]
        }
      ]
      const table: any = {
        type: 'table',
        children: [
          {
            type: 'tableRow',
            children: [
              {
                type: 'tableCell',
                children: [
                  {
                    type: 'strong',
                    children: [{ type: 'text', value: 'Монета' }]
                  }
                ]
              },
              {
                type: 'tableCell',
                children: [
                  {
                    type: 'strong',
                    children: [{ type: 'text', value: 'Количество' }]
                  }
                ]
              }
            ]
          }
        ]
      }
      for (const subfield of field.subfields as any) {
        table.children.push({
          type: 'tableRow',
          children: [
            {
              type: 'tableCell',
              children: [
                {
                  type: 'text',
                  value: subfield.name
                }
              ]
            },
            {
              type: 'tableCell',
              children: [
                {
                  type: 'text',
                  value: subfield.value
                }
              ]
            }
          ]
        })
      }
      result.push(table)
      return result
    }
  }
})
