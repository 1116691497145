
import Vue from 'vue'
import productItem from './components/product-item/index.vue'

interface IProduct {
  title: string
  content: string
  action?: {
    title: string
    callback: () => void
  }
}

export default Vue.extend({
  name: 'aboutPage',
  data: () => ({}),
  computed: {
    products (): IProduct[] {
      return [
        {
          title: this.$t('main.aboutDeveloper.productsItems.personalAccount.title'),
          content: this.$t('main.aboutDeveloper.productsItems.personalAccount.content')
        }
      ]
    }
  },
  components: {
    productItem
  }
})
