export enum ActivePopupTypes {
  action = 'action',
  confirm = 'confirm',
  info = 'info'
}

export interface IActivePopupActions {
  title: string,
  isAccent: boolean,
  callback: void
}

export interface ActivePopupInterface {
  type: ActivePopupTypes,
  letDefaultClose: boolean,
  title: string,
  content?: string,
  slot?: {
    name: string,
    data: any,
    on: any
  },
  actions: IActivePopupActions[],
  onClose?: () => unknown
}
