import axios, { AxiosError } from 'axios'
import SERVICE_CONSTANTS from '@/constants/services'
import Router from '@/router/index'
import Cookies from 'js-cookie'
import jwtDecode from 'jwt-decode'
import moment from 'moment'

enum Statuses {
  unauthorized = 401
}

let isRefreshing = false

const checkServerError = (error: any): boolean => error.response.status >= 500 && error.response.status <= 526

const a = axios.create({
  baseURL: SERVICE_CONSTANTS.BASE_URL,
  withCredentials: true
})

const resetJWTR = (): void => {
  Cookies.remove('jwtr')
}

async function tryRefresh (error: AxiosError): Promise<any> {
  const token = Cookies.get('jwtr')
  if (token) {
    const refreshResponse = await a.post(SERVICE_CONSTANTS.BASE_URL + '/token/refresh/', {
      refresh: token
    })
    if (refreshResponse.status === 200 && refreshResponse.data.refresh) {
      const refresh = refreshResponse.data.refresh
      const location = window.location
      const host = location.hostname.split('.').reverse()[1] + '.' + location.hostname.split('.').reverse()[0]
      Cookies.set('jwtr', refresh, {
        expires: moment.unix((jwtDecode(refresh) as any).exp).toDate(),
        domain: '.' + host
      })
      return await a.request(error.config)
    } else resetJWTR()
  } else resetJWTR()

  isRefreshing = false
  return Promise.reject(error)
}

a.interceptors.response.use((response) => {
  return Promise.resolve(response)
}, async (error) => {
  if (error.response.status === Statuses.unauthorized) {
    if (!isRefreshing) {
      isRefreshing = true
      const result = await tryRefresh(error)
      isRefreshing = false
      return result
    }
  } else if (checkServerError(error)) {
    Router.replace({ name: 'NamedErrorPage', params: { code: error.response.status } })
  } else return Promise.reject(error)
})

// @ts-ignore
export default a
