import { ActionTree, GetterTree, MutationTree } from 'vuex'
import {
  RawAlertInterface,
  AlertInterface
} from '@whitelist/wl-old-library/components/global/alert/types'
import { nanoid } from 'nanoid'

const namespaced = true

class State {
  items: Array<AlertInterface> = []
}

const getters = <GetterTree<State, any>> {
  getAlertsList: state => state.items
}

const actions = <ActionTree<State, any>> {
  openAlert ({ getters, commit }, payload: RawAlertInterface): AlertInterface['id'] {
    const id = nanoid(8)
    commit('addAlert', {
      id,
      ...payload
    })
    if (payload.autoDelete) {
      setTimeout(() => {
        commit('removeAlert', id)
      }, 5000)
    }
    return id
  },
  removeAlert ({ commit, state }, payload: AlertInterface['id']) {
    const neededAlertIndex = state.items.findIndex((item: AlertInterface) => {
      if (item.id === payload) {
        return item
      }
    })

    const alert = state.items[neededAlertIndex]

    if (alert.onClose !== undefined) {
      alert.onClose()
    }

    commit('removeAlert', neededAlertIndex)
  }
}

const mutations = <MutationTree<State>> {
  addAlert (state, payload) {
    state.items.push(payload)
  },
  removeAlert (state, payload: number) {
    state.items.splice(payload, 1)
  }
}

export default {
  namespaced,
  state: new State(),
  actions,
  getters,
  mutations
}
