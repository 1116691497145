
import Vue from 'vue'
import LegalInfoPanel from '../../expansion-panel/index.vue'
import LegalInfoPanelContent from '../../expansion-panel-content/index.vue'
import { mapGetters } from 'vuex'
import { LegalInformationTypes } from '@/components/pages/whitelistID/components/legalInfo/components/panels/types'

export default Vue.extend({
  name: 'legalInfoPanelPolicy',
  components: { LegalInfoPanel, LegalInfoPanelContent },
  props: {
    section: {
      type: Object,
      required: true
    },
    sectionHeight: {
      type: Number,
      required: true
    }
  },
  computed: {
    ...mapGetters('user', ['getUserData']),
    sectionHaveChanges () {
      return this.getUserData.docs?.userUnsignedDocuments?.includes(LegalInformationTypes.cookiePolicy)
    }
  },
  methods: {
    acceptChanges () {
      this.$emit('acceptChanges', LegalInformationTypes.cookiePolicy)
    },
    onChange () {
      this.$emit('onChange')
    }
  }
})
