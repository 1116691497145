
import Vue from 'vue'
import { emailAllowedSymbolsRegExp } from '@/constants/baseConstatnts'

export default Vue.extend({
  name: 'fullnameAndEmail',
  watch: {
    'form.valid': {
      handler (value: boolean) {
        this.$emit('valid', value)
      }
    }
  },
  props: {
    subfields: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      form: {
        valid: false,
        fields: {
          fullName: {
            value: '',
            rules: [(v: string) => v.length > 0 || this.$t('accessRepair.errors.required')]
          },
          email: {
            value: '',
            rules: [
              (v: string) =>
                Boolean(v.length) || this.$t('login.errors.loginRequired'),
              (v: string) => {
                return (
                  emailAllowedSymbolsRegExp.test(v.trim()) || this.$t('login.errors.emailIncorrect')
                )
              }
            ]
          }
        }
      }
    }
  }
})
