export default `
    <body style="color: white, background-color: transparent">
      <h1 style="padding-top: 4pt;padding-left: 5pt;text-indent: 0pt;text-align: left;">Privacy Policy</h1>
      <p style="text-indent: 0pt;text-align: left;"><br/></p>
      <h1 style="padding-top: 9pt;padding-left: 5pt;text-indent: 0pt;text-align: left;">Introduction:</h1>
      <p style="text-indent: 0pt;text-align: left;"><br/></p>
      <p style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">WHITELIST PTE. LTD (&quot;WHITELIST&quot;, &quot;Operator&quot;, &quot;we&quot;, &quot;our&quot; or &quot;us&quot;) collects information about you from various sources to provide the Services to you, to protect our legitimate interests, to analyze and improve our Services, to communicate with by you and to comply with our legal and regulatory obligations. This Privacy Policy (&quot;Privacy Policy&quot;) governs our treatment of personally identifiable information that we collect when you access or use our Services.</p>
      <p style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">From time to time, we may revise or amend this Privacy Policy to reflect changes in the law, our methods of collecting and using Personal Information, features of the Services, or advances in technology. This Privacy Policy does not apply to the activities of organizations that we do not own or control, or people that we do not control.</p>
      <p style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">Our Privacy Policy applies to all Services we offer, but excludes any products, applications or services that have separate privacy policies that do not include this Privacy Policy.</p>
      <p style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">Access to and/or use of our Services implies acceptance of this Privacy Policy and the terms and conditions included in the Terms of Service. Terms used in this Privacy Policy have the same meanings as in the Terms of Service, except as otherwise provided herein.For the avoidance of doubt, the terms are:</p>
      <p style="text-indent: 0pt;text-align: left;"><br/></p>
      <p style="padding-left: 5pt;text-indent: 3pt;text-align: left;"><b>&quot;Services&quot; </b>means all services provided by WHITELIST, including, but not limited to, the Website <u>https://wlc.capital/</u>, a service for making internal as well as external funds transfers, closing positions, canceling limit orders, adjusting leverage for any ticker, tracking financial results, current available and total deposits in the spot and futures wallet, transfer history, both internal and external, and any other services provided by WHITELIST from time to time (“Services”). <b>&quot;Communication Channels</b>&quot; means social networks, instant messengers, e-mail, and / or any Operator data entry field used by the Operator for publishing information, interacting with Users, etc.</p>
      <p style="text-indent: 0pt;text-align: left;"><br/></p>
      <h1 style="padding-left: 5pt;text-indent: 0pt;text-align: left;">What data do we collect:</h1>
      <p style="text-indent: 0pt;text-align: left;"><br/></p>
      <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">To the maximum extent permitted by applicable data protection laws, we collect the following data and information (collectively, &quot;Personal Data»):</p>
      <p style="text-indent: 0pt;text-align: left;"><br/></p>
      <p style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">We may track, record and store your personal information to protect your safety or the safety of other customers, comply with relevant laws, assist with regulatory or law enforcement efforts, protect and defend our rights and property, or for other purposes related to our provision of the Services. By using the Services, you consent to the recording, storage and disclosure of such information that you send or receive for these purposes.</p>
      <p style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">The Services are not available to persons under eighteen (18) years of age (“Minors”). WHITELIST will never knowingly or intentionally collect Personal Data from minors.If you are the parent or guardian of a minor and believe or know that the minor has provided us with Personal Information, please contact us immediately. If we become aware that we have collected Personal Data from minors with or without verification of parental consent, we will take steps to remove that information from our systems.</p>
      <p style="text-indent: 0pt;text-align: left;"><br/></p>
      <h1 style="padding-left: 5pt;text-indent: 0pt;text-align: left;">How We Collect Personal Data:</h1>
      <p style="text-indent: 0pt;text-align: left;"><br/></p>
      <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">To the maximum extent permitted by applicable data protection laws, we collect Personal Data about you and any other party whose data you provide to us when you:</p>
      <p style="text-indent: 0pt;text-align: left;"><br/></p>
      <p style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">We will also collect Personal Data if you only partially complete and/or opt out of any information entered through our Services and/or other online forms and may use this information to contact you to remind you of the need to fill in any remaining information and/or for marketing purposes.</p>
      <p style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">We may also collect information from your devices (including mobile devices) and applications that you or your users use to access and use our Services (for example, we may collect device identification number and type, location information, and connection information, such as statistics of your page views, traffic to and from the Services, referral URL, advertising data, your Internet Protocol (IP) address, your browsing history, and your web log information), and we will</p>
      <p style="padding-top: 4pt;padding-left: 5pt;text-indent: 0pt;text-align: justify;">ask for your permission, before we do it. We may do this using cookies or similar technologies (as described below).</p>
      <p style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">If you intend to provide us with Personal Data about someone else, you are responsible for ensuring that you comply with any obligations and consent obligations under applicable data protection laws. To the extent required by applicable data protection laws, you should ensure in advance that you have a clear sense of providing them.How we use your personal data:</p>
      <p style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">To the maximum extent permitted under applicable data protection laws, we use your information: When using WHITELIST communication channels, the processing of Personal Data will be governed by the policies of the communication channels used, as well as their terms of use, privacy policies and rules that belong to the relevant communication channel, in each case, and those terms and conditions that were previously accepted by you.</p>
      <p style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">WHITELIST will process your Personal Data in order to properly manage your presence in the communication channels used, inform them about WHITELIST activities, Services, as well as for any other purposes permitted by the rules of the communication channels used.</p>
      <p style="text-indent: 0pt;text-align: left;"><br/></p>
      <h1 style="padding-left: 5pt;text-indent: 0pt;text-align: left;">Parties with whom we may share your Personal Data:</h1>
      <p style="text-indent: 0pt;text-align: left;"><br/></p>
      <h1 style="padding-left: 5pt;text-indent: 0pt;text-align: left;">Data storage:</h1>
      <p style="text-indent: 0pt;text-align: left;"><br/></p>
      <p style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">We will retain your Personal Data for as long as necessary for the purposes of performing a contract, complying with our legal and regulatory obligations, and protecting our legitimate interests. In particular, we reserve the right to retain your Personal Data for the purposes of complying with applicable Know Your Customer, anti-money laundering, anti-terrorist financing and anti-corruption laws and regulations for at least seven (7) years. , as well as for the purposes of litigation, complaints and disputes for as long as necessary to protect our legitimate interests.</p>
      <p style="padding-left: 5pt;text-indent: 0pt;line-height: 12pt;text-align: justify;">Persons who may access your Personal Data:</p>
      <p style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">Authorized WHITELIST personnel, including but not limited to our Compliance Officers, who must have access to your Personal Data as needed. Our compliance officers and other authorized personnel are bound by confidentiality and non-disclosure agreements and adhere to strict company policies regarding data access and use.</p>
      <p style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">Third Party Service Providers to assist WHITELIST with the implementation of the KYC/AML and CTF Policies and compliance with applicable regulations - Corruption Laws and Regulations. Where applicable, we enter into a data processing agreement with these third parties to ensure data security and the protection of your personal data.Such data processors will only process your Personal Data to the extent necessary to provide the services for which they are engaged.</p>
      <p style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">We may share your Personal Data with competent authorities upon their request to the extent required by law or to the extent necessary to protect our rights in legal proceedings or investigations.</p>
      <p style="text-indent: 0pt;text-align: left;"><br/></p>
      <h1 style="padding-left: 5pt;text-indent: 0pt;text-align: left;">Data security:</h1>
      <p style="text-indent: 0pt;text-align: left;"><br/></p>
      <p style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">To protect your Personal Information, WHITELIST takes all reasonable precautions and follows industry best practices to prevent loss, misuse, unauthorized access, disclosure, alteration or destruction.</p>
      <p style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">In addition to the purposes described in this section, we may also use the information we collect to provide you with targeted and interest-based advertising, marketing (including in-product messaging), or information that may be useful to you based on your use of the Services. or any other information we have about you.</p>
      <p style="text-indent: 0pt;text-align: left;"><br/></p>
      <h1 style="padding-left: 5pt;text-indent: 0pt;text-align: left;">Content from other Services:</h1>
      <p style="text-indent: 0pt;text-align: left;"><br/></p>
      <p style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">The Services may include embedded content (such as videos, images, articles, etc.). The embedded content of other Internet sites behaves exactly as if you were visiting other Internet sites.</p>
      <p style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">These websites may collect data about you, use cookies, embed additional third-party tracking code, and track your interactions with this code.</p>
      <p style="text-indent: 0pt;text-align: left;"><br/></p>
      <h1 style="padding-left: 5pt;text-indent: 0pt;text-align: left;">What are cookies? and how we collect them:</h1>
      <p style="padding-top: 4pt;padding-left: 5pt;text-indent: 0pt;text-align: justify;">Cookies are files or pieces of information that may be stored on your computer (or other Internet-enabled devices such as a smartphone or tablet) when you visit a website and/or use a mobile application. This type of file usually contains the name of the website/mobile application (hereinafter referred to as the “site”) from which it was obtained, the “lifetime” of the file (for example, how long it will remain on the device), and a value that represents how usually a randomly generated unique number.</p>
      <p style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">The Operator may use other technologies, including web beacons and JavaScript, which sometimes work in conjunction with cookies and other means to uniquely identify your device. These other technologies help you to fully enjoy the features on our sites. The operator may also use this kind of technology to determine if the user has opened the email/clicked on a link contained in the email.</p>
      <p style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">When visiting a website, the user may inadvertently receive cookies from other websites or web servers that present so-called &quot;third-party&quot; cookies.This is because the site you visit may contain elements such as images, maps, sound files, links to individual web pages on different domains located on other servers.</p>
      <p style="text-indent: 0pt;text-align: left;"><br/></p>
      <h1 style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">Classification of cookies:</h1>
      <p style="text-indent: 0pt;text-align: left;"><br/></p>
      <p style="padding-left: 5pt;text-indent: 0pt;line-height: 12pt;text-align: justify;">Cookies can be divided into two main groups: &quot;technical&quot; cookies and &quot;profile&quot; cookies.</p>
      <p style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">1 Technical cookies, which are used exclusively to better run the site from a technical point of view, to carry out the transmission of a message over an electronic communication network, or to the extent strictly necessary to provide the services that have been requested by the user. Technical cookies may be grouped into browsing or session cookies that allow users to navigate and use the site (for example, to authenticate themselves to access certain areas);</p>
      <p style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">2 analytical cookies, which can be compared to technical cookies, as they are used directly by the site operator to collect aggregate information about the number of visitors and visits to the site; functional cookies that allow users to navigate through pre-set criteria, such as language or products, in order to improve the quality of the service.</p>
      <p style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">2 Profiling and Marketing cookies can be used to send commercial offers after some visits to the site. Profiling cookies are used to create user profiles to send messages, ads according to user preferences during navigation.</p>
      <p style="text-indent: 0pt;text-align: left;"><br/></p>
      <h1 style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">Use of cookies:</h1>
      <p style="text-indent: 0pt;text-align: left;"><br/></p>
      <p style="padding-left: 5pt;text-indent: 0pt;line-height: 12pt;text-align: left;">The operator may use cookies to operate the site, including:</p>
      <ul id="l1">
        <li data-list-text="-">
          <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">to maintain statistics and track the total number of visitors to the websites and / or mobile applications of the Operator on an anonymous basis;</p>
        </li>
        <li data-list-text="-">
          <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">to improve the site and provide the User with the opportunity to individually configure the services and functions of the Operator&#39;s sites;</p>
        </li>
        <li data-list-text="-">
          <p style="padding-left: 13pt;text-indent: -7pt;line-height: 12pt;text-align: left;">to recognize new and old customers;</p>
        </li>
        <li data-list-text="-">
          <p style="padding-left: 13pt;text-indent: -7pt;line-height: 12pt;text-align: left;">to provide access to the personal account during the working session;</p>
        </li>
        <li data-list-text="-">
          <p style="padding-left: 13pt;text-indent: -7pt;line-height: 12pt;text-align: left;">to manage competitions and incentive events organized by the Operator;</p>
        </li>
        <li data-list-text="-">
          <p style="padding-left: 13pt;text-indent: -7pt;line-height: 12pt;text-align: left;">to provide users with targeted advertising;</p>
          <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">-for dynamic monitoring of the actions of site visitors and work in browsers when visiting different websites or using other platforms;</p>
        </li>
        <li data-list-text="-">
          <p style="padding-left: 13pt;text-indent: -7pt;line-height: 12pt;text-align: left;">in order to better understand the interests of our customers and website visitors;</p>
        </li>
        <li data-list-text="-">
            <p style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">to achieve other goals stipulated by the Operator&#39;s Policy regarding the processing of personal data</p>
            <p style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">Tracking technologies can be either permanent (meaning they stay on your computer or device until you delete them) or temporary (meaning they last only until you close your browser). Please note that the Operator&#39;s websites may have built-in buttons that allow users to share content on social networks. When you visit a page that displays one or more of these buttons, your browser will establish a direct connection to the relevant social network server and download the button from there. At the same time, the social network will know that the respective page on our websites has been visited.The Operator does not, however, control the distribution of cookies and you should check the respective third party website for more information.</p>
            <p style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">Your further use of the services of the Operator and / or services of the Operator&#39;s website means the consent of the subject of personal data to the processing of his cookies, which is valid for the entire period of using the services of this site (but not less than the period of existence of the account / personal page of the subject of personal data on the site), for the purpose specified in</p>
            <p style="padding-top: 4pt;padding-left: 5pt;text-indent: 0pt;text-align: justify;">section III of this Policy, as well as familiarization and consent of the subject of personal data with this Policy and the conditions for processing personal data specified therein.</p>
            <p style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">In case of refusal to process cookies, the subject of personal data is informed of the need to stop using the site or disable cookies in the browser settings.</p>
            <p style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">In addition, the consent of the subject of personal data to the processing of his cookies may be fully or partially withdrawn at any time by sending a corresponding written request to the Operator.</p>
            <p style="text-indent: 0pt;text-align: left;"><br/></p>
            <p style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">For information on how you can disable cookies, please visit the following links (depending on your web browser type):</p>
            <p style="padding-top: 11pt;padding-left: 5pt;text-indent: 0pt;line-height: 12pt;text-align: left;">-Chrome (https://support.google.com/accounts/answer/61416?hl=en)</p>
            <p style="padding-left: 5pt;text-indent: 0pt;line-height: 12pt;text-align: left;">-Firefox(https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences)</p>
            <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">-IE (https://support.microsoft.com/en-us/help/17442/windows-internet-explorer-delete-manage-cookies)</p>
            <p style="padding-left: 5pt;text-indent: 0pt;line-height: 12pt;text-align: left;"><a href="http://www.opera.com/help/tutorials/security/privacy/)" class="a" target="_blank">-Opera </a><a href="http://www.opera.com/help/tutorials/security/privacy/)" target="_blank">(https://www.opera.com/help/tutorials/security/privacy/)</a></p>
            <p style="padding-left: 5pt;text-indent: 0pt;line-height: 12pt;text-align: left;">-Safari (https://support.apple.com/kb/ph21411?locale=ru_RU)</p>
        </li>
        <li data-list-text="-">
          <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">A n d r o i d  ( h t t p s : / / s u p p o r t . g o o g l e . c o m / a c c o u n t s / a n s w e r / 6 1 4 1 6 ? co=GENIE.Platform=Android&amp;hl=en)</p>
        </li>
      </ul>
      <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;"><a href="http://www.allaboutcookies.org/" class="a" target="_blank">You can find more information about cookies on the websites </a><a href="http://www.allaboutcookies.org/" target="_blank">www.allaboutcookies.org/</a></p>
      <p style="padding-left: 5pt;text-indent: 0pt;line-height: 12pt;text-align: left;"><a href="http://www.youronlinechoices.eu/">www.youronlinechoices.eu/.</a></p>
      <p style="text-indent: 0pt;text-align: left;"><br/></p>
      <h1 style="padding-left: 5pt;text-indent: 0pt;text-align: left;">Navigation:</h1>
      <p style="text-indent: 0pt;text-align: left;"><br/></p>
      <p style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">When accessing and/or using the Services, non-identifying data may be collected, which may include IP address, geolocation, service and Services usage record, browsing habits and other data that cannot be used to identify the User.</p>
      <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">The Services use the following third-party analytical services: Yandex Metrica, Google Analytics. WHITELIST uses the information received to obtain statistical data, analyze trends, administer the Services, study navigation patterns.</p>
      <p style="text-indent: 0pt;text-align: left;"><br/></p>
      <h1 style="padding-left: 5pt;text-indent: 0pt;text-align: left;">Accuracy and Reliability of Personal Data:</h1>
      <p style="text-indent: 0pt;text-align: left;"><br/></p>
      <p style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">You agree that the information provided by you is true, complete, accurate and up to date. You are solely responsible for the validity and correctness of the data you provide while accessing and/or using the Services, indemnifying WHITELIST from any liability in this regard.</p>
      <p style="text-indent: 0pt;text-align: left;"><br/></p>
      <h1 style="padding-left: 5pt;text-indent: 0pt;text-align: left;">Acceptance and Consent:</h1>
      <p style="text-indent: 0pt;text-align: left;"><br/></p>
      <p style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">You declare that you have been informed about the conditions for the protection of Personal Data, you accept and consent to their processing by WHITELIST in the manner and for the purposes specified in this Privacy Policy.</p>
      <p style="text-indent: 0pt;text-align: left;"><br/></p>
      <h1 style="padding-left: 5pt;text-indent: 0pt;text-align: left;">Recall Possibility:</h1>
      <p style="text-indent: 0pt;text-align: left;"><br/></p>
      <p class="s1" style="padding-left: 5pt;text-indent: 0pt;text-align: justify;"><a href="mailto:info@wlc.capital" class="a" target="_blank">To exercise the rights of access, rectification, deletion of data, you must send an email to </a>info@wlc.capital<span class="p"> along with a valid identification document, such as a government-issued identification document. The exercise of your rights does not include any Personal Data that WHITELIST is required to keep for administrative purposes, contract performance, legal or security purposes.</span></p>
      <p style="text-indent: 0pt;text-align: left;"><br/></p>
      <h1 style="padding-left: 5pt;text-indent: 0pt;text-align: left;">Operator change:</h1>
      <p style="text-indent: 0pt;text-align: left;"><br/></p>
      <p style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">In the event of registration, acquisition, merger or any other reason that causes a change in the Operator of the Services, you expressly agree that your registration data and information will be transferred by WHITELIST to the new Operator. When and if this happens, WHITELIST will in any case fulfill the obligation to provide you with information.</p>
      <h1 style="padding-top: 4pt;padding-left: 5pt;text-indent: 0pt;text-align: left;">Changes to the Privacy Policy:</h1>
      <p style="text-indent: 0pt;text-align: left;"><br/></p>
      <p style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">WHITELIST reserves the right to modify this Privacy Policy to adapt it to changes in legislation as well as industry practice. WHITELIST may notify you of changes to this Privacy Policy by email or through the Services.</p>
      <p style="text-indent: 0pt;text-align: left;"><br/></p>
      <h1 style="padding-left: 5pt;text-indent: 0pt;text-align: left;">Service Operator:</h1>
      <p style="text-indent: 0pt;text-align: left;"><br/></p>
      <p style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">Services are operated by WHITELIST PTE. Ltd Reg. number: 202124937Z limited liability company, registered office: 6 EU TONG SEN STREET #09-09 THE CENTRAL SINGAPORE (059817)</p>
      <p style="text-indent: 0pt;text-align: left;"><br/></p>
      <h1 style="padding-left: 5pt;text-indent: 0pt;text-align: left;">Contacts:</h1>
      <p style="text-indent: 0pt;text-align: left;"><br/></p>
      <p style="padding-left: 5pt;text-indent: 0pt;text-align: justify;"><a href="mailto:info@wlc.capital" class="a" target="_blank">If you have any questions or complaints, including about the Privacy Policy, you can contact our Data Protection Officer by email </a><a href="mailto:info@wlc.capital" class="s2" target="_blank">info@wlc.capital</a><a href="mailto:info@wlc.capital" target="_blank">.</a></p>
    </body>`
