const location = window.location

const host = location.host.split('.').slice(1).join('.')

export const serviceEnvironmentName = (
  document.querySelector('meta[name="service-environment"]') as { content: string } | null ?? { content: 'whitelist' }
).content

interface ServiceEnvironmentConfig {
  companyID: string,
  companyName: string,
  companyAbrv: string,
  isAboutShown: boolean,
  isDiscordRequired: boolean
}

interface ServiceEnvironmentConfigs {
  [environment: string]: ServiceEnvironmentConfig
}

const serviceEnvironments: ServiceEnvironmentConfigs = {
  whitelist: {
    companyID: 'Whitelist ID',
    companyName: 'Whitelist',
    companyAbrv: 'WL',
    isAboutShown: true,
    isDiscordRequired: false
  },
  leagueoftraders: {
    companyID: 'League of Traders ID',
    companyName: 'League of Traders',
    companyAbrv: 'LoT',
    isAboutShown: false,
    isDiscordRequired: true
  }
}

export const isTrustedEnvironment = serviceEnvironmentName !== 'whitelist' && Object.keys(serviceEnvironments).includes(serviceEnvironmentName)

const domains: {[key: string]: string} = {
  passport: 'https://passport.' + host,
  traderProfile: 'https://trade.' + host
}

export const getServiceConfigProperty = function (key: string): string | boolean {
  const environment = isTrustedEnvironment ? serviceEnvironments[serviceEnvironmentName] : serviceEnvironments.whitelist
  // @ts-ignore
  const res = environment[key]
  if (res !== undefined) {
    return res
  } else {
    throw new Error(`Атрибут ${key} не является частью конфига`)
    return ''
  }
}

export enum StepByStepGlossary {
  resetPassword = 'resetPassword',
  login = 'login',
  setGA = 'setGA',
  submitSecondFactor = 'submitSecondFactor',
  submitOperations = 'submitOperations',
  unsetGA = 'unsetGA',
  sendConfirmCode = 'sendConfirmCode',
  changePassword = 'changePassword',
  deleteYubikey = 'deleteYubikey',
  validateSession = 'validateSession',
  confirmSession = 'confirmSession',
  addYubikey = 'add_yubikey',
  delete2FA = 'delete2FA',
  changeEmail = 'changeEmail',
  changePhone = 'changePhone',
  disable2FAOnEnter = 'disable2FAOnEnter'
}

export enum BrowserName {
  firefox = 'firefox',
  samsung = 'samsung',
  opera = 'opera',
  explorer = 'explorer',
  edgeold = 'edgeold',
  edge = 'edge',
  chromium = 'cromium',
  safari = 'safari',
  unknown = 'unknown'
}

export default {
  PASSPORT_URL: process.env.NODE_ENV === 'development' ? 'https://passport.local.host' : domains.passport,
  BASE_URL: process.env.NODE_ENV === 'development' ? 'https://passport.local.host/api/v1' : domains.passport + '/api/v1',
  ACCEPTOR_BASE_URL: process.env.NODE_ENV === 'development' ? 'https://trade.local.host' : domains.traderProfile,
  WEBAUTH_CONNECTION_TIMEOUT: 2 * 60 * 1000,
  CODE_LENGTH: 6,
  ACCESS_REPAIR_TIMEOUT: 1 * 60 * 1000,
  CAPTCHA_KEY: process.env.NODE_ENV === 'development' ? '6LeBRrImAAAAABmRpoVMhAFMmmDwdqNTF6B834UW' : '6LdvPrImAAAAANK1Ok1Vn7tGC97ENarcnsvNLAUz'
}
