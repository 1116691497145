
import { times } from 'lodash'
import moment, { Moment } from 'moment'
import Vue, { PropType } from 'vue'
import { Timer } from '@whitelist/wl-old-library/store/notifications/helpers/actionTypes'

export default Vue.extend({
  name: 'passportNotificationTimer',
  props: {
    timer: {
      type: Object as PropType<Timer>,
      required: true
    }
  },
  data () {
    return {
      now: moment()
    }
  },
  computed: {
    timeLeft (): number {
      return moment(this.timer.end).diff(this.now)
    },
    message (): string {
      let timeString = ''

      if (this.timer.display === 'days') {
        const days = String(Math.ceil(moment.duration(this.timeLeft).asDays()))
        timeString = this.$tc('time.days', days)
      } else if (this.timer.display === 'time') {
        timeString = String(Math.ceil(moment.duration(this.timeLeft).asSeconds()))
      }

      return this.timer.text.replaceAll('{timer}', timeString)
    }
  },
  created () {
    this.timeoutID = window.setInterval(() => { this.now = moment() }, 1000)
  },
  beforeDestroy () {
    window.clearInterval(this.timeoutID)
  }
})
