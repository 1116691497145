
import Vue from 'vue'
import { mapGetters } from 'vuex'
import SERVICES_CONSTANTS from '@/constants/services'
import mobNavigationBar from '@/components/global/layout/header/mobNavigationBar.vue'

export default Vue.extend({
  name: 'headerComponent',
  computed: {
    ...mapGetters('user', ['getIsAuthenticated', 'getUserData']),
    lkBtnDisabled () {
      return this.getUserData.docs?.userUnsignedDocuments?.length && !this.getUserData.docs?.docsPermission
    }
  },
  data: () => ({
    mobNavigationIsOpened: false
  }),
  methods: {
    handleLogoClick (): void {
      const vm = this as any
      if (vm.getIsAuthenticated) {
        if (vm.$route.name === 'whitelistIDUserAccount') {
          vm.$router.go()
        } else {
          vm.$router.push({ name: 'whitelistID' })
        }
      } else {
        if (vm.$route.name === 'SignIn') {
          vm.$router.go()
        } else {
          vm.$router.push({ name: 'SignIn' })
        }
      }
    },
    redirectToAcceptor (): void {
      this.$store.commit('setAppIsRedirecting', true)
      window.open(sessionStorage.getItem('acceptor') ?? SERVICES_CONSTANTS.ACCEPTOR_BASE_URL, '_self')
    },
    preLogout (): void {
      const vm = this as any
      this.mobNavigationIsOpened = false
      this.$store.dispatch('popupModule/openPopup', {
        type: 'confirmPopup',
        letDefaultClose: false,
        title: vm.$t('popups.titles.confirmation'),
        content: vm.$t('popups.content.wouldExit'),
        actions: [
          {
            title: vm.$t('popups.actions.stay'),
            isAccent: false,
            callback () { vm.$store.commit('popupModule/setClosed') }
          },
          {
            title: vm.$t('popups.actions.yepExit'),
            isAccent: true,
            callback () {
              vm.$store.commit('popupModule/setClosed')
              vm.logout()
            }
          }
        ]
      })
    },
    async logout (): Promise<void> {
      const vm = this as any
      const cookie = vm.$cookies.get('jwtr')
      const response = await vm.$services.authorization.user.logout({ refresh: cookie })
      const location = window.location
      const host = location.hostname.split('.').reverse()[1] + '.' + location.hostname.split('.').reverse()[0]
      if (response.success) {
        vm.$cookies.set('jwtr', '', {
          expires: -1,
          domain: '.' + host
        })
        window.location.replace(SERVICES_CONSTANTS.PASSPORT_URL)
      }
    }
  },
  components: {
    mobNavigationBar
  }
})
