
import Vue from 'vue'
import showdown from 'showdown'
import CustomSelect from '@whitelist/wl-old-library/components/global/customSelect/index.vue'
import SERVICE_CONSTANTS from '@/constants/services'
import { declOfNum } from '@/utils/numbers'
import moment from 'moment/moment'
import { LanguageList } from '@/components/pages/whitelistID/components/legalInfo/components/panels/types'

export default Vue.extend({
  name: 'legalInfoPanelContent',
  components: { CustomSelect },
  props: {
    documents: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    searchField: '',
    searchFieldCounter: 0,
    selectedDocumentId: null,
    language: LanguageList.russian,
    languageList: [
      {
        key: 'russian',
        value: LanguageList.russian
      },
      {
        key: 'english',
        value: LanguageList.english
      }]
  }),
  computed: {
    selectedDocument () {
      if (this.documents.length && this.selectedDocumentId === null) {
        return this.documents[0]
      }
      return this.documents.find((document: any) => document.id === this.selectedDocumentId)
    },
    selectedDocumentValue () {
      const localTime = moment(this.selectedDocument.releasedDate).locale('ru').format('DD MMMM YYYY')
      return localTime
    },
    selectedDocumentHref () {
      return `${SERVICE_CONSTANTS.BASE_URL}/documents/${this.selectedDocument.id}/?action=inline`
    },
    documentsSelectList () {
      return this.documents.map((document: any) => {
        const localTime = moment(document.releasedDate).locale('ru').format('DD MMMM YYYY')
        return {
          key: document.id,
          value: localTime
        }
      })
    },
    searchTextInfo () {
      const markdownText = this.language === LanguageList.english ? this.selectedDocument.markdownText : this.selectedDocument.markdownRuText
      let check: any = ''
      try {
        const esc = this.searchField.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&')
        check = new RegExp(esc, 'ig')
      } catch {
        check = this.searchField
      }
      let searchFieldCounter = 0

      const insertMarks = (element: any) => {
        if (element === undefined || !element) return

        if (element.hasChildNodes()) {
          for (let i = 0; i < element.childNodes.length; i++) { insertMarks(element.childNodes[i]) }
        }
        if (element.nodeType === 3) {
          const nv = element.nodeValue
          const regs = check.exec(nv)
          if (nv && regs) {
            const match = document.createElement('MARK')
            match.appendChild(document.createTextNode(regs[0]))
            match.classList.add('text__highlight')
            searchFieldCounter += 1

            const after = element.splitText(regs.index)
            after.nodeValue = after.nodeValue.substring(regs[0].length)
            element.parentNode.insertBefore(match, after)
          }
        }
        return element
      }

      const converter = new showdown.Converter()
      converter.setOption('tables', true)
      if (this.searchField) {
        const el = document.createElement('div')
        el.innerHTML = converter.makeHtml(markdownText)
        const contentEl = insertMarks(el)
        return {
          html: contentEl.outerHTML,
          counter: searchFieldCounter
        }
      }
      return {
        html: converter.makeHtml(markdownText),
        counter: searchFieldCounter
      }
    }
  },
  methods: {
    declOfNum,
    onSelectChange (data: any) {
      this.selectedDocumentId = data.key
    },
    onLanguageSelectChange (data: any) {
      this.language = data.value
    }
  }
})
