
import Vue from 'vue'
import { mapGetters } from 'vuex'
import { LevelName } from '../../kyc/types'
export default Vue.extend({
  name: 'YubikeyErrorPopupChunk',
  data: () => ({}),
  props: {
    slotData: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters('user', ['getUserData'])
  },
  methods: {
    hasntAccessHandler () {
      this.$store.commit('popupModule/setClosed')
      const hasCurrentMethod = this.getUserData.currentAccessRepairMethods.includes('yubikey')
      const vm = this as any
      if (hasCurrentMethod) {
        this.$store.dispatch('alertsBus/openAlert', {
          title: vm.$t('alerts.titles.info'),
          type: 'info',
          autoDelete: true,
          content: vm.$t('alerts.content.gotResetRequest')
        })
        return
      }
      const isBasic = this.getUserData.verificationRights.levelName === LevelName.basic
      if (isBasic) {
        vm.$store.dispatch('popupModule/openPopup', {
          type: 'confirmPopup',
          letDefaultClose: false,
          title: vm.$t('popups.titles.repairYubikey'),
          content: vm.$t('popups.content.extendedVerify'),
          actions: [
            {
              title: vm.$t('popups.actions.toSection'),
              isAccent: true,
              callback () {
                vm.$store.commit('popupModule/setClosed')
                vm.slotData.changeKycAccordionStatus(true)
              }
            },
            {
              title: vm.$t('popups.actions.later'),
              isAccent: false,
              callback () {
                vm.$store.commit('popupModule/setClosed')
              }
            }
          ]
        })
        return
      }
      this.$router.push({ name: 'AccessRepair', query: { secondFactor: 'yubikey', an3K8QkkJb3Mzz: this.slotData.sessionId } })
    }
  }
})
