
import Vue from 'vue'
// @ts-ignore
import VueQRCodeComponent from 'vue-qr-generator'
import { ActivePopupTypes } from '@/store/modules/popup/types'
export default Vue.extend({
  name: 'GASetupContinuePopupChunk',
  props: {
    slotData: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    helpersOpened: false,
    QRToAppstoreOpened: false,
    QRToPlayMarketOpened: false
  }),
  methods: {
    handleClickSubmit (): void {
      const vm = this as any
      const content = this.maw(460) ? '' : vm.$t('popups.content.scanQr')
      vm.$store.dispatch('popupModule/openPopup', {
        type: ActivePopupTypes.action,
        letDefaultClose: true,
        title: vm.$t('popups.titles.connectGA'),
        content,
        slot: {
          name: 'GASetupCompletingPopupChunk',
          data: {
            ...vm.slotData
          }
        }
      })
    }
  },
  components: {
    'qr-code': VueQRCodeComponent
  }
})
