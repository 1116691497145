interface Colors {
  [key: string]: any
}

export default {
  light: {
    SYSTEM: {
      DARK_1: '#ffffff',
      DARK_2: '#D1D1D1',
      DARK_3: '#989898',
      DARK_4: '#343434',
      DARK_5: '#343434',
      DARK_6: '#2E2E2E',
      DARK_7: '#272727',
      DARK_8: '#202020',
      DARK_9: '#1C1C1C'
    },
    ACCENT: {
      BLUE_BASE: '#418FDE',
      BLUE_LIGHT_1: '#69A7E5',
      BLUE_LIGHT_2: '#4E97E0',
      BLUE_DARK_1: '#1C5C9C',
      BLUE_DARK_2: '#1C5C9C',
      GREEN_BASE: '#1D7D5B',
      GREEN_LIGHT: '#2CBA88',
      GREEN_DARK: '#13503A',
      RED_BASE: '#863347',
      RED_LIGHT: '#BF566E',
      RED_DARK: '#5F2432',
      YELLOW: '#FFD54F'
    }
  },
  dark: {
    SYSTEM: {
      DARK_1: '#ffffff',
      DARK_2: '#D1D1D1',
      DARK_3: '#989898',
      DARK_4: '#343434',
      DARK_5: '#343434',
      DARK_6: '#2E2E2E',
      DARK_7: '#272727',
      DARK_8: '#202020',
      DARK_9: '#1C1C1C'
    },
    ACCENT: {
      BLUE_BASE: '#418FDE',
      BLUE_LIGHT_1: '#69A7E5',
      BLUE_LIGHT_2: '#4E97E0',
      BLUE_DARK_1: '#1C5C9C',
      BLUE_DARK_2: '#1C5C9C',
      GREEN_BASE: '#1D7D5B',
      GREEN_LIGHT: '#2CBA88',
      GREEN_DARK: '#13503A',
      RED_BASE: '#863347',
      RED_LIGHT: '#BF566E',
      RED_DARK: '#5F2432',
      YELLOW: '#FFD54F'
    }
  }
} as Colors
