
import Vue, { PropType } from 'vue'
import { Notification } from '@whitelist/wl-old-library/store/notifications/helpers/types'
import PassportNotificationTimer from '../timer/index.vue'
import { ActivePopupTypes } from '@/store/modules/popup/types'
import { parseNotificationString } from '@/constants/notifications'

export default Vue.extend({
  name: 'passportNotification',
  props: {
    notification: {
      type: Object as PropType<Notification>,
      required: true
    }
  },
  data () {
    return {
      KYCLink: {
        link: '',
        loading: true,
        error: false,
        status: 'request',
        id: -1
      }
    }
  },
  methods: {
    async getExtendedLink () {
      this.KYCLink.loading = true
      const kycLink = await this.$services.authorization.user.kycLink('document_face')
      if (!kycLink.success) {
        this.KYCLink.error = true
        return
      }
      this.KYCLink.link = kycLink.data.url
      this.KYCLink.status = kycLink.data.status
      this.KYCLink.id = kycLink.data.id
      this.KYCLink.loading = false
    },
    handleMainAction () {
      const action = this.notification.actions.mainButton
      if (action === undefined) return

      if (action.url.includes('{local:PASSPORT_KYC_URL}')) {
        return this.continueVerify()
      }

      window.open(this.parseNotificationString(action.url), '_blank')
    },
    continueVerify (from: string) {
      const vm = this as any
      if (this.KYCLink.status === 'process') {
        window.open(this.KYCLink.link, '_blank')
        return
      }
      this.$store.dispatch('popupModule/openPopup', {
        type: ActivePopupTypes.action,
        letDefaultClose: true,
        title: 'Подтверждение достоверности предоставляемых данных',
        content: '',
        slot: {
          name: 'privacyPolicyAgreement',
          data: {
            linkData: {
              url: this.KYCLink.link,
              id: this.KYCLink.id,
              status: this.KYCLink.status
            }
          },
          on: {
            onSuccess () {
              vm.kycLink.status = 'process'
              vm.$store.commit('popupModule/setClosed')
            }
          }
        }
      })
    },
    parseNotificationString (body: string) {
      return parseNotificationString(body, this.notification.vars)
    }
  },
  created () {
    this.getExtendedLink()
  },
  components: {
    PassportNotificationTimer
  }
})
