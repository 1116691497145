import Vuex, { GetterTree, MutationTree, ActionTree } from 'vuex'
import popupModule from './modules/popup/index'
import user from './modules/user/index'
import alertsBus from './modules/alertsBus/index'
import notifications from '@whitelist/wl-old-library/store/notifications'
import Vue from 'vue'

interface MessageInstance {
  opened: boolean,
  title: string,
  description: string,
  actionBtn: any,
  retry: boolean
}

interface State {
  userCountryISO: null | string,
  messageInstance: MessageInstance
}

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    appIsRedirecting: false,
    userCountryISO: null,
    messageInstance: {
      opened: false,
      title: '',
      description: '',
      actionBtn: null,
      retry: false
    }
  } as State,
  getters: {
    getUserCountryISO: (state: any) => state.userCountryISO,
    getMessageInstanceState: (state: any) => state.messageInstance,
    getAppIsRedirecting: (state: any) => state.appIsRedirecting
  } as GetterTree<State, any>,
  mutations: {
    setUserCountryISO (state, payload) {
      state.userCountryISO = payload
    },
    setMessageInstanceState (state, payload: any) {
      for (const [key, value] of Object.entries(payload) as [string, any]) {
        (state as any).messageInstance[key] = value
      }
    },
    setAppIsRedirecting (state: any, payload: any) {
      state.appIsRedirecting = payload
    }
  } as MutationTree<State>,
  actions: {
  } as ActionTree<State, any>,
  modules: {
    alertsBus,
    popupModule,
    user,
    notifications
  }
})
