
import Vue from 'vue'
export default Vue.extend({
  name: 'YubikeySetupNamePopupChunk',
  data () {
    return {
      responseErrors: [],
      form: {
        valid: false,
        fields: {
          yubikeyName: {
            value: '',
            rules: [
              (v: string) => v.length > 0 || this.$t('common.errors.required'),
              (v: string) => v.length < 21 || this.$t('common.errors.maxSymbolQuantity', { length: '21' })
            ]
          }
        }
      }
    }
  }
})
