export default `<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
<html xmlns="http://www.w3.org/1999/xhtml" xml:lang="en" lang="en">
 <head>
 <meta http-equiv="Content-Type" content="text/html; charset=utf-8"/>
 <title>file_1661750647172</title>
 <meta name="author" content="Zamzar"/>
 <style type="text/css"> * {margin:0; padding:0; text-indent:0; color: white, background-color: transparent;}
 h1 { color: white; font-family:Arial, sans-serif; font-style: normal; font-weight: bold; text-decoration: none; font-size: 11pt; }
 .p, p { color: white; font-family:Arial, sans-serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 11pt; margin:0pt; }
 .s1 { color: #15C; font-family:Arial, sans-serif; font-style: normal; font-weight: normal; text-decoration: underline; font-size: 11pt; }
 .s2 { color: #15C; font-family:Arial, sans-serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 11pt; }
 .a, a { color: #15C; font-family:Arial, sans-serif; font-style: normal; font-weight: normal; text-decoration: underline; font-size: 11pt; }
 .s3 { color: white; font-family:"Segoe UI Symbol", sans-serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 11pt; }
 .s4 { color: white; font-style: normal; font-weight: normal; text-decoration: none; }
 .s5 { color: #00F; font-family:Arial, sans-serif; font-style: normal; font-weight: normal; text-decoration: underline; font-size: 11pt; }
 .s7 { color: white; font-family:Arial, sans-serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 11pt; }
 li {display: block; }
 #l1 {padding-left: 0pt;counter-reset: c1 1; }
 #l1> li>*:first-child:before {counter-increment: c1; content: counter(c1, decimal)". "; color: white; font-style: normal; font-weight: normal; text-decoration: none; }
 #l1> li:first-child>*:first-child:before {counter-increment: c1 0; }
 #l2 {padding-left: 0pt;counter-reset: c2 1; }
 #l2> li>*:first-child:before {counter-increment: c2; content: counter(c2, lower-latin)". "; color: white; font-style: normal; font-weight: normal; text-decoration: none; }
 #l2> li:first-child>*:first-child:before {counter-increment: c2 0; }
 #l3 {padding-left: 0pt;counter-reset: c2 1; }
 #l3> li>*:first-child:before {counter-increment: c2; content: counter(c2, lower-latin)". "; color: white; font-style: normal; font-weight: normal; text-decoration: none; }
 #l3> li:first-child>*:first-child:before {counter-increment: c2 0; }
 #l4 {padding-left: 0pt;counter-reset: c3 1; }
 #l4> li>*:first-child:before {counter-increment: c3; content: "("counter(c3, lower-roman)") "; color: white; font-family:Arial, sans-serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 11pt; }
 #l4> li:first-child>*:first-child:before {counter-increment: c3 0; }
 #l5 {padding-left: 0pt;counter-reset: c2 1; }
 #l5> li>*:first-child:before {counter-increment: c2; content: counter(c2, lower-latin)". "; color: white; font-style: normal; font-weight: normal; text-decoration: none; }
 #l5> li:first-child>*:first-child:before {counter-increment: c2 0; }
 #l6 {padding-left: 0pt;counter-reset: c3 1; }
 #l6> li>*:first-child:before {counter-increment: c3; content: "("counter(c3, lower-roman)") "; color: white; font-family:Arial, sans-serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 11pt; }
 #l6> li:first-child>*:first-child:before {counter-increment: c3 0; }
 #l7 {padding-left: 0pt;counter-reset: c3 1; }
 #l7> li>*:first-child:before {counter-increment: c3; content: "("counter(c3, lower-roman)") "; color: white; font-family:Arial, sans-serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 11pt; }
 #l7> li:first-child>*:first-child:before {counter-increment: c3 0; }
 #l8 {padding-left: 0pt;counter-reset: c3 1; }
 #l8> li>*:first-child:before {counter-increment: c3; content: "("counter(c3, lower-roman)") "; color: white; font-family:Arial, sans-serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 11pt; }
 #l8> li:first-child>*:first-child:before {counter-increment: c3 0; }
 #l9 {padding-left: 0pt;counter-reset: c2 1; }
 #l9> li>*:first-child:before {counter-increment: c2; content: counter(c2, lower-latin)". "; color: white; font-style: normal; font-weight: normal; text-decoration: none; }
 #l9> li:first-child>*:first-child:before {counter-increment: c2 0; }
 #l10 {padding-left: 0pt;counter-reset: d1 1; }
 #l10> li>*:first-child:before {counter-increment: d1; content: "("counter(d1, upper-latin)") "; color: white; font-family:Arial, sans-serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 11pt; }
 #l10> li:first-child>*:first-child:before {counter-increment: d1 0; }
 #l11 {padding-left: 0pt;counter-reset: c2 1; }
 #l11> li>*:first-child:before {counter-increment: c2; content: counter(c2, lower-latin)". "; color: white; font-style: normal; font-weight: normal; text-decoration: none; }
 #l11> li:first-child>*:first-child:before {counter-increment: c2 0; }
 #l12 {padding-left: 0pt;counter-reset: c2 1; }
 #l12> li>*:first-child:before {counter-increment: c2; content: counter(c2, lower-latin)". "; color: white; font-style: normal; font-weight: normal; text-decoration: none; }
 #l12> li:first-child>*:first-child:before {counter-increment: c2 0; }
 #l13 {padding-left: 0pt;counter-reset: c2 1; }
 #l13> li>*:first-child:before {counter-increment: c2; content: counter(c2, lower-latin)". "; color: white; font-style: normal; font-weight: normal; text-decoration: none; }
 #l13> li:first-child>*:first-child:before {counter-increment: c2 0; }
 </style>
 </head>
 <body>
 <h1 style="padding-top: 4pt;padding-left: 5pt;text-indent: 0pt;line-height: 217%;text-align: left;">TERMS OF USE WLC CRYPTO <span class="p">LAST REVISED: December 15, 2021 IMPORTANT NOTICE:</span></h1>
 <p style="padding-left: 5pt;text-indent: 0pt;line-height: 109%;text-align: justify;">YOU SHOULD CAREFULLY READ THIS TERMS OF USE (”TERMS”) BEFORE USING WLC CRYPTO SOFTWARE. IF YOU DO NOT AGREE WITH ALL OF THE TERMS, YOU NEED TO STOP USING WLC CRYPTO SOFTWARE.</p>
 <p style="text-indent: 0pt;text-align: left;"><br/></p>
 <p style="padding-left: 5pt;text-indent: 0pt;line-height: 109%;text-align: justify;">Your use of the WLC CRYPTO is licensed, not sold, to you, and you hereby acknowledge that no title or ownership with respect to the Software is being transferred or assigned and this Terms should not be construed as a sale of any rights. All access to and use of the WLC CRYPTO is subject to these Terms, Privacy Policy, software documentation and Binance Terms of Use.</p>
 <p style="text-indent: 0pt;text-align: left;"><br/></p>
 <p style="padding-left: 5pt;text-indent: 0pt;line-height: 109%;text-align: justify;">You must be and hereby affirm that you are an adult of the legal age of majority in your country and state of residence. If you are under the legal age of majority, your parent or legal guardian must consent to this Terms.You guarantee that you are not under the control of jurisdiction that explicitly prohibits the use of similar software</p>
 <p style="text-indent: 0pt;text-align: left;"><br/></p>
 <ol id="l1">
 <li data-list-text="1.">
 <h1 style="padding-left: 41pt;text-indent: -18pt;text-align: left;">DEFINITIONS</h1>
 <p style="text-indent: 0pt;text-align: left;"><br/></p>
 <ol id="l2">
 <li data-list-text="a.">
 <p style="padding-left: 41pt;text-indent: -18pt;text-align: justify;">“Licensor” - WHITELIST PTE. LTD. the company organized and existing under the laws of Republic of Singapore, having its principal place of business at: 6 EU TONG SEN STREET #09-09 THE CENTRAL SINGAPORE (059817) (company No 202124937Z).</p>
 <p style="text-indent: 0pt;text-align: left;"><br/></p>
 </li>
 <li data-list-text="b.">
 <p style="padding-left: 41pt;text-indent: -18pt;text-align: justify;">“User” or “You” - means adult of the legal age current registered Account user of WLC Crypto.</p>
 <p style="text-indent: 0pt;text-align: left;"><br/></p>
 </li>
 <li data-list-text="c.">
 <p class="s1" style="padding-left: 41pt;text-indent: -18pt;text-align: justify;"><span class="p">“WLC Crypto” or “Software” - is software located at an Internet address </span>https://<span class="s2"> </span>wlc.capital<span class="s2"> </span><span class="p">designed for the Users interaction with the Binance cryptocurrency exchange.</span></p>
 <p style="text-indent: 0pt;text-align: left;"><br/></p>
 </li>
 <li data-list-text="d.">
 <p style="padding-left: 41pt;text-indent: -18pt;text-align: left;">“Website” - website of the Software location <span class="s1">https://wlc.capital</span></p>
 <p style="text-indent: 0pt;text-align: left;"><br/></p>
 </li>
 <li data-list-text="e.">
 <p style="padding-left: 41pt;text-indent: -18pt;text-align: left;">“Account” <b>- </b>personal account of a registered user for using the Software.</p>
 <p style="text-indent: 0pt;text-align: left;"><br/></p>
 </li>
 <li data-list-text="f.">
 <p style="padding-left: 41pt;text-indent: -18pt;text-align: justify;">“Binance” - refers to an ecosystem comprising Binance websites (whose domain names include but are not limited to <span class="s1">https://binance.com</span>), mobile applications, clients, applets and other applications that are developed to offer Binance Services, and includes independently-operated platforms, websites and clients within the ecosystem (e.g. Binance’s Open Platform, Binance Launchpad, Binance Labs, Binance Charity, Binance DEX, Binance X, JEX, Trust Wallet, and fiat gateways).</p>
 <p style="text-indent: 0pt;text-align: left;"><br/></p>
 </li>
 </ol>
 </li>
 <li data-list-text="2.">
 <h1 style="padding-left: 41pt;text-indent: -18pt;text-align: left;">GRANT OF LICENSE.</h1>
 <p style="text-indent: 0pt;text-align: left;"><br/></p>
 <ol id="l3">
 <li data-list-text="a.">
 <p style="padding-left: 41pt;text-indent: -18pt;line-height: 109%;text-align: justify;">If you accept and comply with the terms of use, Licensor will grant, and you will receive, a limited non-sub licensable, and non-exclusive license to use the Software subject to the “License Limitations,” set forth below.</p>
 <p style="text-indent: 0pt;text-align: left;"><br/></p>
 </li>
 <li data-list-text="b.">
 <p style="padding-left: 41pt;text-indent: -18pt;line-height: 109%;text-align: justify;">License Limitations. Licensor may suspend or terminate your license to use the Software, or parts, components and/or single features thereof. B hereunder if you do</p>
 <p style="padding-top: 4pt;padding-left: 41pt;text-indent: 0pt;line-height: 109%;text-align: justify;">not comply with the Agreement, the Code of Conduct and other applicable Terms mentioned in this Agreement, and/or if you violate, or assist others in violating, the specific license limitations set forth below. In case of such a license suspension or termination you will not be entitled to any compensation in regard to the respective suspended or terminated license. You agree that you will not, in whole or in part or under any circumstances, do the following:</p>
 <p style="text-indent: 0pt;text-align: left;"><br/></p>
 <ol id="l4">
 <li data-list-text="(i)">
 <p style="padding-left: 41pt;text-indent: 0pt;line-height: 109%;text-align: justify;">Derivative Works: Copy or reproduce, translate, reverse engineer, derive source code from, modify, disassemble, decompile, or create derivative works based on or related to the Software.</p>
 <p style="text-indent: 0pt;text-align: left;"><br/></p>
 </li>
 <li data-list-text="(ii)">
 <p style="padding-left: 41pt;text-indent: 0pt;line-height: 109%;text-align: justify;">Prohibited Commercial Uses: Exploit, in its entirety or individual components, the Software for any purpose not expressly authorized by Licensor, including, without limitation.</p>
 <p style="text-indent: 0pt;text-align: left;"><br/></p>
 </li>
 <li data-list-text="(iii)">
 <p style="padding-left: 41pt;text-indent: 0pt;line-height: 109%;text-align: justify;">Cheating: Create, use, offer, promote, advertise, make available and/or distribute the following or assist therein:</p>
 <p style="text-indent: 0pt;text-align: left;"><br/></p>
 <p style="padding-left: 41pt;text-indent: 0pt;line-height: 109%;text-align: justify;">cheats; i.e. methods not expressly authorized by Licensor (whether accomplished using hardware, software, a combination thereof, or otherwise), influencing and/or facilitating the using, including exploits of any bugs, and thereby granting you and/or any other user an advantage over others not using such methods;</p>
 <p style="text-indent: 0pt;text-align: left;"><br/></p>
 <p style="padding-left: 41pt;text-indent: 0pt;line-height: 109%;text-align: justify;">bots; i.e. any code and/or software, not expressly authorized by Licensor, that allows the automated control of Software, or any other feature of the Software;</p>
 <p style="text-indent: 0pt;text-align: left;"><br/></p>
 <p style="padding-left: 41pt;text-indent: 0pt;line-height: 109%;text-align: justify;">hacks; i.e. accessing or modifying the software of the Software in any manner not expressly authorized by Software;</p>
 <p style="text-indent: 0pt;text-align: left;"><br/></p>
 <p style="padding-left: 41pt;text-indent: 0pt;line-height: 109%;text-align: justify;">any code and/or software, not expressly authorized by Licensor, that can be used in connection with the Software and/or any component or feature thereof which changes and/or facilitates the gameplay or other functionality;</p>
 <p style="text-indent: 0pt;text-align: left;"><br/></p>
 </li>
 <li data-list-text="(iv)">
 <p style="padding-left: 41pt;text-indent: 0pt;line-height: 109%;text-align: justify;">Data Mining: Use any unauthorized process or software that intercepts, collects, reads, or “mines” information generated or stored by the Software; provided, however, that Licensor may, at its sole and absolute discretion, allow the use of certain third-party user interfaces.</p>
 <p style="text-indent: 0pt;text-align: left;"><br/></p>
 </li>
 <li data-list-text="(v)">
 <p style="padding-left: 41pt;text-indent: 0pt;line-height: 109%;text-align: justify;">Duplicated Items: Create, utilize or transact in any item created or copied by exploiting a design flaw, undocumented problem, or program bugs in the Software.</p>
 <p style="text-indent: 0pt;text-align: left;"><br/></p>
 </li>
 <li data-list-text="(vi)">
 <p style="padding-left: 41pt;text-indent: 0pt;line-height: 109%;text-align: justify;">Unauthorized Connections: Facilitate, create or maintain any unauthorized connection to the Software including without limitation any connection using third-party programs or tools not expressly authorized by Licensor.</p>
 <p style="text-indent: 0pt;text-align: left;"><br/></p>
 </li>
 <li data-list-text="(vii)">
 <p style="padding-left: 41pt;text-indent: 0pt;line-height: 109%;text-align: justify;">Transfers: Attempt to sell, sublicense, rent, lease, grant a security interest in or otherwise transfer any copy of the Software or component thereof, or your rights to the Software to any other party in any way not expressly authorized herein.</p>
 </li>
 <li data-list-text="(viii)">
 <p style="padding-top: 4pt;padding-left: 41pt;text-indent: 0pt;line-height: 109%;text-align: left;">Disrupting or assisting in (i) the disruption of any computer used to support the Software;</p>
 <p style="text-indent: 0pt;text-align: left;"><br/></p>
 <p style="padding-left: 41pt;text-indent: 0pt;text-align: left;">(viii) Violation of Laws: use the Software to violate any applicable law or regulation.</p>
 <p style="text-indent: 0pt;text-align: left;"><br/></p>
 </li>
 </ol>
 </li>
 <li data-list-text="c.">
 <p style="padding-left: 41pt;text-indent: -18pt;line-height: 109%;text-align: justify;">The licensor has the right to transfer its rights and obligations under this agreement to another third party without prior notice.</p>
 <p style="text-indent: 0pt;text-align: left;"><br/></p>
 </li>
 </ol>
 </li>
 <li data-list-text="3.">
 <h1 style="padding-left: 41pt;text-indent: -18pt;text-align: left;">ACCOUNT REGISTRATION AND REQUIREMENTS</h1>
 <p style="text-indent: 0pt;text-align: left;"><br/></p>
 <ol id="l5">
 <li data-list-text="a.">
 <p style="padding-left: 41pt;text-indent: -18pt;line-height: 109%;text-align: justify;">All Users must apply for a WLC Crypto Account at Website before using Software. All users upon registration are required to provide accurate information about themselves: real name, email address, phone number and password, and accept these Terms and the Privacy Policy.</p>
 <p style="text-indent: 0pt;text-align: left;"><br/></p>
 </li>
 <li data-list-text="b.">
 <p style="padding-left: 41pt;text-indent: -18pt;line-height: 109%;text-align: justify;">All Users undertakes to read and accept with the terms of use of Binance on the <a href="http://www.binance.com/en/terms" class="a" target="_blank">https://</a><a href="http://www.binance.com/en/terms" target="_blank">www.binance.com/en/terms</a></p>
 <p style="text-indent: 0pt;text-align: left;"><br/></p>
 </li>
 <li data-list-text="c.">
 <p style="padding-left: 41pt;text-indent: -18pt;line-height: 109%;text-align: justify;">Licensor may refuse, in its discretion, to open a Licensor Account for you. You agree to provide complete and accurate information opening a Licensor Account, and agree to timely update any information you provide to Licensor to maintain the integrity and accuracy of the information.</p>
 <p style="text-indent: 0pt;text-align: left;"><br/></p>
 <p style="padding-left: 61pt;text-indent: 0pt;text-align: center;">By registering to use a Licensor Account, you represent and warrant that</p>
 <p style="text-indent: 0pt;text-align: left;"><br/></p>
 <ol id="l6">
 <li data-list-text="(i)">
 <p style="padding-left: 62pt;text-indent: 0pt;line-height: 109%;text-align: justify;">as an individual, you are at least 18 or are of legal age to form a binding contract under applicable laws;</p>
 <p style="text-indent: 0pt;text-align: left;"><br/></p>
 </li>
 <li data-list-text="(ii)">
 <p style="padding-left: 62pt;text-indent: 0pt;line-height: 109%;text-align: justify;">as an individual, legal person, or other organization, you have full legal capacity and sufficient authorizations to enter into these Terms;</p>
 <p style="text-indent: 0pt;text-align: left;"><br/></p>
 </li>
 <li data-list-text="(iii)">
 <p style="padding-left: 62pt;text-indent: 0pt;line-height: 109%;text-align: justify;">you have not been previously suspended or removed from using WLC Crypto or Binance Services;</p>
 <p style="text-indent: 0pt;text-align: left;"><br/></p>
 </li>
 <li data-list-text="(iv)">
 <p style="padding-left: 80pt;text-indent: -18pt;text-align: justify;">you do not currently have a WLC Crypto Account;</p>
 <p style="text-indent: 0pt;text-align: left;"><br/></p>
 </li>
 <li data-list-text="(v)">
 <p style="padding-left: 62pt;text-indent: 0pt;line-height: 109%;text-align: justify;">you are neither a United States user, a Malaysia user, a Singapore-based user, or an Ontario (Canada)-based user; nor are you acting on behalf of a United States user, a Malaysia user, a Singapore-based user, or an Ontario (Canada)-based user. If you act as an employee or agent of a legal entity, and enter into these Terms on their behalf, you represent and warrant that you have all the necessary rights and authorizations to bind such legal entity;</p>
 <p style="text-indent: 0pt;text-align: left;"><br/></p>
 </li>
 <li data-list-text="(vi)">
 <p style="padding-left: 62pt;text-indent: 0pt;line-height: 109%;text-align: justify;">the user warrants that he use of WLC Crypto will not violate any and all laws and regulations applicable to you, including but not limited to regulations on anti-money laundering, anti-corruption, and counter-terrorist financing.</p>
 </li>
 </ol>
 </li>
 <li data-list-text="d.">
 <p style="padding-top: 4pt;padding-left: 41pt;text-indent: -18pt;line-height: 109%;text-align: justify;">Please note that some products and services may not be available in certain jurisdictions or regions or to certain users. Licensor or Binance reserves the right to change, modify or impose additional restrictions at its discretion at any time.</p>
 <p style="text-indent: 0pt;text-align: left;"><br/></p>
 <ol id="l7">
 <li data-list-text="(i)">
 <p style="padding-left: 62pt;text-indent: 0pt;line-height: 112%;text-align: justify;">Futures and Derivatives Products. USD<span class="s3">Ⓢ</span>-M Futures, Coin-M Futures, Margin, Binance Options, Vanilla Options and Leveraged Tokens (“Futures and Derivatives Products”) will not be available to all users of the Netherlands, Germany, Italy, Australia and Hong Kong, and retail users of the United Kingdom.</p>
 <p style="text-indent: 0pt;text-align: left;"><br/></p>
 </li>
 <li data-list-text="(ii)">
 <p style="padding-left: 62pt;text-indent: 0pt;line-height: 109%;text-align: justify;">Crypto Tokens. Privacy tokens (XMR, ZEC, DASH, XVG, XZC, KMD, ZEN, PIVX and GRS) will not be available to users of Australia with PayID associated with the Binance accounts.</p>
 <p style="text-indent: 0pt;text-align: left;"><br/></p>
 </li>
 </ol>
 </li>
 <li data-list-text="e.">
 <p style="padding-left: 41pt;text-indent: -18pt;line-height: 109%;text-align: justify;">User Identity Verification. Your registration of an account with WLC Crypto will be deemed your agreement to provide required personal information for identity verification. Such information will be used to verify Users’ identity, identify traces of money laundering, terrorist financing, fraud and other financial crimes through Licensor’s Software and Binance, or for other lawful purposes stated by WLC Crypto. We will collect, use and share such information in accordance with our Privacy Policy. In addition to providing such information, you agree to allow us to keep a record of that information during the period for which your account is active and within five (5) years after your account is closed, in compliance with global industry standards on data storage. You also authorize us to conduct necessary investigations directly or through a third party to verify your identity or protect you and/or us from financial crimes, such as fraud. The information we require to verify your identity may include, but is not limited to, your name, email address, contact information, phone number, username, government-issued ID, date of birth, and other information collected during account registration. When providing the required information, you confirm it is true and accurate.</p>
 <p style="text-indent: 0pt;text-align: left;"><br/></p>
 <p style="padding-left: 5pt;text-indent: 0pt;line-height: 109%;text-align: justify;">AFTER REGISTRATION, YOU MUST ENSURE THAT THE INFORMATION IS TRUE, COMPLETE, AND TIMELY UPDATED WHEN CHANGED. IF THERE ARE ANY GROUNDS FOR BELIEVING THAT ANY OF THE INFORMATION YOU PROVIDED IS INCORRECT, FALSE, OUTDATED OR INCOMPLETE, LICENSOR RESERVES THE RIGHT TO SEND YOU A NOTICE TO DEMAND CORRECTION, DIRECTLY DELETE THE RELEVANT INFORMATION, AND, AS THE CASE MAY BE, TERMINATE ALL OR PART OF WLC CRYPTO SOFTWARE WE PROVIDE FOR YOU. IF WE ARE UNABLE TO REACH YOU WITH THE CONTACT INFORMATION YOU PROVIDED, YOU SHALL BE FULLY LIABLE FOR ANY LOSS OR EXPENSE CAUSED TO BINANCE DURING YOUR USE OF WLC CRYPTO. YOU HEREBY ACKNOWLEDGE AND AGREE THAT YOU HAVE THE OBLIGATION TO UPDATE ALL THE INFORMATION IF THERE IS ANY CHANGE.BY REGISTERING AN ACCOUNT, YOU HEREBY AUTHORIZE LICENSOR TO CONDUCT INVESTIGATIONS THAT LICENSOR CONSIDERS NECESSARY, EITHER DIRECTLY OR THROUGH A THIRD PARTY, TO VERIFY YOUR IDENTITY OR PROTECT YOU, OTHER USERS AND/OR LICENSOR FROM FRAUD OR OTHER FINANCIAL CRIMES, AND TO TAKE NECESSARY ACTIONS BASED ON THE RESULTS OF SUCH INVESTIGATIONS. YOU ALSO ACKNOWLEDGE AND AGREE THAT YOUR PERSONAL INFORMATION MAY BE DISCLOSED TO CREDIT BUREAUS AND AGENCIES FOR FRAUD PREVENTION OR FINANCIAL CRIME PREVENTION, WHICH MAY RESPOND TO OUR INVESTIGATIONS IN FULL.</p>
 </li>
 <li data-list-text="f.">
 <p class="s4" style="padding-top: 3pt;padding-left: 41pt;text-indent: -18pt;line-height: 109%;text-align: left;"> <span class="p">Account Usage Requirements. The WLC Crypto Account can only be used by the account registrant. Licensor reserves the right to suspend, freeze or cancel the use of WLC Crypto Accounts by persons other than account registrant. If you suspect or become aware of any unauthorized use of your username and password, you should notify Licensor immediately. Licensor assumes no liability for any loss or damage arising from the use of WLC Crypto Account by you or any third party with or without your authorization.</span></p>
 <p style="text-indent: 0pt;text-align: left;"><br/></p>
 </li>
 <li data-list-text="g.">
 <p style="padding-left: 41pt;text-indent: -18pt;line-height: 109%;text-align: justify;">Account Security. Licensor has been committed to maintaining the security of User entrusted funds, and has implemented industry standard protection for WLC Crypto. However, the actions of individual Users may pose risks. You shall agree to treat your access credentials (such as username and password) as confidential information, and not to disclose such information to any third party. You also agree to be solely responsible for taking the necessary security measures to protect your WLC Crypto Account and personal information.</p>
 <p style="text-indent: 0pt;text-align: left;"><br/></p>
 <p style="padding-left: 41pt;text-indent: 0pt;line-height: 109%;text-align: justify;">You should be solely responsible for keeping your WLC Crypto Account and password, and be responsible for all the transactions under your WLC Crypto Account. Licensor assumes no liability for any loss or consequences caused by authorized or unauthorized use of your account credentials, including but not limited to information disclosure, information release, consent or submission of various rules and agreements by clicking on the website, online agreement renewal, etc.</p>
 <p style="text-indent: 0pt;text-align: left;"><br/></p>
 <p style="padding-left: 41pt;text-indent: 0pt;text-align: justify;">By creating a WLC Crypto Account, you hereby agree that:</p>
 <p style="padding-top: 1pt;padding-left: 41pt;text-indent: 0pt;line-height: 109%;text-align: justify;">you will notify Licensor immediately if you are aware of any unauthorized use of your WLC Crypto Account and password or any other violation of security rules;</p>
 <p style="padding-left: 41pt;text-indent: 0pt;line-height: 109%;text-align: justify;">you will strictly abide by all mechanisms or procedures of Licensor regarding security, authentication, trading, charging, and withdrawal; and you will take appropriate steps to logout from WLC Crypto at the end of each visit.</p>
 <p style="text-indent: 0pt;text-align: left;"><br/></p>
 </li>
 <li data-list-text="h.">
 <p style="padding-left: 41pt;text-indent: -18pt;line-height: 109%;text-align: justify;">Personal Data. Your personal data will be properly protected and kept confidential, but Licensor has the right to collect, process, use or disclose your personal data in accordance with the Terms (including the Privacy Policy) or applicable laws. Depending on the products or services concerned, your personal data may be disclosed to the following third parties: your transaction counterparty; Binance; Binance Operators, and the shareholders, partners, investors, directors, supervisors, senior managers and employees of such entities; our joint ventures, alliance partners and business partners; our agents, contractors, suppliers, third-party service providers and professional advisers, including the parties who have been contracted to provide us with administrative, financial, research, operations, IT and other services, in such areas as telecommunications, information technology, payroll, information processing, training, market research, storage and archival; third-party business partners who provide goods and services or sponsor contests or other promotional activities, whether or not in cooperation with us; insurance companies or insurance investigators and credit providers; credit bureaus, or any debt collection agencies or dispute resolution centers in the event of violation or dispute; business partners, investors, trustees or assignees (actual or expected) that promote business asset transactions (which can be broadened to include any merger, acquisition or asset sale) of Binance Operators; professional consultants such as auditors and lawyers; relevant</p>
 <p style="padding-top: 4pt;padding-left: 41pt;text-indent: 0pt;line-height: 109%;text-align: justify;">government regulatory agencies or law enforcement agencies to comply with laws or regulations formulated by government authorities; assignees of our rights and obligations; banks, credit card companies and their respective service providers; persons with your consent as determined by you or the applicable contract.</p>
 <p style="text-indent: 0pt;text-align: left;"><br/></p>
 <p style="padding-left: 41pt;text-indent: 0pt;line-height: 109%;text-align: justify;">According to separate rules of the Licensor, he has the right to make payments to the user for providing them with personal data.</p>
 <p style="text-indent: 0pt;text-align: left;"><br/></p>
 </li>
 <li data-list-text="i.">
 <p style="padding-left: 41pt;text-indent: -18pt;line-height: 109%;text-align: justify;">By accessing Software, you agree that Licensor has the right to investigate any violation of these Terms, unilaterally determine whether you have violated these Terms, and take actions under relevant regulations without your consent or prior notice. Examples of such actions include, but are not limited to:</p>
 <ol id="l8">
 <li data-list-text="(i)">
 <p style="padding-left: 54pt;text-indent: -12pt;line-height: 13pt;text-align: left;">Blocking and closing order requests;</p>
 </li>
 <li data-list-text="(ii)">
 <p style="padding-top: 1pt;padding-left: 57pt;text-indent: -15pt;text-align: left;">Freezing your account;</p>
 </li>
 <li data-list-text="(iii)">
 <p style="padding-top: 1pt;padding-left: 59pt;text-indent: -17pt;text-align: left;">Reporting the incident to the authorities;</p>
 </li>
 <li data-list-text="(iv)">
 <p style="padding-top: 1pt;padding-left: 41pt;text-indent: 0pt;line-height: 109%;text-align: left;">Publishing the alleged violations and actions that have been taken; (v)Deleting any information you published that are found to be violations.</p>
 <p style="text-indent: 0pt;text-align: left;"><br/></p>
 </li>
 </ol>
 </li>
 <li data-list-text="j.">
 <p style="padding-left: 41pt;text-indent: -18pt;line-height: 109%;text-align: justify;">If during or after the investigation, the user can prove that the rules were properly followed and provide adequate evidence, the licensor has the right to unfreeze the account and / or refuse other measures.</p>
 <p style="text-indent: 0pt;text-align: left;"><br/></p>
 </li>
 </ol>
 </li>
 <li data-list-text="4.">
 <h1 style="padding-left: 41pt;text-indent: -18pt;text-align: left;">LIABILITIES</h1>
 <p style="text-indent: 0pt;text-align: left;"><br/></p>
 <ol id="l9">
 <li data-list-text="a.">
 <p style="padding-left: 44pt;text-indent: -21pt;text-align: left;">Disclaimer of Warranties</p>
 <p style="text-indent: 0pt;text-align: left;"><br/></p>
 <p style="padding-left: 5pt;text-indent: 0pt;line-height: 109%;text-align: justify;">TO THE MAXIMUM EXTENT PERMITTED UNDER APPLICABLE LAW, SOFTWARE, SERVICE OR OTHER ITEM PROVIDED BY OR ON BEHALF OF LICENSOR OR BINANCE ARE OFFERED ON AN “AS IS” AND “AS AVAILABLE” BASIS, AND LICENSOR EXPRESSLY DISCLAIMS, AND YOU WAIVE, ANY AND ALL OTHER WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING, WITHOUT LIMITATION, WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE OR NON-INFRINGEMENT OR WARRANTIES ARISING FROM COURSE OF PERFORMANCE, COURSE OF DEALING OR USAGE IN TRADE. WITHOUT LIMITING THE FOREGOING, LICENSOR DOES NOT REPRESENT OR WARRANT THAT THE WEBSITE, SOFTWARE, ARE ACCURATE, COMPLETE, RELIABLE, CURRENT, ERROR-FREE, OR FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. LICENSOR DOES NOT GUARANTEE THAT ANY ORDER WILL BE EXECUTED, ACCEPTED, RECORDED OR REMAIN OPEN. EXCEPT FOR THE EXPRESS STATEMENTS, AGREEMENTS AND RULES SET FORTH IN THESE TERMS, YOU HEREBY ACKNOWLEDGE AND AGREE THAT YOU HAVE NOT RELIED UPON ANY OTHER STATEMENT OR AGREEMENT, WHETHER WRITTEN OR ORAL, WITH RESPECT TO YOUR USE AND ACCESS OF SOFTWARE. WITHOUT LIMITING THE FOREGOING, YOU HEREBY UNDERSTAND AND AGREE THAT LICENSOR WILL NOT BE LIABLE FOR ANY LOSSES OR DAMAGES ARISING OUT OF OR RELATING TO:</p>
 <ol id="l10">
 <li data-list-text="(A)">
 <p style="padding-left: 22pt;text-indent: -17pt;line-height: 12pt;text-align: left;">ANY INACCURACY, DEFECT OR OMISSION OF DIGITAL ASSETS PRICE DATA,</p>
 </li>
 <li data-list-text="(B)">
 <p style="padding-top: 1pt;padding-left: 22pt;text-indent: -17pt;text-align: left;">ANY ERROR OR DELAY IN THE TRANSMISSION OF SUCH DATA,</p>
 </li>
 <li data-list-text="(C)">
 <p style="padding-top: 1pt;padding-left: 24pt;text-indent: -18pt;text-align: left;">INTERRUPTION IN ANY SUCH DATA,</p>
 </li>
 <li data-list-text="(D)">
 <p style="padding-top: 4pt;padding-left: 5pt;text-indent: 0pt;line-height: 109%;text-align: justify;">REGULAR OR UNSCHEDULED MAINTENANCE CARRIED OUT BY LICENSOR, BINANCE OR OR ANY OTHER THIRD PARTY AND SERVICE INTERRUPTION AND CHANGE RESULTING FROM SUCH MAINTENANCE,</p>
 </li>
 <li data-list-text="(E)">
 <p style="padding-left: 5pt;text-indent: 0pt;line-height: 109%;text-align: justify;">ANY DAMAGES INCURRED BY OTHER USERS’ ACTIONS, OMISSIONS OR VIOLATION OF THESE TERMS,</p>
 </li>
 <li data-list-text="(F)">
 <p style="padding-left: 5pt;text-indent: 0pt;line-height: 109%;text-align: justify;">ANY DAMAGE CAUSED BY ILLEGAL ACTIONS OF OTHER THIRD PARTIES OR ACTIONS WITHOUT AUTHORIZED BY LICENSOR; AND</p>
 </li>
 <li data-list-text="(G)">
 <p style="padding-left: 5pt;text-indent: 0pt;line-height: 109%;text-align: justify;">OTHER EXEMPTIONS MENTIONED IN DISCLAIMERS AND Software RULES ISSUED BY LICENSOR.</p>
 </li>
 </ol>
 <p style="text-indent: 0pt;text-align: left;"><br/></p>
 </li>
 <li data-list-text="b.">
 <p style="padding-left: 48pt;text-indent: -18pt;text-align: left;">Disclaimer of Damages and Limitation of Liability</p>
 <p style="text-indent: 0pt;text-align: left;"><br/></p>
 <p style="padding-left: 5pt;text-indent: 0pt;line-height: 109%;text-align: justify;">TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT WILL LICENSOR, ITS AFFILIATES AND THEIR RESPECTIVE SHAREHOLDERS, MEMBERS, DIRECTORS, OFFICERS, EMPLOYEES, ATTORNEYS, AGENTS, REPRESENTATIVES, SUPPLIERS OR CONTRACTORS BE LIABLE FOR ANY INCIDENTAL, INDIRECT, SPECIAL, PUNITIVE, CONSEQUENTIAL OR SIMILAR DAMAGES OR LIABILITIES WHATSOEVER (INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OF DATA, INFORMATION, REVENUE, PROFITS OR OTHER BUSINESSES OR FINANCIAL BENEFITS) ARISING OUT OF SOFTWARE, ANY PERFORMANCE OR NON-PERFORMANCE OF LICENSOR SERVICES, OR ANY OTHER PRODUCT, SERVICE OR OTHER ITEM PROVIDED BY OR ON BEHALF OF LICENSOR AND ITS AFFILIATES, WHETHER UNDER CONTRACT, STATUTE, STRICT LIABILITY OR OTHER THEORY EVEN IF LICENSOR HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES EXCEPT TO THE EXTENT OF A FINAL JUDICIAL DETERMINATION THAT SUCH DAMAGES WERE A RESULT OF LICENSOR’S GROSS NEGLIGENCE, FRAUD, WILLFUL MISCONDUCT OR INTENTIONAL VIOLATION OF LAW. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE LIMITATION MAY NOT APPLY TO YOU.</p>
 <p style="text-indent: 0pt;text-align: left;"><br/></p>
 <p style="padding-left: 5pt;text-indent: 0pt;line-height: 109%;text-align: justify;">NOTWITHSTANDING THE FOREGOING, IN NO EVENT WILL THE LIABILITY OF LICENSOR, ITS AFFILIATES AND THEIR RESPECTIVE SHAREHOLDERS, MEMBERS, DIRECTORS, OFFICERS, EMPLOYEES, ATTORNEYS, AGENTS, REPRESENTATIVES, SUPPLIERS OR CONTRACTORS ARISING OUT OF SERVICES OFFERED BY OR ON BEHALF OF LICENSOR AND ITS AFFILIATES, ANY PERFORMANCE OR NON-PERFORMANCE OF SOFTWARE, OR ANY OTHER PRODUCT, SERVICE OR OTHER ITEM, WHETHER UNDER CONTRACT, STATUTE, STRICT LIABILITY OR OTHER THEORY, EXCEED THE AMOUNT OF THE FEES PAID BY YOU TO LICENSOR UNDER THESE TERMS IN THE TWELVE-MONTH PERIOD IMMEDIATELY PRECEDING THE EVENT GIVING RISE TO THE CLAIM FOR LIABILITY.</p>
 <p style="text-indent: 0pt;text-align: left;"><br/></p>
 </li>
 <li data-list-text="c.">
 <p style="padding-left: 48pt;text-indent: -18pt;line-height: 109%;text-align: justify;">Indemnification. You agree to indemnify and hold harmless Licensor, their affiliates, contractors, licensors, and their respective directors, officers, employees and agents from and against any claims, actions, proceedings, investigations, demands, suits, costs, expenses and damages (including attorneys’ fees, fines or penalties imposed by any regulatory authority) arising out of or related to (i) your use of, or conduct in connection with, Software, (ii) your breach or our enforcement of these Terms, or (iii) your violation of any applicable law, regulation, or rights of any third party during your use of Software. If you are obligated to indemnify Licensor, their affiliates, contractors, and their respective directors, officers, employees or agents pursuant to</p>
 <p style="padding-top: 4pt;padding-left: 48pt;text-indent: 0pt;line-height: 109%;text-align: justify;">these Terms, Licensor will have the right, in its sole discretion, to control any action or proceeding and to determine whether Licensor wishes to settle, and if so, on what terms.</p>
 <p style="text-indent: 0pt;text-align: left;"><br/></p>
 </li>
 <li data-list-text="d.">
 <p style="padding-left: 48pt;text-indent: -18pt;line-height: 109%;text-align: justify;">Announcements. Please be aware that all official announcements, news, promotions, competitions and airdrops will be listed on Website.</p>
 <p style="text-indent: 0pt;text-align: left;"><br/></p>
 <p style="padding-left: 5pt;text-indent: 0pt;line-height: 109%;text-align: justify;">USERS UNDERTAKE TO REFER TO THESE MATERIALS REGULARLY AND PROMPTLY. LICENSOR WILL NOT BE HELD LIABLE OR RESPONSIBLE IN ANY MANNER OF COMPENSATION SHOULD USERS INCUR PERSONAL LOSSES ARISING FROM IGNORANCE OR NEGLIGENCE OF THE ANNOUNCEMENTS.</p>
 <p style="text-indent: 0pt;text-align: left;"><br/></p>
 </li>
 <li data-list-text="e.">
 <p style="padding-left: 48pt;text-indent: -18pt;line-height: 109%;text-align: justify;">Force Majeure. Licensor will not be liable for any delay or failure to perform as required by these Terms because of any cause or condition beyond Licensor’s reasonable control.</p>
 <p style="text-indent: 0pt;text-align: left;"><br/></p>
 </li>
 <li data-list-text="f.">
 <p style="padding-left: 48pt;text-indent: -18pt;line-height: 109%;text-align: justify;">The relationship between the parties is NOT covered by public or private insurance coverage</p>
 <p style="text-indent: 0pt;text-align: left;"><br/></p>
 </li>
 </ol>
 </li>
 <li data-list-text="5.">
 <h1 style="padding-left: 41pt;text-indent: -18pt;text-align: justify;">TERMINATION OF AGREEMENT</h1>
 <p style="text-indent: 0pt;text-align: left;"><br/></p>
 <ol id="l11">
 <li data-list-text="a.">
 <p style="padding-left: 41pt;text-indent: -18pt;line-height: 109%;text-align: justify;">Suspension of Software Accounts. You agree that Licensor shall have the right to immediately suspend your Software Account (and any accounts beneficially owned by related entities or affiliates), freeze or lock the Digital Assets or funds in all such accounts, and suspend your access to Licensor for any reason including if Licensor suspects any such accounts to be in violation of these Terms, our Privacy Policy, or any applicable laws and regulations. You agree that Licensor shall not be liable to you for any permanent or temporary modification of your Software Account, or suspension or termination of your access to all or any portion of Software. Licensor shall reserve the right to keep and use the transaction data or other information related to such Software Accounts. The above account controls may also be applied in the following cases:</p>
 <p style="padding-left: 41pt;text-indent: 0pt;line-height: 109%;text-align: left;">The Software Account is subject to a governmental proceeding, criminal investigation or other pending litigation;</p>
 <p style="padding-left: 41pt;text-indent: 0pt;line-height: 13pt;text-align: left;">We detect unusual activities in theSoftware Account;</p>
 <p style="padding-left: 41pt;text-indent: 0pt;text-align: left;">We detect unauthorized access to the Software Account;</p>
 <p style="padding-top: 1pt;padding-left: 41pt;text-indent: 0pt;line-height: 109%;text-align: left;">We are required to do so by a court order or command by a regulatory/government authority.</p>
 <p style="text-indent: 0pt;text-align: left;"><br/></p>
 </li>
 <li data-list-text="b.">
 <p style="padding-left: 41pt;text-indent: -18pt;text-align: justify;">Cancellation of Software Accounts</p>
 <p style="padding-top: 1pt;padding-left: 41pt;text-indent: 0pt;line-height: 109%;text-align: justify;">In case of any of the following events, Licensor shall have the right to directly terminate these Terms by cancelling your Software Account, and shall enjoy the right but not the obligation to permanently freeze (cancel) the authorizations of your Software Account on Licensor and withdraw the corresponding Software Account thereof:</p>
 <p style="padding-left: 41pt;text-indent: 0pt;line-height: 13pt;text-align: justify;">after Licensor terminates services to you;</p>
 <p style="padding-top: 1pt;padding-left: 41pt;text-indent: 0pt;line-height: 109%;text-align: justify;">you allegedly register or register in any other person’s name as a Licensor User again, directly or indirectly;</p>
 <p style="padding-left: 41pt;text-indent: 0pt;line-height: 109%;text-align: justify;">the information that you have provided is untruthful, inaccurate, outdated or incomplete;</p>
 <p style="padding-top: 4pt;padding-left: 41pt;text-indent: 0pt;line-height: 109%;text-align: left;">when these Terms are amended, you state your unwillingness to accept the amended Terms by applying for cancellation of your Software Account or by other means;</p>
 <p style="padding-left: 41pt;text-indent: 0pt;line-height: 13pt;text-align: left;">you request that Software services be terminated;</p>
 <p style="padding-top: 1pt;padding-left: 41pt;text-indent: 0pt;line-height: 109%;text-align: left;">and any other circumstances where Licensor deems it should terminate Software Services.</p>
 <p style="text-indent: 0pt;text-align: left;"><br/></p>
 <p style="padding-left: 41pt;text-indent: 0pt;line-height: 109%;text-align: left;">Should your Software Account be terminated, the account and transactional information that meet data retention standards will be securely stored for 5 years. In addition, if a transaction is unfinished during the account termination process, Licensor shall have the right to notify your counterparty of the situation at that time. You acknowledge that a user-initiated account exit (right to erasure under GDPR or other equivalent regulations) will also be subjected to the termination protocol stated above. If Licensor is informed that any Digital Assets or funds held in your Software Account are stolen or otherwise are not lawfully possessed by you, Licensore may, but has no obligation to, place an administrative hold on the affected funds and your Software Account. If Software does lay down an administrative hold on some or all of your funds or Software Account, Licensor may continue such hold until such time as the dispute has been resolved and evidence of the resolution acceptable to Licensor has been provided to Licensor in a form acceptable to Licensor. Licensor will not involve itself in any such dispute or the resolution of the dispute. You agree that Licensor will have no liability or responsibility for any such hold, or for your inability to withdraw Digital Assets or funds or execute trades during the period of any such hold.</p>
 <p style="text-indent: 0pt;text-align: left;"><br/></p>
 </li>
 <li data-list-text="c.">
 <p style="padding-left: 41pt;text-indent: -18pt;text-align: justify;">Remaining Funds After Software Account Termination</p>
 <p style="padding-top: 1pt;padding-left: 41pt;text-indent: 0pt;line-height: 109%;text-align: justify;">Except as set forth in paragraph “d” below, once a Software Account is closed/ withdrawn, all remaining account balance (which includes charges and liabilities owed to Licensor) will be payable immediately to Licensor. Upon payment of all outstanding charges to Licensor (if any), Users will have 5 business days to withdraw all Digital Assets or funds from the account.</p>
 <p style="text-indent: 0pt;text-align: left;"><br/></p>
 </li>
 <li data-list-text="d.">
 <p style="padding-left: 41pt;text-indent: -18pt;line-height: 109%;text-align: justify;">Remaining Funds After Software Account Termination Due to Fraud, Violation of Law, or Violation of These Terms Licensor maintains full custody of the Digital Assets, funds and User data/information which may be turned over to governmental authorities in the event of Software Accounts’ suspension/closure arising from fraud investigations, investigations of violation of law or violation of these Terms.</p>
 <p style="text-indent: 0pt;text-align: left;"><br/></p>
 </li>
 </ol>
 </li>
 <li data-list-text="6.">
 <h1 style="padding-left: 41pt;text-indent: -18pt;text-align: justify;">GOVERNING LAW.</h1>
 <p style="text-indent: 0pt;text-align: left;"><br/></p>
 <ol id="l12">
 <li data-list-text="a.">
 <p style="padding-left: 41pt;text-indent: -18pt;line-height: 109%;text-align: justify;">The Terms are governed by and construed in accordance with the Republic of Singapore common law.</p>
 <p style="text-indent: 0pt;text-align: left;"><br/></p>
 </li>
 <li data-list-text="b.">
 <p style="padding-left: 41pt;text-indent: -18pt;line-height: 109%;text-align: justify;">Compliance with Local Laws. It is Users’ responsibility to abide by local laws in relation to the legal usage of Software in their local jurisdiction as well as other laws and regulations applicable to Users. Users must also factor, to the extent of their local laws, all aspects of taxation, the withholding, collection, reporting and remittance to their appropriate tax authorities.</p>
 <p style="text-indent: 0pt;text-align: left;"><br/></p>
 </li>
 <li data-list-text="c.">
 <p style="padding-left: 41pt;text-indent: -18pt;line-height: 109%;text-align: justify;">ALL USERS OF SOFTWARE ACKNOWLEDGE AND DECLARE THAT THEIR FUNDS COME FROM LEGITIMATE SOURCES AND DO NOT ORIGINATE FROM ILLEGAL ACTIVITIES; USERS AGREE THAT LICENSOR WILL REQUIRE THEM TO PROVIDE</p>
 <p style="padding-top: 4pt;padding-left: 41pt;text-indent: 0pt;line-height: 109%;text-align: justify;">OR OTHERWISE COLLECT THE NECESSARY INFORMATION AND MATERIALS AS PER RELEVANT LAWS OR GOVERNMENT ORDERS TO VERIFY THE LEGALITY OF THE SOURCES AND USE OF THEIR FUNDS.</p>
 <p style="text-indent: 0pt;text-align: left;"><br/></p>
 </li>
 <li data-list-text="d.">
 <p style="padding-left: 41pt;text-indent: -18pt;line-height: 109%;text-align: justify;">Licensor maintains a stance of cooperation with law enforcement authorities globally and will not hesitate to seize, freeze, terminate Users’ accounts and funds which are flagged out or investigated by legal mandate.</p>
 <p style="text-indent: 0pt;text-align: left;"><br/></p>
 </li>
 </ol>
 </li>
 <li data-list-text="7.">
 <h1 style="padding-left: 41pt;text-indent: -18pt;text-align: left;">PRIVACY POLICY</h1>
 <p style="text-indent: 0pt;text-align: left;"><br/></p>
 <p style="text-indent: 0pt;text-align: left;"/>
 <ol id="l13">
 <li data-list-text="a.">
 <p style="padding-left: 41pt;text-indent: -18pt;line-height: 109%;text-align: justify;">Access to Software will require the submission of certain personally identifiable information. Please review Licensor’s Privacy Policy at <span class="s5">https://wlc.capital/</span> for a summary of Licensor’s guidelines regarding the collection and use of personally identifiable information.</p>
 <p style="text-indent: 0pt;text-align: left;"><br/></p>
 </li>
 </ol>
 </li>
 <li data-list-text="8.">
 <h1 style="padding-left: 44pt;text-indent: -21pt;text-align: left;">CONTACT INFORMATION.</h1>
 </li>
 </ol>
 <p style="text-indent: 0pt;text-align: left;"><br/></p>
 <p style="padding-left: 41pt;text-indent: 0pt;line-height: 109%;text-align: left;">For more information on Licensor, you may refer to the company and license information found on Licensor’s Website. If you have questions regarding these Terms, please feel free to contact Licensor for clarification via our Customer Support team at: Telegram: @WLCrypto_info</p>
 <p style="padding-left: 44pt;text-indent: 0pt;line-height: 13pt;text-align: left;"><a href="mailto:Info@wlc.capital" class="s7" target="_blank">Email: Info@wlc.capital</a></p>
 </body>
</html>`
