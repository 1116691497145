
import Vue from 'vue'
export default Vue.extend({
  name: 'aboutPageProductItem',
  props: {
    title: {
      type: String,
      required: true
    },
    content: {
      type: String,
      required: true
    },
    action: {
      type: Object,
      required: false
    }
  }
})
