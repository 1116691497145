export enum LevelName {
  basic = 'basic',
  extended = 'extended',
  advanced = 'advanced'
}

export enum DocsTypes {
  address = 'address',
  documentFace = 'document_face'
}

export enum LevelStatuses {
  empty = '',
  start = 'start',
  inProgress = 'in-progress',
  success = 'success',
  rejected = 'rejected'
}

export enum AccessRepairStatuses {
  work = 'work',
  start = 'start',
  repeat = 'repeat'
}

export type StatusMap = {
  [key in LevelName]: {
    basic: LevelStatuses
    extended: LevelStatuses
    advanced: LevelStatuses
  }
}

export enum LinkStatus {
  request = 'request',
  process = 'process'
}
