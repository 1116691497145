import SERVICES, { getServiceConfigProperty } from '@/constants/services'
import { InternalVars, createNotificationStringParser } from '@whitelist/wl-old-library/utils/notifications'

const l: InternalVars = {
  PASSPORT_URL: SERVICES.PASSPORT_URL,
  TRADE_URL: SERVICES.ACCEPTOR_BASE_URL,
  COMPANY_NAME: getServiceConfigProperty('companyName').toString(),
  COMPANY_ABRV: getServiceConfigProperty('companyAbrv').toString()
}

export const parseNotificationString = createNotificationStringParser(l)
