
import Vue from 'vue'
import layouts from '@/layouts/index.vue'
import { mapGetters } from 'vuex'
import { UserDataInterface } from '@/store/modules/user/types'
import Chatra from '@chatra/chatra'

function setChatraUserData (userData: UserDataInterface): void {
  Chatra('setIntegrationData', {
    name: userData.last_name + ' ' + userData.first_name + ' ' + userData.patronymic,
    email: userData.email,
    phone: userData.phone,
    notes: `Паспорт. ${document.title}`
  })
}

export default Vue.extend({
  name: 'App',
  computed: {
    ...mapGetters('user', ['getUserData', 'getIsAuthenticated'])
  },
  watch: {
    getIsAuthenticated (v) {
      const vm = this as any
      if (v) setChatraUserData(vm.getUserData)
    }
  },
  beforeCreate (): void {
    const setTrueVH = () => {
      const trueVH = window.innerHeight / 100
      document.body.style.setProperty('--tVH', trueVH + 'px')
    }
    setTrueVH()
    window.addEventListener('resize', setTrueVH)
  },
  mounted (): void {
    const vm = this as any
    if (vm.getIsAuthenticated) setChatraUserData(vm.getUserData)
  },
  async created (): Promise<void> {
    const vm = this as any
    await vm.$store.dispatch('user/loadUser')
    this.$store.dispatch('notifications/loadAll')
  },
  components: {
    layouts
  }
})
