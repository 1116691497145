
import Vue from 'vue'

export default Vue.extend({
  name: 'coinsList',
  props: {
    subfields: {
      type: Array,
      required: true
    },
    value: {
      type: Boolean,
      required: true
    },
    valid: {
      type: Boolean,
      default: false
    },
    hideNoCoinsCheckbox: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: () => ({
    numberboxConfig: {
      rules: [],
      touched: false,
      stepSize: 0.1,
      maskConfig: {
        alias: 'numeric',
        allowMinus: false,
        digits: 2,
        min: 0,
        max: Infinity
      },
      min: 0,
      max: Infinity
    }
  }),
  computed: {
    isCurrentSubfieldValid () {
      const lastField: any = this.subfields[this.subfields.length - 1]
      return Boolean(lastField.name) && Boolean(lastField.value)
    }
  }
})
