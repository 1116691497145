import authorization from '@/services/authorization'
import userProps from '@/services/userProps/index'
import notifications from '@/services/notifications/index'
import common from '@/services/common/index'

export default {
  authorization,
  userProps,
  notifications,
  ...common
}
