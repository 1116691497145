export enum LegalInformationTypes {
  termsOfUse = 'termsOfUse',
  riskWarning = 'riskWarning',
  privacyPolicy = 'privacyPolicy',
  cookiePolicy = 'cookiePolicy',
  cookiePolicyId = 'ckPolicy',
  buddy = 'buddy',
}

export enum LanguageList {
  russian = 'русский',
  english = 'английский'
}
