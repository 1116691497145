
import Vue from 'vue'
import { mapGetters } from 'vuex'
import basic from './components/basic/index.vue'
import extended from './components/extended/index.vue'
import advanced from './components/advanced/index.vue'
import { ActivePopupTypes } from '@/store/modules/popup/types'
import SERVICES from '@/constants/services'
import { LevelName, DocsTypes, LinkStatus, StatusMap, LevelStatuses } from './types'

const statussesMap = {
  basic: {
    basic: 'success',
    extended: 'in-progress',
    advanced: ''
  },
  extended: {
    basic: 'success',
    extended: 'success',
    advanced: 'in-progress'
  },
  advanced: {
    basic: 'success',
    extended: 'success',
    advanced: 'success'
  }
} as StatusMap

export default Vue.extend({
  name: 'kyc',
  props: {
    kycLevels: {
      type: Array,
      required: true,
      default: () => ([])
    },
    kycAccordionStatus: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  watch: {
    kycAccordionStatus (value: boolean) {
      if (value) this.openedPanel = 0
    },
    openedPanel (value: number) {
      if (!value) this.$emit('hideKycPanel')
    },
    kycLevels: {
      handler (levels: Array<any>) {
        levels.forEach((levelData: any) => {
          this.dynamicLevelData[levelData.levelName] = {
            ...this.dynamicLevelData[levelData.levelName],
            ...levelData
          }
        })
      },
      immediate: true
    }
  },
  data () {
    return {
      openedPanel: null as number | null,
      selectedNecessityVerifyElementId: 'legality',
      showNecessityVerify: false,
      continueVerifyBtnIsLoading: false,
      getInfoTimer: null,
      verificationInited: false,
      dynamicLevelData: {
        basic: {},
        extended: {},
        advanced: {}
      },
      verifyNecessityElements: [
        { title: 'Законность', id: 'legality' },
        { title: 'Безопасность', id: 'safety' },
        { title: 'Конфиденциальность', id: 'privacy' },
        { title: 'Защита аккаунта', id: 'accountDefender' },
        { title: 'Надежность', id: 'reliability' }
      ],
      linksData: {
        extended: {
          id: '',
          url: '',
          status: '',
          loading: false,
          hideBtn: false,
          hasError: false
        },
        advanced: {
          id: '',
          url: '',
          status: '',
          loading: false,
          hideBtn: false,
          hasError: false
        }
      }
    }
  },
  created () {
    const levelName = this.getUserData.verificationRights.levelName
    if (levelName === LevelName.basic) this.getExtendedLink()
    if (levelName === LevelName.extended) this.getAdvancedLink()
    const isFinished = this.statusData.advanced === LevelStatuses.success

    this.getInfoTimer = setInterval(async () => {
      const currentLevel = this.getUserData.verificationRights.levelName
      if (this.getUserData.currentAccessRepairMethods.length <= 0) this.$emit('getAccessInfo')
      if (this.verificationInited) {
        if (currentLevel === LevelName.extended) {
          this.getAdvancedLink()
        }
        if (currentLevel !== LevelName.advanced) {
          this.$store.dispatch('user/loadUser')
        }
      }
    }, SERVICES.ACCESS_REPAIR_TIMEOUT)

    const kycFAQURL = new URL('/kyc-faq', SERVICES.PASSPORT_URL)

    const acceptor = sessionStorage.getItem('acceptor')
    if (acceptor !== null) {
      kycFAQURL.searchParams.set('acceptor', acceptor)
    }

    this.kycFAQLink = kycFAQURL.toString()
  },
  mounted () {
    const urlParams = new URLSearchParams(window.location.search)
    const verificationShow = urlParams.get('verification-show') === 'true'
    if (verificationShow) {
      this.openedPanel = 0
    }
  },
  destroyed (): void {
    clearInterval(this.getInfoTimer)
  },
  methods: {
    continueVerifyFromMain () {
      const isCurrentBasic = this.getUserData.verificationRights.levelName === LevelName.basic
      const linkType = isCurrentBasic ? LevelName.extended : LevelName.advanced
      this.continueVerify(linkType)
    },
    async getExtendedLink () {
      this.linksData.extended.loading = true
      const kycLink = await this.$services.authorization.user.kycLink(DocsTypes.documentFace)
      this.linksData.extended.loading = false
      if (!kycLink.success) {
        this.linksData.extended.hasError = true
        this.linksData.advanced.hasError = true
        return
      }
      this.linksData.extended = {
        ...this.linksData.extended,
        ...kycLink.data
      }
      if (kycLink.data.status === LinkStatus.process) {
        this.linksData.advanced.hideBtn = true
      }
    },
    async getAdvancedLink () {
      const levelType = DocsTypes.address
      this.linksData.advanced.loading = true
      const kycLink = await this.$services.authorization.user.kycLink(levelType)
      this.linksData.advanced.loading = false
      if (!kycLink.success) {
        this.linksData.advanced.hasError = true
        return
      }
      this.linksData.advanced = {
        ...this.linksData.advanced,
        ...kycLink.data
      }
      if (kycLink.data.status === LinkStatus.process) this.linksData.extended.hideBtn = true
      else this.linksData.advanced.hideBtn = false
    },
    continueVerify (from: string) {
      const vm = this as any
      if (this.linksData[from].status === LinkStatus.process) {
        window.open(this.linksData[from].url, '_blank')
        return
      }
      this.$store.dispatch('popupModule/openPopup', {
        type: ActivePopupTypes.action,
        letDefaultClose: true,
        title: 'Подтверждение достоверности предоставляемых данных',
        content: '',
        slot: {
          name: 'privacyPolicyAgreement',
          data: {
            linkData: this.linksData[from]
          },
          on: {
            onSuccess () {
              vm.linksData[from].status = LinkStatus.process
              const targetType = from === LevelName.extended ? LevelName.advanced : LevelName.extended
              vm.linksData[targetType].hideBtn = true
              vm.verificationInited = true
              vm.$store.commit('popupModule/setClosed')
            }
          }
        }
      })
    }
  },
  computed: {
    ...mapGetters('popupModule', ['getPopupIsOpened']),
    ...mapGetters('user', ['getUserData']),
    statusData () {
      const levelName = this.getUserData.verificationRights.levelName as LevelName
      return statussesMap[levelName]
    },
    mainBtnText () {
      const hasInit = this.linksData.extended.status === LinkStatus.process || this.linksData.advanced.status === LinkStatus.process
      return hasInit ? this.$t('main.accountVerify.checkDetails') : this.$t('main.accountVerify.continue')
    }
  },
  components: { basic, extended, advanced }
})
