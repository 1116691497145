
import Vue from 'vue'

export default Vue.extend({
  name: 'phoneRepairKYCQuestionShell',
  props: {
    index: {
      type: Number,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    subtitle: {
      type: String,
      default: ''
    },
    valid: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    hasError: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    panelIsOpened: false
  }),
  methods: {
    handleClick () {
      this.panelIsOpened = !this.panelIsOpened
    }
  }
})
