
import Vue from 'vue'
import ConditionsPanel from '../legalInfo/components/panels/conditions/index.vue'
import PolicyPanel from '../legalInfo/components/panels/policy/index.vue'
import WarningPanel from '../legalInfo/components/panels/warning/index.vue'
import PolicyCookiePanel from '../legalInfo/components/panels/policyCookie/index.vue'
import BuddyPanel from '../legalInfo/components/panels/buddy/index.vue'
import { LegalInformationTypes } from '@/components/pages/whitelistID/components/legalInfo/components/panels/types'
import { mapGetters } from 'vuex'
import Cookies from 'js-cookie'
import moment from 'moment/moment'
import jwtDecode from 'jwt-decode'

export default Vue.extend({
  name: 'legalInfoPage',
  components: { ConditionsPanel, PolicyPanel, WarningPanel, PolicyCookiePanel, BuddyPanel },
  data () {
    return {
      privacyPolicy: {},
      riskWarning: {},
      termsOfUse: {},
      cookiePolicy: {},
      buddy: {},
      scrollTo: {
        x: 0,
        y: 0
      },
      sectionHeight: 384
    }
  },
  computed: {
    LegalInformationTypes () {
      return LegalInformationTypes
    },
    buddyPanelIsVisible () {
      const urlParams = new URLSearchParams(window.location.search)
      const buddyParamEnabled = urlParams.get('buddy') === 'true'
      return this.buddy?.isVisible || buddyParamEnabled
    },
    ...mapGetters('user', ['getUserData'])
  },
  mounted () {
    this.requestDocuments()
  },
  methods: {
    calculateVisiblePanels () {
      let panelCount = 0
      if (this.privacyPolicy?.isVisible) panelCount++
      if (this.riskWarning?.isVisible) panelCount++
      if (this.termsOfUse?.isVisible) panelCount++
      if (this.cookiePolicy?.isVisible) panelCount++
      if (this.buddyPanelIsVisible) panelCount++
      return panelCount
    },
    calculateHeight () {
      const legalInfoHeight = this.$refs.legalInfo.getBoundingClientRect().height
      const panelHeight = 60
      const panelCount = this.calculateVisiblePanels()
      const panelsMarginTop = (panelCount - 1) * 12
      const userUnsignedDocuments = this.getUserData?.docs?.userUnsignedDocuments?.length ?? 0
      const additionalPanelHeight = userUnsignedDocuments * 44
      const sectionHeight = legalInfoHeight - (panelHeight * panelCount + additionalPanelHeight + panelsMarginTop)
      this.sectionHeight = sectionHeight > 460 ? sectionHeight : 460
    },
    async requestDocuments () {
      const response = await this.$services.loadDocuments()
      this.privacyPolicy = this.formatSection(response.data.privacyPolicy)
      this.riskWarning = this.formatSection(response.data.riskWarning)
      this.termsOfUse = this.formatSection(response.data.termsOfUse)
      this.buddy = this.formatSection(response.data.buddy)
      this.cookiePolicy = this.formatSection(response.data.cookiePolicy)
      if (this.getUserData?.docs?.userUnsignedDocuments?.length > 0) {
        this.openExpansionPanel()
      }
      this.calculateHeight()
    },
    formatSection (section: any) {
      const documents = section?.documents.map((document: any) => ({
        id: document.id,
        documentFile: document.document_file,
        markdownText: document.markdown_text ?? '',
        markdownRuText: document.markdown_ru_text ?? '',
        releasedDate: document.released_date,
        version: document.version
      })) ?? {}
      return {
        ...section,
        documents,
        opened: false
      }
    },
    async acceptChanges (type: LegalInformationTypes) {
      const response = await this.$services.acceptDocument({
        types: [type]
      })
      if (response.success) {
        const token = Cookies.get('jwtr')
        if (token) {
          try {
            const refreshResponse = await this.$services.authorization.user.refreshToken({
              refresh: token
            })
            if (refreshResponse?.status === 200 && refreshResponse.data?.refresh) {
              const refresh = refreshResponse.data.refresh
              const location = window.location
              const host = location.hostname.split('.').reverse()[1] + '.' + location.hostname.split('.').reverse()[0]
              Cookies.set('jwtr', refresh, {
                expires: moment.unix((jwtDecode(refresh) as any).exp).toDate(),
                domain: '.' + host
              })
            }
          } catch {}
        }
        await this.$store.dispatch('user/loadUser')
        await this.$store.dispatch('alertsBus/openAlert', {
          title: this.$t('alerts.titles.success'),
          type: 'success',
          autoDelete: true,
          content: this.$t('alerts.content.changesAccepted')
        })
        this.calculateHeight()
        return
      }
      this.openErrorAlert()
    },
    openErrorAlert () {
      this.$store.dispatch('alertsBus/openAlert', {
        title: this.$t('alerts.titles.error'),
        type: 'error',
        autoDelete: true,
        content: this.$t('alerts.content.errorChangesAccepted')
      })
    },
    openExpansionPanel () {
      const documents = this.getUserData.docs.userUnsignedDocuments
      if (documents.includes(LegalInformationTypes.termsOfUse)) {
        this.termsOfUse = { ...this.termsOfUse, opened: true }
      } else if (documents.includes(LegalInformationTypes.privacyPolicy)) {
        this.privacyPolicy = { ...this.privacyPolicy, opened: true }
      } else if (documents.includes(LegalInformationTypes.riskWarning)) {
        this.riskWarning = { ...this.riskWarning, opened: true }
      } else if (documents.includes(LegalInformationTypes.cookiePolicy)) {
        this.cookiePolicy = { ...this.cookiePolicy, opened: true }
      } else if (documents.includes(LegalInformationTypes.buddy)) {
        this.buddy = { ...this.buddy, opened: true }
      }
    },
    onPanelChange (panel: LegalInformationTypes) {
      this[panel].opened = true
      if (panel !== LegalInformationTypes.termsOfUse) {
        this.termsOfUse.opened = false
      }
      if (panel !== LegalInformationTypes.cookiePolicy) {
        this.cookiePolicy.opened = false
      }
      if (panel !== LegalInformationTypes.privacyPolicy) {
        this.privacyPolicy.opened = false
      }
      if (panel !== LegalInformationTypes.riskWarning) {
        this.riskWarning.opened = false
      }
      if (panel !== LegalInformationTypes.buddy) {
        this.buddy.opened = false
      }
      setTimeout(() => {
        const scrollToId = panel === LegalInformationTypes.cookiePolicy ? LegalInformationTypes.cookiePolicyId : panel
        const scrollTo = this.$refs.panels.querySelector(`#${scrollToId}`)
        this.scrollTo = {
          x: 0,
          y: scrollTo?.offsetTop ?? 0
        }
      }, 300)
    }
  }
})
