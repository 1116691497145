
import Vue from 'vue'
import { mapGetters } from 'vuex'

export default Vue.extend({
  name: 'instructionsMessage',
  data: () => ({
    retry: 0,
    retryLimit: 0,
    opened: false
  }),
  watch: {
    instance: {
      handler (v) {
        const vm = this as any
        vm.opened = v.opened
        if (v.actionBtn?.retry) {
          vm.retry = 180
        }
      },
      deep: true
    },
    opened (v) {
      const vm = this as any
      if (v) vm.retryLimit = 3
    },
    retry (v) {
      const vm = this as any
      if (v > 0) {
        setTimeout(() => {
          vm.retry--
        }, 1000)
      }
    }
  },
  computed: {
    ...mapGetters({
      instance: 'getMessageInstanceState'
    }),
    actionBtnVisibility (): boolean {
      const vm = this as any
      if (vm.instance.actionBtn?.retry) {
        if (!vm.retry) {
          return true
        } else return false
      }
      return false
    }
  },
  methods: {
    handleRetryClick (f: any): void {
      const vm = this as any
      f()
      vm.retry = 180
      vm.retryLimit--
    }
  }
})
