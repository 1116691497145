// @ts-ignore
import switcher from 'ai-switcher-translit'

export default {
  getColor (name: string, vuetifyInstance: any): string | Error {
    const currentTheme = vuetifyInstance.userPreset.theme.dark ? 'dark' : 'light'
    const iters = name.split('.')
    let result: any = vuetifyInstance.userPreset.theme.themes[currentTheme]
    for (let i = 0; i < iters.length; i++) {
      result = result[iters[i]]
    }
    if (result) return result
    else throw new Error('Color name "' + name + '" not found in pallet')
  },
  getSwitch (stringToSwitch: string, switchType = 'rueng'): string {
    return switcher.getSwitch(stringToSwitch, { type: switchType })
  }
}
