
import Vue from 'vue'
import questionShell from './accessRepairQuestions/questionShell/index.vue'
import { toMarkdown } from 'mdast-util-to-markdown'
import { gfmTableToMarkdown } from 'mdast-util-gfm-table'
import { IQuestionItem } from './accessRepairQuestions/types'

const questionsListComponentsRequires = require.context('@/components/pages/whitelistID/components/account/components/access-repair/accessRepairQuestionnaire/accessRepairQuestions/questions/', true, /\.vue$/i)
const questionsListComponents = questionsListComponentsRequires.keys().reduce((acc: any, curr: string) => {
  const parsed = require('@/components/pages/whitelistID/components/account/components/access-repair/accessRepairQuestionnaire/accessRepairQuestions/questions/' + curr.substring(2))
  acc[parsed.default.extendOptions.name] = parsed.default
  return acc
}, {})

const questionsFieldsHandlersMixinsRequires = require.context('@/components/pages/whitelistID/components/account/components/access-repair/accessRepairQuestionnaire/accessRepairQuestions/questions/', true, /\.ts$/i)
const questionsFieldsHandlersMixinsList = questionsFieldsHandlersMixinsRequires.keys().reduce((acc: any, curr: string) => {
  const parsed = require('@/components/pages/whitelistID/components/account/components/access-repair/accessRepairQuestionnaire/accessRepairQuestions/questions/' + curr.substring(2))
  const component = parsed[Object.keys(parsed)[0]]
  acc.push(component)
  return acc
}, [])

export default Vue.extend({
  name: 'AccessRepairQuestionnaire',
  data () {
    return {
      telegramTypeValue: 'Phone',
      form: {
        fields: {
          questionnaire: {
            fullnameAndEmail: {
              valid: false,
              isDirty: false,
              title: 'Ваше полное имя, а также электронная почта, привязанная к аккаунту.',
              subfields: [
                {
                  name: 'Фамилия, имя, отчество (при наличии)',
                  value: ''
                },
                {
                  name: 'Электронная почта',
                  value: ''
                }
              ]
            },
            lastVisit: {
              title: 'Дата последнего входа в Личный кабинет.',
              valid: false,
              value: ''
            },
            APIKeysQuantity: {
              title: 'Количество API-ключей в вашем Личном кабинете.',
              valid: false,
              value: 0
            },
            anyAPIKeyName: {
              title: 'Название любого из API-ключей.',
              valid: false,
              value: ''
            },
            spotCoins: {
              title: 'Монеты вашего спотового кошелька.',
              subtitle: 'Количество можно указать приблизительно.',
              valid: false,
              value: false,
              subfields: [
                {
                  name: '',
                  value: 0
                }
              ]
            },
            futuresCoins: {
              title: 'Монеты вашего фьючерсного счета. ',
              subtitle: 'Количество можно указать приблизительно.',
              valid: false,
              value: false,
              subfields: [
                {
                  name: '',
                  value: 0
                }
              ]
            },
            inboundTransfers: {
              title: '3 последних пополнения вашего счёта.',
              subtitle: 'Можно указать в любом порядке.',
              valid: false,
              value: false,
              subfields: [
                {
                  name: '',
                  value: {
                    volume: 0,
                    date: ''
                  }
                }
              ]
            },
            outboundTransfers: {
              title: '3 последних вывода средств.',
              subtitle: 'Можно указать в любом порядке.',
              valid: false,
              value: false,
              subfields: [
                {
                  name: '',
                  value: {
                    volume: 0,
                    date: ''
                  }
                }
              ]
            },
            lastCallToSupport: {
              title: 'Дата последнего обращения в техподдержку.',
              valid: false,
              value: ''
            },
            lastCallToSupportTopic: {
              title: 'Последняя тема обращения в техподдержку.',
              valid: false,
              value: ''
            }
          } as {[key: string]: IQuestionItem},
          telegram: {
            value: '',
            type: 'phone'
          },
          comment: {
            value: ''
          }
        }
      }
    }
  },
  watch: {
    'form.fields': {
      handler (fields: any) {
        const validAnswerQuantity = (Object.values(fields.questionnaire) as IQuestionItem[]).reduce((acc: number, curr: IQuestionItem) => {
          if (curr.valid) acc += 1
          return acc
        }, 0)
        const questionnaireStatus = validAnswerQuantity > 4
        this.$emit('questionnaire-valid', questionnaireStatus)
        if (questionnaireStatus) {
          const questionnaireValues = {
            answers: this.getQuestionnaireMarkdown(),
            telegram: this.form.fields.telegram.value,
            comment: this.form.fields.comment.value
          }
          this.$emit('questionnaireValues', questionnaireValues)
        }
      },
      deep: true
    }
  },
  // created () {
  //   this.telegramTypeValue = this.$t(`common.${this.telegramTypeValue.toLowerCase()}`)
  // },
  methods: {
    telegramTypeHandler (e: any) {
      this.form.fields.telegram.type = e.key
      this.telegramTypeValue = e.key
      this.form.fields.telegram.value = ''
    },
    handleSetNoDataValueCoins (type: string, newValue: boolean): void {
      this.form.fields.questionnaire[type].value = newValue
      if (!newValue) return
      this.handleDeleteAllCoin(type)
    },
    handleDelete (type: string, index: number): void {
      this.form.fields.questionnaire[type].subfields.splice(index, 1)
    },
    handleAddCoin (type: string): void {
      this.form.fields.questionnaire[type].value = false
      this.form.fields.questionnaire[type].subfields.push({
        name: '',
        value: 0
      })
    },
    handleDeleteCoin (type: string): void {
      this.form.fields.questionnaire[type].subfields.pop()
    },
    handleDeleteAllCoin (type: string): void {
      const textType = type === 'spotCoins' ? 'спотового' : 'фьючерсного'
      const vm = this as any
      this.$store.dispatch('popupModule/openPopup', {
        type: 'confirmPopup',
        letDefaultClose: true,
        title: 'Подтверждение удаления',
        content: `Заполняемый список с монетами ${textType} кошелька будет очищен.`,
        actions: [
          {
            title: vm.$t('popups.actions.cancel'),
            isAccent: false,
            callback () {
              vm.$store.commit('popupModule/setClosed')
              vm.form.fields.questionnaire[type].value = false
            }
          },
          {
            title: vm.$t('popups.actions.delete'),
            isAccent: true,
            callback () {
              vm.$store.commit('popupModule/setClosed')
              vm.form.fields.questionnaire[type].subfields = [
                {
                  name: '',
                  value: 0
                }
              ]
            }
          }
        ]
      })
    },
    handleSetNoDataValueTransfers (type: string, newValue: boolean): void {
      this.form.fields.questionnaire[type].value = newValue
      if (!newValue) return
      this.handleDeleteAllTransfers(type)
    },
    handleAddTransfer (type: string): void {
      this.form.fields.questionnaire[type].value = false
      this.form.fields.questionnaire[type].subfields.push({
        name: '',
        value: {
          volume: 0,
          date: ''
        }
      })
    },
    handleDeleteAllTransfers (type: string): void {
      const textType = type === 'inboundTransfers' ? 'пополнениями' : 'выводами'
      const vm = this as any
      this.$store.dispatch('popupModule/openPopup', {
        type: 'confirmPopup',
        letDefaultClose: true,
        title: 'Подтверждение удаления',
        content: `Заполняемый список с тремя последними ${textType} будет очищен. `,
        actions: [
          {
            title: vm.$t('popups.actions.cancel'),
            isAccent: false,
            callback () {
              vm.$store.commit('popupModule/setClosed')
              vm.form.fields.questionnaire[type].value = false
            }
          },
          {
            title: vm.$t('popups.actions.delete'),
            isAccent: true,
            callback () {
              vm.$store.commit('popupModule/setClosed')
              vm.form.fields.questionnaire[type].subfields = [
                {
                  name: '',
                  value: {
                    volume: 0,
                    date: ''
                  }
                }
              ]
            }
          }
        ]
      })
    },
    getQuestionnaireMarkdown () {
      const mainTree: any = {
        type: 'root',
        children: [
          {
            type: 'heading',
            depth: 2,
            children: [
              {
                type: 'text',
                value: 'Анкета пользователя на восстановление KYC-0'
              }
            ]
          },
          {
            type: 'heading',
            depth: 3,
            children: [
              {
                type: 'text',
                value: 'Телеграм для связи'
              }
            ]
          },
          {
            type: 'paragraph',
            children: [
              {
                type: 'text',
                value: this.form.fields.telegram.value.length === 0 ? '...' : this.form.fields.telegram.value
              }
            ]
          }
        ]
      }
      for (const [key, questionnaireItem] of Object.entries(this.form.fields.questionnaire) as [string, IQuestionItem][]) {
        if (questionnaireItem.valid) {
          mainTree.children.push(...this[`toMD${key}`](questionnaireItem))
        } else {
          mainTree.children.push(...[
            {
              type: 'heading',
              depth: 3,
              children: [
                {
                  type: 'text',
                  value: questionnaireItem.title
                }
              ]
            },
            {
              type: 'paragraph',
              children: [
                {
                  type: 'text',
                  value: '...'
                }
              ]
            }
          ])
        }
      }
      return toMarkdown(mainTree, { extensions: [gfmTableToMarkdown()] })
    }
  },
  computed: {
    selectList () {
      return [{ key: 'phone', value: this.$t('common.phone') }, { key: 'nickname', value: this.$t('common.nickname') }]
    }
  },
  mixins: questionsFieldsHandlersMixinsList,
  components: {
    questionShell,
    ...questionsListComponents
  }
})
