
import Vue from 'vue'
import moment from 'moment'
import { mapGetters } from 'vuex'
import { passwordAllowedSymbolsRegExp } from '@/constants/baseConstatnts'

export default Vue.extend({
  name: 'changePhoneConfirmPopupChunk',
  props: {
    slotData: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      step: 1,
      form: {
        fields: {
          password: {
            valid: false,
            type: 'password',
            value: '',
            rules: [
              (v: string) => Boolean(v) || this.$t('common.errors.required')
            ]
          },
          emailCode: {
            valid: false,
            codeIsSent: false,
            retryTimeout: 60,
            retries: 3,
            letRetry: false,
            value: '',
            rules: [
              (v: string) => Boolean(v) || this.$t('common.errors.required')
            ]
          }
        },
        passwordPattern: passwordAllowedSymbolsRegExp.source
      }
    }
  },
  computed: {
    ...mapGetters('user', ['getUserData'])
  },
  watch: {
    'form.fields.emailCode.retryTimeout' (v) {
      const vm = this as any
      if (v > 0) {
        setTimeout(() => {
          vm.form.fields.emailCode.retryTimeout = v - 1
        }, 1000)
      } else vm.form.fields.emailCode.letRetry = true
    }
  },
  methods: {
    changePasswordInputType (item: string): void {
      const vm = this as any
      vm.form.fields[item].type = vm.form.fields[item].type === 'password' ? 'text' : 'password'
    },
    async sendEmail (): Promise<void> {
      const vm = this as any
      vm.form.fields.emailCode.loading = true
      vm.form.fields.emailCode.codeSentBefore = false
      const response = await vm.$services.sendCode('phone_change')
      if (response.success) {
        vm.form.fields.emailCode.loading = false
        vm.form.fields.emailCode.codeIsSent = true
        vm.form.fields.emailCode.letRetry = false
        vm.form.fields.emailCode.retryTimeout = moment(response.data.repeatAfter).diff(moment(), 'seconds')
        vm.form.fields.emailCode.retries -= 1
        setTimeout(() => {
          vm.form.fields.emailCode.retryTimeout -= 1
        }, 1000)
      } else {
        vm.form.fields.emailCode.loading = false
        if (response?.data?.repeatAfter) {
          vm.form.fields.emailCode.codeSentBefore = true
          vm.form.fields.emailCode.codeIsSent = true
          vm.form.fields.emailCode.letRetry = false
          vm.form.fields.emailCode.retryTimeout = moment(response.data.repeatAfter).diff(moment(), 'seconds')
          vm.form.fields.emailCode.retries -= 1
          setTimeout(() => {
            vm.form.fields.emailCode.retryTimeout -= 1
          }, 1000)
        } else {
          vm.form.fields.emailCode.codeSendError = true
          vm.form.fields.emailCode.letRetry = true
        }
      }
    }
  }
})
