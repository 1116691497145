
import Vue from 'vue'
// @ts-ignore
import VueQRCodeComponent from 'vue-qr-generator'
import { copyToClipboard } from '@whitelist/wl-old-library/utils/other'
import SERVICES from '@/constants/services'

export default Vue.extend({
  name: 'GASetupCompletingPopupChunk',
  props: {
    slotData: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      manualCopyOpened: false,
      secretCopied: false,
      form: {
        valid: false,
        fields: {
          code: {
            value: '',
            rules: [
              (v: string) => Boolean(v) || this.$t('common.errors.required'),
              (v: string) => v.length === SERVICES.CODE_LENGTH || this.$t('popups.numberCode', { length: SERVICES.CODE_LENGTH })
            ],
            errorsBucket: []
          }
        },
        isLoading: false
      }
    }
  },
  beforeDestroy (): void {
    window.removeEventListener('resize', this.resizeHandler)
  },
  created () {
    window.addEventListener('resize', this.resizeHandler)
  },
  watch: {
    'form.fields.code.value' () {
      const vm = this as any
      vm.form.fields.code.errorsBucket = []
    },
    'form.valid' (v) {
      if (v && this.form.fields.code.value.length > 0) {
        setTimeout(() => {
          this.handleClickSubmit()
        }, 400)
      }
    }
  },
  methods: {
    resizeHandler () {
      this.manualCopyOpened = this.maw(460)
    },
    handleClickCopyAddress (): void {
      const vm = this as any
      copyToClipboard(vm.slotData.secret).then(() => {
        vm.secretCopied = true
        setTimeout(() => {
          vm.secretCopied = false
        }, 3000)
      })
    },
    async handleClickSubmit (): Promise<void> {
      const vm = this as any
      vm.form.isLoading = true
      vm.form.fields.code.errorsBucket = []
      const response = await vm.$services.authorization.user.completeCreating2FA({ code: vm.form.fields.code.value })
      vm.form.isLoading = false
      if (response.success) {
        vm.$emit('onSuccess')
        vm.$store.dispatch('alertsBus/openAlert', {
          title: vm.$t('alerts.titles.success'),
          type: 'success',
          autoDelete: true,
          content: vm.$t('alerts.content.connectGASuccess')
        })
        const response2FA = await vm.$services.authorization.user.getState2FA(null)
        if (response2FA.data.current === 'off') {
          vm.$store.dispatch('alertsBus/openAlert', {
            title: vm.$t('alerts.titles.attention'),
            type: 'warning',
            autoDelete: false,
            content: vm.$t('alerts.content.enable2FA')
          })
        }
        vm.$store.commit('popupModule/setClosed')
      } else {
        vm.form.fields.code.errorsBucket = response.data.errors
      }
    }
  },
  computed: {
    servicesConst () {
      return SERVICES
    }
  },
  components: {
    'qr-code': VueQRCodeComponent
  }
})
