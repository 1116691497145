
import Vue from 'vue'
import { ActivePopupTypes } from '@/store/modules/popup/types'
import { mapGetters } from 'vuex'
import { StepByStepGlossary } from '@/constants/services'
import YubikeyAuthMixin from '@/mixins/YubikeyAuthMixin'
import stepByStepValidate from '@whitelist/wl-old-library/services/_helpers/stepByStepValidate'
import { passwordAllowedSymbolsRegExp, passwordSpecialCharacters } from '@/constants/baseConstatnts'

export default Vue.extend({
  name: 'password',
  mixins: [YubikeyAuthMixin],
  props: {
    disableChangeBtn: {
      type: Boolean,
      default: true,
      required: false
    },
    changeKycAccordionStatus: {
      type: Function,
      default: () => ({}),
      required: true
    }
  },
  watch: {
    'changePasswordForm.fields.old_password.value' (oldPasswordValue) {
      const vm = this as any
      vm.changePasswordForm.responseErrors = []
      const newPassword1 = vm.changePasswordForm.fields.new_password1
      if (oldPasswordValue && oldPasswordValue === newPassword1.value) {
        newPassword1.errorMessages = this.$t('main.safety.newPassMustBeEqual')
        return
      }
      newPassword1.errorMessages = []
    },
    'changePasswordForm.fields.new_password2.value' (newPassword2Value) {
      const vm = this as any
      vm.changePasswordForm.responseErrors = []
      const newPassword1 = vm.changePasswordForm.fields.new_password1
      const newPassword2 = vm.changePasswordForm.fields.new_password2
      if (newPassword2Value !== newPassword1.value && newPassword2Value.length > 0) {
        newPassword1.errorMessages = this.$t('main.safety.newPassNotEqual')
        newPassword2.errorMessages = this.$t('main.safety.newPassNotEqual')
        return
      }
      newPassword1.errorMessages = []
      newPassword2.errorMessages = []
    }
  },
  data () {
    return {
      changePassIsLoading: false,
      passwordEdit: false,
      changePasswordForm: {
        valid: false,
        fields: {
          old_password: {
            value: '',
            type: 'password',
            errorMessages: [],
            rules: [
              (v: string) => Boolean(v.length) || this.$t('main.safety.passwordRequired')
            ]
          },
          new_password1: {
            value: '',
            type: 'password',
            errorMessages: [],
            rules: [
              (v: string) => Boolean(v.length) || this.$t('main.safety.passwordRequired'),
              (v: string) => v.length >= 8 || this.$t('main.safety.passIsShort'),
              (v: string) => passwordAllowedSymbolsRegExp.test(v) || this.$t('main.safety.onlyLatinAllowed'),
              (v: string) => v.search(/[a-z]/g) >= 0 || this.$t('main.safety.passMustContainsLower'),
              (v: string) => v.search(/[A-Z]/g) >= 0 || this.$t('main.safety.passMustContainsUpper'),
              (v: string) => v.search(/[0-9]/g) >= 0 || this.$t('main.safety.passMustContainsNumbers'),
              (v: string) => passwordSpecialCharacters.test(v) || this.$t('main.safety.specialSymbol'),
              (v: string) => {
                const vm = this as any
                return v !== vm.changePasswordForm.fields.old_password.value || this.$t('main.safety.newPassMustBeEqual')
              }
            ]
          },
          new_password2: {
            value: '',
            type: 'password',
            errorMessages: [],
            rules: [
              (v: string) => Boolean(v.length) || this.$t('main.safety.passwordRequired'),
              (v: string) => v.length >= 8 || this.$t('main.safety.passIsShort'),
              (v: string) => passwordAllowedSymbolsRegExp.test(v) || this.$t('main.safety.onlyLatinAllowed'),
              (v: string) => v.search(/[a-z]/g) >= 0 || this.$t('main.safety.passMustContainsLower'),
              (v: string) => v.search(/[A-Z]/g) >= 0 || this.$t('main.safety.passMustContainsUpper'),
              (v: string) => v.search(/[0-9]/g) >= 0 || this.$t('main.safety.passMustContainsNumbers'),
              (v: string) => passwordSpecialCharacters.test(v) || this.$t('main.safety.specialSymbol')
            ]
          }
        },
        responseErrors: [],
        passwordPattern: passwordAllowedSymbolsRegExp.source
      }
    }
  },
  computed: {
    ...mapGetters('popupModule', ['getPopupIsOpened']),
    ...mapGetters('user', ['getUserData'])
  },
  methods: {
    serverErrorHandler (errors: any) {
      const vm = this as any
      vm.$store.commit('popupModule/setClosed')
      vm.$store.dispatch('alertsBus/openAlert', {
        title: vm.$t('popups.titles.error'),
        type: 'error',
        autoDelete: true,
        content: errors?.join('\n') ?? vm.$t('common.errors.cantDoOperation')
      })
    },
    async handleCancelPasswordChange (): Promise<void> {
      const vm = this as any
      for (const itemKey in vm.changePasswordForm.fields) {
        vm.changePasswordForm.fields[itemKey].value = ''
      }
      vm.changePasswordForm.responseErrors = []
    },

    changePasswordInputType (item: string): void {
      const vm = this as any
      vm.changePasswordForm.fields[item].type = vm.changePasswordForm.fields[item].type === 'password' ? 'text' : 'password'
    },

    async handlePasswordChangeButtonClick (): Promise<void> {
      const vm = this as any
      vm.changePasswordForm.responseErrors = []

      const data = {
        old_password: vm.changePasswordForm.fields.old_password.value,
        password1: vm.changePasswordForm.fields.new_password1.value,
        password2: vm.changePasswordForm.fields.new_password2.value
      }

      vm.changePassIsLoading = true
      const sessionResponse = await vm.$services.authorization.user.changePasswordBegin(data)
      vm.changePassIsLoading = false

      const sessionId = sessionResponse.data.session_id

      if (!sessionId) {
        vm.changePasswordForm.responseErrors = sessionResponse.data.errors
        return
      }

      const currentConfirmationType = vm.getUserData.confirmation

      if (currentConfirmationType === 'yubikey') {
        const yubikeyChangePass = (): void => {
          vm.YubikeyAuth(StepByStepGlossary.changePassword, null, async (authResponse: any) => {
            if (!authResponse.success) return vm.serverErrorHandler(authResponse.errors)
            const response = await stepByStepValidate({
              name: StepByStepGlossary.changePassword,
              endpointName: 'changePasswordComplete',
              data: {
                sessionId
              }
            })
            vm.$store.commit('popupModule/setClosed')
            vm.passwordEdit = false
            vm.handleCancelPasswordChange()

            if (response.success) {
              vm.$store.dispatch('alertsBus/openAlert', {
                title: vm.$t('alerts.titles.success'),
                type: 'success',
                autoDelete: true,
                content: vm.$t('alerts.content.passwordChangeSuccess')
              })
            } else {
              vm.$store.dispatch('alertsBus/openAlert', {
                title: vm.$t('popups.titles.error'),
                type: 'error',
                autoDelete: true,
                content: vm.$t('alerts.content.passwordChangeFailed')
              })
            }
          }, sessionId, yubikeyChangePass)
        }
        yubikeyChangePass()
        return
      }

      vm.$store.dispatch('popupModule/openPopup', {
        type: ActivePopupTypes.action,
        letDefaultClose: true,
        title: vm.$t('popups.titles.passwordChange'),
        content: vm.$t('popups.content.confirmOperation'),
        slot: {
          name: `email${currentConfirmationType.toUpperCase()}ConfirmPopupChunk`,
          data: {
            codes: sessionResponse.data.confirmations,
            isLoading: false,
            endpointName: StepByStepGlossary.validateSession,
            name: StepByStepGlossary.changePassword,
            sessionId,
            steps: 3,
            changeKycAccordionStatus: vm.changeKycAccordionStatus
          },
          on: {
            async onSuccess () {
              vm.$store.commit('popupModule/setSlotData', { isLoading: true })
              const response = await vm.$services.authorization.user.changePasswordComplete({ sessionId })
              vm.$store.commit('popupModule/setSlotData', { isLoading: false })
              vm.passwordEdit = false
              vm.$store.commit('popupModule/setClosed')
              if (response.status === 200) {
                vm.$store.dispatch('alertsBus/openAlert', {
                  title: vm.$t('alerts.titles.success'),
                  type: 'success',
                  autoDelete: true,
                  content: 'Пароль от Личного кабинета изменен.'
                })
              } else {
                vm.$store.dispatch('alertsBus/openAlert', {
                  title: vm.$t('popups.titles.error'),
                  type: 'error',
                  autoDelete: true,
                  content: 'Не удалось изменить пароль'
                })
              }
              vm.handleCancelPasswordChange()
            }
          }
        }
      })
    }
  }
})
