var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('section',[(_vm.slotData.showAccessRepair && _vm.slotData.sessionId)?_c('v-btn',{staticClass:"btn btn-smallest btn-smallest-text btn-flat mt-0 px-0",on:{"click":_vm.hasntAccessHandler}},[_vm._v(_vm._s(_vm.$t('popups.noAccessYubikey'))+" ")]):_vm._e(),_c('v-row',{staticClass:"mt-3"},[_c('v-col',{class:{
      'col-12': _vm.maw(460),
      'col-6': _vm.miw(460)
    }},[_c('v-btn',{staticClass:"btn btn--gradient-border btn--gradient-border--accent fill-width",attrs:{"height":"32","max-height":"32","min-height":"32"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.$emit('onSubmit')},"click":function($event){return _vm.$emit('onSubmit')}}},[_vm._v(_vm._s(_vm.$t('common.retry'))+" ")])],1),_c('v-col',{class:{
      'col-12': _vm.maw(460),
      'col-6': _vm.miw(460)
    }},[_c('v-btn',{staticClass:"btn btn--gradient-border fill-width",attrs:{"height":"32","max-height":"32","min-height":"32"},on:{"click":function($event){return _vm.$store.commit('popupModule/setClosed')}}},[_vm._v(" "+_vm._s(_vm.$t('common.cancel'))+" ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }