var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-footer',{style:({
    zIndex: 9999,
    height: _vm.$vuetify.breakpoint.mdAndUp ? '44px' : 'auto'
  }),attrs:{"app":"","color":_vm.getColor('SYSTEM.DARK_9')}},[_c('v-container',{class:{
      'py-0': _vm.$vuetify.breakpoint.mdAndUp,
      'py-2': _vm.$vuetify.breakpoint.smAndDown
    }},[_c('v-row',{staticClass:"my-0 align-center"},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"auto"}},[_c('span',{staticClass:"footer_copyright"},[_vm._v(_vm._s(_vm.$t('layouts.dark.footer.copyright')))])]),_c('v-spacer'),_c('v-col',{staticClass:"py-0 d-flex align-center",class:{
          'fill-width': _vm.$vuetify.breakpoint.smAndDown,
          'mt-2': _vm.$vuetify.breakpoint.smAndDown
        },attrs:{"cols":"12","sm":"auto"}},[_c('div',{staticClass:"d-flex flex-wrap"},[_c('router-link',{staticClass:"link link--primary mr-4",attrs:{"to":{ name: 'whitelistIDAbout' }}},[_vm._v(_vm._s(_vm.$t('layouts.dark.footer.about')))]),_c('router-link',{staticClass:"link link--primary",attrs:{"to":{ name: 'whitelistIDLegalInformation' }}},[_vm._v(_vm._s(_vm.$t('layouts.dark.footer.legalInformation')))])],1),_c('div',{staticClass:"footer_social ml-12",class:{
            'ml-12': _vm.$vuetify.breakpoint.mdAndUp,
            'ml-auto': _vm.$vuetify.breakpoint.smAndDown
          }},[_c('a',{staticClass:"footer_social_item",attrs:{"href":"https://vk.com/whitelistofficial","target":"_blank"}},[_c('v-img',{attrs:{"src":require("@/assets/icons/svg/icon-vk.svg"),"lazy-src":require("@/assets/icons/svg/icon-vk.svg"),"max-width":"16","max-height":"16"}})],1),_c('a',{staticClass:"footer_social_item ml-5",attrs:{"href":"https://t.me/wlcrpt","target":"_blank"}},[_c('v-img',{attrs:{"src":require("@/assets/icons/svg/icon-telegram.svg"),"lazy-src":require("@/assets/icons/svg/icon-telegram.svg"),"max-width":"16","max-height":"16"}})],1)])])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }