
import Vue from 'vue'
export default Vue.extend({
  name: 'privacyPolicyAgreement',
  data: () => ({}),
  props: {
    slotData: {
      type: Object,
      required: true
    }
  },
  methods: {
    async clickContinueVerify () {
      this.$store.commit('popupModule/setSlotData', { isLoading: true })
      const updateLinkState = await this.$services.authorization.user.startKycVerify({ verificationId: this.slotData.linkData.id })
      this.$store.commit('popupModule/setSlotData', { isLoading: false })
      if (updateLinkState.success) {
        window.open(this.slotData.linkData.url, '_blank')
        this.$emit('onSuccess')
      }
    },
    clickPrivacyLinkHandler () {
      const routeData = this.$router.resolve({ name: 'whitelistIDLegalInformation' })
      window.open(routeData.href, '_blank')
    }
  }
})
