
import Vue from 'vue'

export default Vue.extend({
  name: 'load-line',
  props: {
    status: {
      type: String,
      required: false,
      default: ''
    }
  }
})
