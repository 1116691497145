import { RouteConfig } from 'vue-router'
import { getServiceConfigProperty, isTrustedEnvironment } from '@/constants/services'

export const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Base',
    component: () => import('@/views/Main.vue'),
    children: [
      {
        path: '',
        name: 'SignIn',
        component: () => import('@/views/Login.vue'),
        meta: {
          title: `${getServiceConfigProperty('companyAbrv')} Passport Sign In`,
          description: ''
        }
      },
      {
        path: '/signup',
        name: 'SignUp',
        component: () => import('@/views/Register.vue'),
        meta: {
          title: `${getServiceConfigProperty('companyAbrv')} Passport Sign Up`,
          description: ''
        }
      },
      {
        path: '/signup/:partner',
        name: 'SignUp',
        component: () => import('@/views/Register.vue'),
        meta: {
          title: `${getServiceConfigProperty('companyAbrv')} Passport Sign Up`,
          description: ''
        }
      }
    ]
  },
  {
    path: '/signin-second-factor/:method',
    name: 'SignInSecondFactor',
    component: () => import('@/views/SignInSecondFactor.vue'),
    meta: {
      title: 'Двухфакторная аутентификация',
      description: ''
    }
  },
  {
    path: '/ga-reset/:an3K8QkkJb3Mzz',
    name: 'GAReset',
    component: () => import('@/views/GAResetPage.vue'),
    meta: {
      title: 'Восстановление доступа',
      meta: ''
    }
  },
  {
    path: '/email-confirm/:token',
    name: 'SignUpEmailConfirm',
    component: () => import('@/views/Register_ConfirmEmail.vue'),
    meta: {
      title: `${getServiceConfigProperty('companyAbrv')} Passport Sign Up Continue`,
      description: ''
    }
  },
  {
    path: '/password-repair',
    name: 'PasswordRepair',
    component: () => import('@/views/PasswordRepair.vue'),
    meta: {
      title: `${getServiceConfigProperty('companyAbrv')} Passport Repair Password`,
      description: ''
    }
  },
  {
    path: '/password-repair-setup/:id',
    name: 'PasswordRepairSetup',
    component: () => import('@/views/PasswordRepair_Setup.vue'),
    meta: {
      title: `${getServiceConfigProperty('companyAbrv')} Passport Repair Password repair setup`,
      description: ''
    }
  },
  {
    path: '/access-repair',
    name: 'AccessRepair',
    component: () => import('@/views/AccessRepair.vue'),
    meta: {
      title: `${getServiceConfigProperty('companyAbrv')} Access Repair`,
      description: ''
    }
  },
  {
    path: '/kyc-faq',
    name: 'KycFaq',
    component: () => import('@/views/KycFaq.vue'),
    meta: {
      title: 'KYC FAQ',
      description: ''
    }
  },
  {
    path: '/authentication',
    name: 'authentication',
    component: () => import('@/components/pages/authentication/index.vue'),
    meta: {
      title: 'Авторизация в приложении',
      description: 'Авторизация в приложении через сервис Whitelist'
    }
  },
  {
    path: '/id',
    name: 'whitelistID',
    component: () => import('@/views/whitelistID.vue'),
    meta: {
      title: getServiceConfigProperty('companyID'),
      description: ''
    },
    redirect: { name: 'whitelistIDUserAccount' },
    children: [
      {
        path: '',
        name: 'whitelistIDUserAccount',
        component: () => import('@/components/pages/whitelistID/components/account/index.vue'),
        meta: {
          title: 'Мой аккаунт',
          description: ''
        }
      },
      {
        path: 'about',
        name: 'whitelistIDAbout',
        component: () => import('@/components/pages/whitelistID/components/about/index.vue'),
        meta: {
          title: 'О Разработчике',
          description: ''
        },
        beforeEnter (to, from, next) {
          if (isTrustedEnvironment) {
            next({ name: 'NotFoundErrorPage', replace: true })
          } else {
            next()
          }
        }
      },
      {
        path: 'legal-information',
        name: 'whitelistIDLegalInformation',
        component: () => import('@/components/pages/whitelistID/components/legalInfo/index.vue'),
        meta: {
          title: 'Правовая информация',
          description: ''
        }
      }
    ]
  },
  {
    path: '/error',
    name: 'ErrorPage',
    component: () => import('@/views/ErrorPage.vue'),
    meta: {
      title: 'Error',
      description: ''
    },
    children: [
      {
        path: ':code',
        name: 'NamedErrorPage',
        component: () => import('@/views/ErrorPage.vue'),
        meta: {
          title: 'Error',
          description: ''
        }
      }
    ]
  },
  {
    path: '*',
    name: 'NotFoundErrorPage',
    component: () => import('@whitelist/wl-old-library/views/404.vue'),
    meta: {
      title: 'Not found',
      description: '404: Not found'
    }
  }
]
