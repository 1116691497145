import { GetterTree, MutationTree, ActionTree } from 'vuex'
import { ActivePopupInterface } from './types'

const namespaced = true
class State {
  isOpened = false
  activePopup: null | ActivePopupInterface = null
  firstClosing = false
}
const getters = <GetterTree<State, any>>{
  getPopupIsOpened: state => state.isOpened,
  getActivePopup: state => state.activePopup,
  getFirstClosing: state => state.firstClosing
}
const actions = <ActionTree<State, any>>{
  openPopup ({ commit, dispatch }, payload: ActivePopupInterface) {
    dispatch('setClosed')
    commit('setOpened', payload)
  },
  setFirstClosing ({ commit }, payload: boolean) {
    commit('setFirstClosing', payload)
  },
  async setClosed ({ commit, getters }) {
    const activePopup = getters.getActivePopup

    commit('setClosed')
    if (activePopup && activePopup.onBeforeClose !== undefined) {
      await activePopup.onBeforeClose()
    }
  }
}
const mutations = <MutationTree<State>>{
  setOpened (state, payload: ActivePopupInterface) {
    state.isOpened = true
    state.activePopup = payload
  },
  setClosed (state) {
    state.isOpened = false
    state.activePopup = null
  },
  setSlotData (state, payload: NonNullable<ActivePopupInterface['slot']>['data']) {
    if (state.isOpened === true && state.activePopup !== null && state.activePopup.slot !== undefined) {
      state.activePopup = {
        ...state.activePopup,
        slot: {
          ...state.activePopup.slot,
          data: {
            ...state.activePopup.slot.data,
            ...payload
          }
        }
      }
    }
  },
  setFirstClosing (state, payload: boolean) {
    state.firstClosing = payload
  }
}

export default {
  namespaced,
  state: new State(),
  getters,
  actions,
  mutations
}
