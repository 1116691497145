
export default {
  name: 'FileUpload',
  data: () => ({
    file: '',
    dragging: false,
    previewImage: null
  }),
  watch: {
    file (file: any) {
      this.$emit('input', file)
    }
  },
  props: {
    extensions: {
      type: Array,
      required: false,
      default: () => (['image/jpeg', 'image/jpg', 'image/png', 'image/heif', 'image/webp'])
    },
    maxByteSize: {
      type: Number,
      required: false,
      default: 10485760
    }
  },
  methods: {
    acceptedExtensions (): Array<string> {
      return this.extensions.map((extension: any) => {
        return `.${extension.split('/')[1]}`
      }).join(', ')
    },
    onChange (e: any) {
      const files = e.target.files || e.dataTransfer.files
      if (!files.length) {
        this.dragging = false
        return
      }
      const reader = new FileReader()
      reader.onload = (e: any) => {
        this.previewImage = e.target.result
      }
      reader.readAsDataURL(files[0])
      this.createFile(files[0])
      this.$nextTick(() => this.$refs.fakeInput.focus())
    },
    createFile (file: any) {
      const isMatchExtension = this.extensions.some((extension: any) => extension === file.type)
      const vm = this as any
      if (file.size > this.maxByteSize) {
        this.$store.dispatch('alertsBus/openAlert', {
          title: vm.$t('alerts.titles.error'),
          type: 'error',
          autoDelete: true,
          content: `Неподходящий размер файла. Загрузите файл размером не более ${this.maxByteSize / 1e-6} МБ.`
        })
        this.dragging = false
        return
      }

      if (!isMatchExtension) {
        this.$store.dispatch('alertsBus/openAlert', {
          title: vm.$t('alerts..error'),
          type: 'error',
          autoDelete: true,
          content: `Неподходящий формат файла. Загрузите файл в формате ${this.$options.filters.formattedExtensions(this.extensions)}.`
        })
        this.dragging = false
        return
      }

      this.file = file
      this.dragging = false
    },
    removeFile () {
      this.file = ''
      this.previewImage = null
    }
  },
  computed: {
    extension () {
      return (this.file) ? this.file.name.split('.').pop() : ''
    }
  }
}
