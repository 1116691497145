import Vue from 'vue'
import { toMarkdown } from 'mdast-util-to-markdown'
import { IQuestionItem, TransfersType } from '@/components/pages/whitelistID/components/account/components/access-repair/accessRepairQuestionnaire/accessRepairQuestions/types'

export const transfersMixin = Vue.extend({
  watch: {
    'form.fields.questionnaire.inboundTransfers': {
      handler (newState) {
        if (newState.value) {
          this.form.fields.questionnaire.inboundTransfers.valid = true
          return
        }
        this.form.fields.questionnaire.inboundTransfers.valid = newState.subfields.some((item: { name: string, value: TransfersType }) => {
          return item.name.length > 0 && item.value.volume > 0 && item.value.date.length > 0
        })
      },
      deep: true
    },
    'form.fields.questionnaire.outboundTransfers': {
      handler (newState) {
        if (newState.value) {
          this.form.fields.questionnaire.outboundTransfers.valid = true
          return
        }
        this.form.fields.questionnaire.outboundTransfers.valid = newState.subfields.some((item: { name: string, value: TransfersType }) => {
          return item.name.length > 0 && item.value.volume > 0 && item.value.date.length > 0
        })
      },
      deep: true
    }
  },
  methods: {
    toMDinboundTransfers (field: IQuestionItem): any {
      return this.toMDTransfers(field)
    },
    toMDoutboundTransfers (field: IQuestionItem): any {
      return this.toMDTransfers(field)
    },
    toMDTransfers (field: IQuestionItem): any {
      if (!field) return []
      const result: any = [
        {
          type: 'heading',
          depth: 3,
          children: [
            {
              type: 'text',
              value: field.title
            }
          ]
        }
      ]
      const table: any = {
        type: 'table',
        children: [
          {
            type: 'tableRow',
            children: [
              {
                type: 'tableCell',
                children: [
                  {
                    type: 'strong',
                    children: [{ type: 'text', value: 'Монета' }]
                  }
                ]
              },
              {
                type: 'tableCell',
                children: [
                  {
                    type: 'strong',
                    children: [{ type: 'text', value: 'Объем' }]
                  }
                ]
              },
              {
                type: 'tableCell',
                children: [
                  {
                    type: 'strong',
                    children: [{ type: 'text', value: 'Дата' }]
                  }
                ]
              }
            ]
          }
        ]
      }
      for (const subfield of field.subfields as any) {
        table.children.push({
          type: 'tableRow',
          children: [
            {
              type: 'tableCell',
              children: [
                {
                  type: 'text',
                  value: subfield.name
                }
              ]
            },
            {
              type: 'tableCell',
              children: [
                {
                  type: 'text',
                  value: subfield.value.volume.toString()
                }
              ]
            },
            {
              type: 'tableCell',
              children: [
                {
                  type: 'text',
                  value: subfield.value.date.toString()
                }
              ]
            }
          ]
        })
      }
      result.push(table)
      return result
    }
  }
})
