import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import color from '@/constants/colors'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    dark: true,
    themes: {
      light: {
        primary: color.light.SYSTEM.DARK_9,
        secondary: color.light.ACCENT.BLUE_BASE,
        ...color.light
      },
      dark: {
        primary: color.dark.SYSTEM.DARK_9,
        secondary: color.light.ACCENT.BLUE_BASE,
        ...color.dark
      }
    }
  }
})
