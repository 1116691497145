import request from '@/services/base/request'

export default {
  sendConfirmCode: (data: any) => request(`/codes/${data.operation}/`, 'post', data.payload),
  yubikeyBegin: (data: any) => request(`/yubikey/register/begin/${data.sessionId}/`, 'post', data.payload, { responseType: 'arraybuffer' }),
  yubikeyComplete: (data: any) => request(`/yubikey/register/complete/${data.sessionId}/`, 'post', data.payload, { headers: { 'Content-Type': 'application/cbor', 'Cache-Control': 'no-store' } }),
  yubikeyAuthBegin: (data: any) => request(`/yubikey/authenticate/begin/${data.sessionId}/`, 'post', data.payload, { responseType: 'arraybuffer' }),
  yubikeyAuthComplete: (data: any) => request(`/yubikey/authenticate/complete/${data.sessionId}/`, 'post', data.payload, { headers: { 'Content-Type': 'application/cbor', 'Cache-Control': 'no-store' } }),
  yubikeyDelete: (data: any) => request(`/yubikey/cancel/${data.sessionId}/${data.keyId}/`, 'delete', null),
  getYubikeysList: (data: any) => request('/yubikey/user/', 'get', data),
  yubiKeyRename: (data: any) => request('/yubikey/fast_rename/', 'post', data),
  confirmSession: (data: any) => request(`/user/confirm_session/${data.sessionId}/`, 'get', null),
  validateSession: (data: any) => request(`/user/validate_session/${data.sessionId}/`, 'post', data.payload),
  loadUser: (data: any) => request(`/user/?timestamp=${Date.now()}`, 'get', data),
  logout: (data: any) => request('/logout/', 'post', data),
  refreshToken: (data: any) => request('/token/refresh/', 'post', data),
  changePasswordBegin: (data: any) => request('/password/change/begin/', 'post', data),
  changePasswordComplete: (data: any) => request(`/password/change/complete/${data.sessionId}/`, 'post', data.payload),
  changePhone: (data: any) => request('/user/', 'patch', data),
  changeDiscord: (data: any) => request('/user/discord_nickname/', 'post', data),
  deleteDiscord: (data: any) => request('/user/discord_nickname/', 'delete', data),
  changeTelegram: (data: any) => request('/user/telegram_nickname/', 'post', data),
  deleteTelegram: (data: any) => request('/user/telegram_nickname/', 'delete', data),
  check2FA: (data: any) => request('/otp/user/', 'get', data),
  create2FA: (data: any) => request(`/otp/new/${data.sessionId}/`, 'post', null),
  completeCreating2FA: (data: any) => request('/otp/verify/', 'put', data),
  delete2FA: (data: any) => request(`/otp/user/delete/${data.sessionId}`, 'delete', null),
  getState2FA: (data: any) => request('/user/two_factor/', 'get', data),
  switch2FA: (data: any) => request(`/user/two_factor/${data.sessionId}/`, 'put', null),
  switchUserConfirmMethod: (data: any) => request(`/user/confirm_methods/${data.sessionId}/`, 'post', data.payload),
  changeEmailBegin: (data: any) => request('/user/email_change/begin/', 'post', data),
  changeEmailComplete: (data: any) => request(`/user/email_change/complete/${data.sessionId}/`, 'post', data.payload),
  changePhoneBegin: (data) => request('/user/phone_change/begin/', 'post', data),
  changePhoneComplete: (data: any) => request(`/user/phone_change/complete/${data.sessionId}/`, 'post', data.payload),
  kycLevels: () => request('/user/verification_levels/', 'get', null),
  kycLink: (data: any) => request(`/kyc/?check_type=${data}`, 'get', null),
  startKycVerify: (data: any) => request(`/kyc/status/${data.verificationId}/`, 'patch', null),
  accessRepairInit: (data: any) => request(`/access_recovery/initial/${data.secondFactor}/${data.sessionId}/`, 'post', null),
  accessRepairSendSMS: (data: any) => request(`/access_recovery/new_phone/${data.recoveryId}/`, 'post', data.payload),
  getAccessInfo: (data: any) => request(`/access_recovery/work/${data.recoveryId}/`, 'get', null),
  bindPhoneToSession: (data: any) => request(`/access_recovery/work/${data.recoveryId}/`, 'patch', data.payload),
  resetAccess: (data: any) => request(`/access_recovery/work/${data.recoveryId}/`, 'post', data.payload, { headers: { 'Content-Type': 'multipart/form-data', 'Cache-Control': 'no-store' } }),
  resetAccessBasic: (data: any) => request(`/access_recovery/work/${data.recoveryId}/`, 'post', data.payload)
} as {[key: string]: MethodDecorator}
