import Vue from 'vue'
import { toMarkdown } from 'mdast-util-to-markdown'
import { IQuestionItem } from '@/components/pages/whitelistID/components/account/components/access-repair/accessRepairQuestionnaire/accessRepairQuestions/types'

export const anyAPIKeyNameMixin = Vue.extend({
  watch: {
    'form.fields.questionnaire.anyAPIKeyName.value' (newValue) {
      this.form.fields.questionnaire.anyAPIKeyName.valid = newValue.length > 1
    }
  },
  methods: {
    toMDanyAPIKeyName (field: IQuestionItem): any {
      if (!field) return []
      return [
        {
          type: 'heading',
          depth: 3,
          children: [
            {
              type: 'text',
              value: field.title
            }
          ]
        },
        {
          type: 'paragraph',
          children: [
            {
              type: 'text',
              value: field.value
            }
          ]
        }
      ]
    }
  }
})
