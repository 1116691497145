
import Vue from 'vue'
import moment from 'moment'

export default Vue.extend({
  name: 'transfers',
  props: {
    subfields: {
      type: Array,
      required: true
    },
    value: {
      type: Boolean,
      required: true
    },
    valid: {
      type: Boolean,
      default: false
    },
    checkboxLabel: {
      type: String,
      default: '',
      required: true
    }
  },
  data: () => ({
    numberboxConfig: {
      rules: [],
      touched: false,
      stepSize: 0.1,
      maskConfig: {
        alias: 'numeric',
        allowMinus: false,
        digits: 2,
        min: 0,
        max: Infinity
      },
      min: 0,
      max: Infinity
    },
    tempDateValue: ''
  }),
  computed: {
    isCurrentSubfieldValid () {
      const lastField: any = this.subfields[this.subfields.length - 1]
      return Boolean(lastField.name) && Boolean(lastField.value.date) && Boolean(lastField.value.volume)
    }
  },
  methods: {
    getToday (): string {
      return moment().format('YYYY-MM-DD')
    },
    transformDate (date: string) {
      return moment(date).format('DD.MM.YYYY')
    }
  }
})
