const EventPathPolyfill = () => {
  if (!('path' in Event.prototype)) {
    Object.defineProperty(Event.prototype, 'path', {
      get: function () {
        const path = []
        let currentElem = this.target
        while (currentElem) {
          path.push(currentElem)
          currentElem = currentElem.parentElement
        }
        if (path.indexOf(window) === -1 && path.indexOf(document) === -1) {
          path.push(document)
        }
        if (path.indexOf(window) === -1) {
          path.push(window)
        }
        return path
      }
    })
  }
}

const LastElementInArray = () => {
  if (!('last' in Array.prototype)) {
    Object.defineProperty(Array.prototype, 'last', {
      get: function () {
        return this[this.length - 1]
      }
    })
  }
}

const ElementFromArrayEnd = () => {
  if (!('fromEnd' in Array.prototype)) {
    Object.defineProperty(Array.prototype, 'fromEnd', {
      // @ts-ignore
      get: function () {
        return (num: number) => this[this.length - num]
      }
    })
  }
}

export default {
  EventPathPolyfill,
  LastElementInArray,
  ElementFromArrayEnd
}
