
import Vue from 'vue'
import { mapGetters } from 'vuex'
import { discordAllowedSymbolsRegExp, telegramAllowedSymbolsRegExp } from '@/constants/baseConstatnts'
import { capitalizeFirstLetter } from '@/utils/strings'
import { formatDiscordNickname } from '@/utils/formatters'

export default Vue.extend({
  name: 'socialNetworks',
  data () {
    return {
      socialForm: {
        fields: {
          discord: {
            valid: false,
            value: '',
            errorMessages: [],
            rules: [
              (v: string) => {
                return Boolean(v.length) || this.$t('main.socialNetworks.errors.invalidName')
              },
              (v: string) => {
                return discordAllowedSymbolsRegExp.test(v.trim()) || this.$t('main.socialNetworks.errors.invalidName')
              }
            ],
            isLoading: false,
            isEditing: false
          },
          telegram: {
            valid: false,
            value: '',
            errorMessages: [],
            rules: [
              (v: string) => {
                return Boolean(v.length) || this.$t('main.socialNetworks.errors.invalidName')
              },
              (v: string) => {
                return telegramAllowedSymbolsRegExp.test(v.trim()) || this.$t('main.socialNetworks.errors.invalidName')
              }
            ],
            isLoading: false,
            isEditing: false
          }
        }
      }
    }
  },
  computed: {
    ...mapGetters('user', ['getUserData'])
  },
  methods: {
    changeEditing (type: 'telegram' | 'discord') {
      this.socialForm.fields[type].isEditing = true
      if (!this.getUserData[`${type}_nickname`]) return
      this.socialForm.fields[type].value = this.getUserData[`${type}_nickname`]
    },
    async handleNickChangeButtonClick (type: 'telegram' | 'discord'): Promise<void> {
      const vm = this as any
      vm.socialForm.fields[type].isLoading = true

      const nickname = vm.socialForm.fields[type].value.trim()

      const payloadNicknameKey = type === 'telegram' ? 'telegram_nickname' : 'nickname'
      const data = nickname ? { method: `change${capitalizeFirstLetter(type)}`, payload: { [payloadNicknameKey]: nickname } } : { method: `delete${capitalizeFirstLetter(type)}`, payload: null }

      if (data.method === 'changeDiscord' && data.payload !== null) {
        const formattedNickname = formatDiscordNickname(data.payload[payloadNicknameKey])
        data.payload[payloadNicknameKey] = formattedNickname
      }

      const response = await vm.$services.authorization.user[data.method](data.payload)

      vm.socialForm.fields[type].isEditing = false
      vm.socialForm.fields[type].isLoading = false

      if (!response.success) {
        vm.$store.dispatch('alertsBus/openAlert', {
          title: vm.$t('alerts.titles.error'),
          type: 'error',
          autoDelete: true,
          content: vm.$t('alerts.content.nicknameChangeFailed', { type: capitalizeFirstLetter(type) })
        })
        return
      }

      vm.$store.commit('user/setUserData', {
        ...vm.getUserData,
        [`${type}_nickname`]: nickname
      })

      vm.$store.dispatch('alertsBus/openAlert', {
        title: vm.$t('alerts.titles.success'),
        type: 'success',
        autoDelete: true,
        content: vm.$t('alerts.content.nicknameChangeSuccess', { type: capitalizeFirstLetter(type) })
      })
    }
  },
  beforeCreate (): void {
    const vm = this as any
    if (!vm.$store.getters['user/getIsAuthenticated']) {
      vm.$store.dispatch('user/loadUser')
    }
  }
})
