import Vue from 'vue'
import { IQuestionItem } from '@/components/pages/whitelistID/components/account/components/access-repair/accessRepairQuestionnaire/accessRepairQuestions/types'

export const fullnameAndEmailMixin = Vue.extend({
  methods: {
    toMDfullnameAndEmail (field: IQuestionItem): any {
      if (!field) return []
      const result = [
        {
          type: 'heading',
          depth: 3,
          children: [
            {
              type: 'text',
              value: field.title
            }
          ]
        }
      ]
      const list: any = {
        type: 'list',
        children: []
      }
      for (const subfield of field.subfields!) {
        list.children.push({
          type: 'listItem',
          children: [
            {
              type: 'strong',
              children: [
                {
                  type: 'text',
                  value: subfield.name
                }
              ]
            },
            {
              type: 'text',
              value: ' - ' + subfield.value
            }
          ]
        })
      }
      result.push(list)
      return result
    }
  }
})
