
import Vue from 'vue'
import phoneInput from '@/components/pages/register/phoneInput/index.vue'
import SERVICES from '@/constants/services'
import moment from 'moment'

export default Vue.extend({
  name: 'AccessPhoneRepair',
  components: {
    phoneInput
  },
  props: {
    showWarningText: {
      type: String,
      required: false,
      default: ''
    }
  },
  data () {
    return {
      form: {
        responseErrors: [],
        fields: {
          smsCode: {
            sessionId: '',
            codeSendError: false,
            codeIsSent: false,
            codeSentBefore: false,
            sending: false,
            retryTimeout: 60,
            retries: 3,
            letRetry: false,
            show: false,
            valid: false,
            isLoading: false,
            verified: false,
            value: '',
            serverError: '',
            rules: [
              (v: string) => Boolean(v) || this.$t('common.errors.required'),
              (v: string) => v.length === SERVICES.CODE_LENGTH || this.$t('common.errors.codeMustConsist', { length: SERVICES.CODE_LENGTH })
            ]
          },
          phone: {
            errors: [],
            valid: false,
            value: '',
            dial: '',
            valueWithDial: ''
          }
        }
      }
    }
  },
  watch: {
    'form.fields.smsCode.retryTimeout' (v) {
      const vm = this as any
      if (v > 0) {
        setTimeout(() => {
          vm.form.fields.smsCode.retryTimeout = v - 1
        }, 1000)
      } else vm.form.fields.smsCode.letRetry = true
    },
    'form.fields.smsCode.value' (value: string) {
      this.form.fields.smsCode.serverError = ''
      if (value.length === SERVICES.CODE_LENGTH) this.checkSMS()
    },
    'form.fields.smsCode.verified' (value: boolean) {
      this.$emit('phoneVerified', value)
    }
  },
  created () {
    this.recoveryId = this.$route.query.recoveryId
  },
  methods: {
    phoneInputHandler () {
      this.form.fields.smsCode.show = false
      this.form.fields.smsCode.value = ''
      this.form.responseErrors = []
    },
    async sendSMS () {
      this.form.responseErrors = []
      const phone = this.form.fields.phone.valueWithDial
      this.form.fields.smsCode.codeSentBefore = false
      this.form.fields.smsCode.sending = true
      const response = await this.$services.authorization.user.accessRepairSendSMS({ recoveryId: this.recoveryId, payload: { phone } })
      this.form.fields.smsCode.sending = false
      if (response.success) {
        this.form.fields.smsCode.show = true
        this.form.fields.smsCode.sessionId = response.data.sessionId
        this.form.fields.smsCode.codeIsSent = true
        this.form.fields.smsCode.letRetry = false
        this.form.fields.smsCode.retryTimeout = moment(response.data.repeatAfter).diff(moment(), 'seconds')
        this.form.fields.smsCode.retries -= 1
        setTimeout(() => {
          this.form.fields.smsCode.retryTimeout -= 1
        }, 1000)
      } else {
        if (response?.data?.repeatAfter) {
          this.form.fields.smsCode.codeSentBefore = true
          this.form.fields.smsCode.codeIsSent = true
          this.form.fields.smsCode.letRetry = false
          this.form.fields.smsCode.retryTimeout = moment(response.data.repeatAfter).diff(moment(), 'seconds')
          this.form.fields.smsCode.retries -= 1
          this.form.fields.smsCode.show = true
          setTimeout(() => {
            this.form.fields.smsCode.retryTimeout -= 1
          }, 1000)
        } else {
          this.form.fields.smsCode.codeSendError = true
          this.form.fields.smsCode.letRetry = true
          this.form.responseErrors = response.data.errors
        }
      }
    },
    async checkSMS () {
      this.form.fields.smsCode.isLoading = true
      const sessionId = this.form.fields.smsCode.sessionId
      const validateSessionResponse = await this.$services.authorization.user.validateSession({ payload: { sms: this.form.fields.smsCode.value }, sessionId })
      if (!validateSessionResponse.success) {
        this.form.fields.smsCode.serverError = validateSessionResponse.data.errors[0]
        this.form.fields.smsCode.isLoading = false
        return
      }
      const bindPhoneResponse = await this.$services.authorization.user.bindPhoneToSession({ recoveryId: this.recoveryId, payload: { session_id: sessionId } })
      if (!bindPhoneResponse.success) {
        return
      }
      this.form.fields.smsCode.verified = true
    }
  }
})
