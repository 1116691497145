import signup from '@/services/authorization/signup'
import signin from '@/services/authorization/signin'
import repair from '@/services/authorization/repair'
import user from '@/services/authorization/user'
import authentication from '@/services/authorization/authentication'

export default {
  signup,
  signin,
  repair,
  user,
  authentication
}
