
import Vue from 'vue'
import moment from 'moment'
import { ActivePopupTypes } from '@/store/modules/popup/types'
import { mapGetters } from 'vuex'
import stepByStepValidate from '@whitelist/wl-old-library/services/_helpers/stepByStepValidate'
import { declOfNum } from '@/utils/numbers'
import SERVICES_CONSTANTS, { StepByStepGlossary } from '@/constants/services'
import YubikeyAuthMixin from '@/mixins/YubikeyAuthMixin'
import { capitalizeFirstLetter } from '@/utils/strings'
import password from './components/password/index.vue'
import { emailAllowedSymbolsRegExp } from '@/constants/baseConstatnts'
import phoneInput from '@/components/pages/register/phoneInput/index.vue'

const cbor = require('cbor')

enum SecondFactorTypes {
  sms = 'sms',
  ga = 'ga',
  yubikey = 'yubikey'
}

export default Vue.extend({
  name: 'userSafety',
  mixins: [YubikeyAuthMixin],
  props: {
    currentKycLevelData: {
      type: Object,
      default: () => ({}),
      required: true
    },
    changeKycAccordionStatus: {
      type: Function,
      default: () => ({}),
      required: true
    }
  },
  data () {
    return {
      editingKey: {
        id: null,
        disabled: false,
        valid: false,
        value: '',
        rules: [
          (v: string) => v.length > 0 || this.$t('common.fieldCantBeEmpty'),
          (v: string) => v.length < 21 || this.$t('common.maxSymbolQuantity', { length: 21 })
        ]
      },
      phoneEdit: false,
      showPhoneEditWarn: false,
      phoneForm: {
        fields: {
          phone: {
            errors: [],
            valid: false,
            value: '',
            dial: '',
            valueWithDial: ''
          }
        }
      },
      emailEdit: false,
      showEmailEditWarn: false,
      emailForm: {
        fields: {
          email: {
            loading: false,
            valid: false,
            value: '',
            dial: '',
            rules: [
              (v: string) => Boolean(v.length) || 'login.errors.loginRequired',
              (v: string) => emailAllowedSymbolsRegExp.test(v.trim()) || 'login.errors.emailIncorrect'
            ]
          }
        }
      },
      GASet: false,
      showYubikeyEditWarn: false,
      availableYubiKeys: 0,
      yubiKeys: [],
      GAPlugLoading: false,
      yubiKeySet: true,
      secondFactorEnabled: false,
      secondFactorDisabledAwait: false,
      secondFactorPlugLoading: false,
      secondFactorForm: {
        valid: false,
        editing: false,
        fields: {
          secondFactorType: {
            value: ''
          }
        }
      },
      operationChangeLoading: false,
      operationsForm: {
        valid: false,
        editing: false,
        fields: {
          confirmationType: {
            value: SecondFactorTypes.sms
          }
        }
      },
      available2FAMethods: [],
      current2FA: 'off',
      panel: 0
    }
  },
  components: {
    password,
    phoneInput
  },
  computed: {
    ...mapGetters('popupModule', ['getPopupIsOpened']),
    ...mapGetters('user', ['getUserData']),
    fullBlockOptions () {
      const repairingMethods = this.getUserData.currentAccessRepairMethods
      const enterMethod = this.secondFactorForm.fields.secondFactorType.value
      const currentOperation = this.getUserData.confirmation
      return repairingMethods.includes(enterMethod) || repairingMethods.includes(currentOperation)
    },
    declOfNum: () => declOfNum,
    customAlertContent (): string {
      const repairingGaOrYubikey = this.getUserData.currentAccessRepairMethods.some((el: string) => el === 'ga' || el === 'yubikey')
      const showAdditionalText = repairingGaOrYubikey && !this.fullBlockOptions
      return `${this.$t('main.safety.changing')} ${showAdditionalText ? this.$t('main.safety.someSettings') : this.$t('main.safety.settings')} ${this.$t('main.safety.unavailable')}`
    },
    ableToChangeOperation (): boolean {
      const vm = this as any
      return vm.operationsForm.fields.confirmationType.value === vm.getUserData.confirmation
    },
    isOperationRepairing (): boolean {
      const repairingMethods = this.getUserData.currentAccessRepairMethods
      const currentOperation = this.getUserData.confirmation
      return repairingMethods.includes(currentOperation)
    },
    isEnterMethodRepairing (): boolean {
      const repairingMethods = this.getUserData.currentAccessRepairMethods
      const enterMethod = this.secondFactorForm.fields.secondFactorType.value
      return repairingMethods.includes(enterMethod)
    },
    servicesConst () {
      return SERVICES_CONSTANTS
    }
  },
  watch: {
    'getUserData.confirmation' (v) {
      const vm = this as any
      vm.operationsForm.fields.confirmationType.value = v
    },
    'phoneForm.fields.phone.value' () {
      this.phoneForm.fields.phone.errors = []
    },
    getPopupIsOpened (v) {
      const vm = this as any
      if (!v) {
        vm.doCheck2FA()
        this.cancelChangeOperation()
        this.GAPlugLoading = false
      }
    }
  },
  methods: {
    clickPhoneEditHandler () {
      if (!this.currentKycLevelData.phoneChange) {
        this.showPhoneEditWarn = true
        return
      }
      this.showPhoneEditWarn = false
      this.phoneEdit = true
    },
    clickEmailEditHandler () {
      if (!this.currentKycLevelData.emailChange) {
        this.showEmailEditWarn = true
        return
      }
      this.showEmailEditWarn = false
      this.emailEdit = true
    },
    async changeYubikeyName (yubikey: any) {
      const vm = this as any

      if (vm.editingKey.loading) return

      if (vm.editingKey.value === yubikey.name || !vm.editingKey.valid) {
        vm.editingKey.id = null
        return
      }

      vm.editingKey.loading = true
      vm.editingKey.disabled = true

      const payload = {
        key_id: yubikey.id,
        name: vm.editingKey.value
      }

      const response = await vm.$services.authorization.user.yubiKeyRename(payload)

      vm.editingKey.disabled = false
      vm.editingKey.loading = false

      if (response.success) {
        vm.editingKey.id = null
        vm.editingKey.value = ''
        vm.getYubikeysList()
        return
      }

      vm.serverErrorHandler(response.data.errors)

      setTimeout(() => {
        const input = vm.$refs[`yubikey${yubikey.id}`][0]
        if (input)input.focus()
      })
    },

    yubikeyNameClickHandler (yubikey: any) {
      const vm = this as any
      vm.editingKey.id = yubikey.id
      vm.editingKey.value = yubikey.name

      setTimeout(() => {
        const input = vm.$refs[`yubikey${yubikey.id}`][0]
        input.focus()
      })
    },

    serverErrorHandler (errors: any) {
      const vm = this as any
      vm.$store.commit('popupModule/setClosed')
      vm.$store.dispatch('alertsBus/openAlert', {
        title: vm.$t('alerts.titles.error'),
        type: 'error',
        autoDelete: true,
        content: errors?.join('\n') ?? vm.$t('common.errors.cantDoOperation')
      })
    },

    keyStringEnding (quantity: number): string {
      if (quantity === 1) return this.$t('common.key')
      return quantity >= 2 && quantity <= 4 ? this.$t('common.keyTypeA') : this.$t('common.keys')
    },

    async getYubikeysList (): Promise<void> {
      const vm = this as any
      const response = await vm.$services.authorization.user.getYubikeysList(null)
      if (response.success) {
        vm.yubiKeys = response.data.yubikeys.map((item: any) => ({
          name: item.name,
          id: item.id,
          createdAt: moment(item.created_at).format('DD.MM.YYYY HH:mm'),
          lastTimeUsage: item.last_use ? moment(item.last_use).format('DD.MM.YYYY HH:mm') : vm.$t('main.accountVerify.notUsed')
        }))
        vm.availableYubiKeys = response.data.available
      } else {
        setTimeout(() => {
          vm.getYubikeysList()
        }, 1000)
      }
    },

    removeYubiKeyHandler (id: number, name: string): void {
      const vm = this as any

      const operationsYubikeyConfirm = vm.getUserData.confirmation === SecondFactorTypes.yubikey ? vm.$t('popups.content.operationConfirmationWarning') : ''
      const enterYubikeyConfirm = vm.current2FA === SecondFactorTypes.yubikey ? vm.$t('popups.content.enterWithout2FA') : ''
      const isKeyLast = vm.yubiKeys.length === 1
      const has2FAKeyLast = isKeyLast && (enterYubikeyConfirm || operationsYubikeyConfirm)

      const removeYubiKey = () => {
        const additionalPopupContent = this.$t('popups.content.toProceedNeedYubikey')
        vm.YubikeyAuth(StepByStepGlossary.deleteYubikey, 'delete_yubikey', async (authResponse: any, sessionId: string) => {
          if (!authResponse.success) return vm.serverErrorHandler(authResponse.errors)
          const deleteResponse = await stepByStepValidate({
            name: StepByStepGlossary.deleteYubikey,
            endpointName: 'yubikeyDelete',
            data: {
              sessionId,
              keyId: id
            }
          })

          if (!deleteResponse.success) return vm.serverErrorHandler(deleteResponse.errors)

          vm.getYubikeysList()
          vm.$store.dispatch('user/loadUser')
          vm.$store.commit('popupModule/setClosed')

          vm.$store.dispatch('alertsBus/openAlert', {
            title: vm.$t('alerts.titles.success'),
            type: 'success',
            autoDelete: true,
            content: vm.$t('alerts.content.keyDeleted', { name })
          })

          if (has2FAKeyLast) {
            vm.$store.dispatch('alertsBus/openAlert', {
              title: vm.$t('alerts.titles.success'),
              type: 'success',
              autoDelete: true,
              content: vm.$t('alerts.content.settingUpdated')
            })
          }

          if (enterYubikeyConfirm && isKeyLast) {
            setTimeout(() => {
              vm.$store.dispatch('alertsBus/openAlert', {
                title: vm.$t('alerts.titles.attention'),
                type: 'warning',
                autoDelete: true,
                content: vm.$t('alerts.content.enterToLkWithoutConfirmation')
              })
            }, 400)
          }
          vm.doCheck2FA()
        }, null, vm.removeYubiKeyHandler, additionalPopupContent)
      }

      if (!has2FAKeyLast) return removeYubiKey()

      vm.$store.dispatch('popupModule/openPopup', {
        type: 'confirmPopup',
        letDefaultClose: true,
        title: vm.$t('popups.titles.disableYubikey'),
        content: `${enterYubikeyConfirm}
        ${operationsYubikeyConfirm}`,
        actions: [
          {
            title: vm.$t('popups.actions.continue'),
            isAccent: true,
            callback: () => removeYubiKey()
          },
          {
            title: vm.$t('popups.actions.cancel'),
            isAccent: false,
            callback: () => vm.$store.commit('popupModule/setClosed')
          }]
      })
    },

    handleAddYubiKey (): void {
      const vm = this as any
      if (!this.currentKycLevelData.yubikeyInstall) {
        this.showYubikeyEditWarn = true
        return
      }
      this.showPhoneEditWarn = false
      let sessionId: null | string = null
      const beginAddYubiKey = async () => {
        const additionalPopupContent = vm.$t('popups.needLinkedKeys')
        const confirmationType = vm.getUserData.confirmation
        if (confirmationType === SecondFactorTypes.yubikey) {
          const yubikeyAddyubiKey = () => {
            vm.YubikeyAuth(StepByStepGlossary.addYubikey, StepByStepGlossary.addYubikey, async (_: any, yubikeyAuthSessionId: string) => {
              sessionId = yubikeyAuthSessionId
              openYubikeySetupNamePopup()
            }, null, yubikeyAddyubiKey, additionalPopupContent)
          }
          yubikeyAddyubiKey()
          return
        }

        const createSessionResponse = await stepByStepValidate({
          name: StepByStepGlossary.addYubikey,
          endpointName: StepByStepGlossary.confirmSession,
          data: {
            sessionId: StepByStepGlossary.addYubikey
          },
          steps: 5
        })

        sessionId = createSessionResponse.data.session_id
        if (createSessionResponse.success) {
          const confirmationType = vm.getUserData.confirmation
          vm.$store.dispatch('popupModule/openPopup', {
            type: ActivePopupTypes.action,
            letDefaultClose: true,
            title: vm.$t('popups.titles.connectYubikey'),
            slot: {
              name: `email${confirmationType.toUpperCase()}ConfirmPopupChunk`,
              data: {
                codes: createSessionResponse.data.confirmations,
                name: StepByStepGlossary.addYubikey,
                endpointName: StepByStepGlossary.validateSession,
                sessionId,
                changeKycAccordionStatus: vm.changeKycAccordionStatus
              },
              on: {
                onSuccess: openYubikeySetupNamePopup
              }
            }
          })
        } else { vm.serverErrorHandler(createSessionResponse.errors) }
      }

      vm.$store.dispatch('popupModule/openPopup', {
        type: ActivePopupTypes.action,
        letDefaultClose: true,
        title: vm.$t('popups.titles.connectYubikey'),
        slot: {
          name: 'YubikeySetupBeginPopupChunk',
          on: {
            onSubmit: beginAddYubiKey
          }
        }
      })

      const openYubikeySetupNamePopup = () => {
        vm.$store.dispatch('popupModule/openPopup', {
          type: ActivePopupTypes.action,
          letDefaultClose: true,
          title: vm.$t('popups.titles.connectYubikey'),
          content: vm.$t('popups.content.createYubikey'),
          slot: {
            name: 'YubikeySetupNamePopupChunk',
            on: {
              onConfirm: openYubikeySetupPermissionsPopup,
              onCancel (): void {
                vm.$store.commit('popupModule/setClosed')
              }
            }
          }
        })
      }

      const openYubikeySetupPermissionsPopup = (name: string) => {
        if (!vm.availableYubiKeys) {
          vm.$store.commit('popupModule/setClosed')
          vm.$store.dispatch('alertsBus/openAlert', {
            title: vm.$t('alerts.error'),
            type: 'error',
            autoDelete: true,
            content: vm.$t('alerts.content.maxKeysReached')
          })
          return
        }

        if (vm.yubiKeys.length) return beginCreateWebauthPayload({ keyName: name })

        vm.$store.dispatch('popupModule/openPopup', {
          type: ActivePopupTypes.action,
          letDefaultClose: true,
          title: vm.$t('popups.titles.connectYubikey'),
          content: vm.$t('popups.content.yubikeyWillBeValidFor'),
          slot: {
            name: 'YubikeySetupPermissionsPopupChunk',
            on: {
              onConfirm (payload: { allowLogin: { value: boolean }, allowConfirmations: { value: boolean } }): void {
                beginCreateWebauthPayload({ keyName: name, use2fa: payload.allowLogin.value, useConfirmation: payload.allowConfirmations.value })
              },
              onCancel (): void {
                vm.$store.commit('popupModule/setClosed')
              }
            }
          }
        })
      }

      const beginCreateWebauthPayload = async (payload: { keyName: string, use2fa?: boolean, useConfirmation?: boolean }) => {
        if (!sessionId) return vm.serverErrorHandler(null)
        const createWebauthPayloadResponse = await stepByStepValidate({
          name: StepByStepGlossary.addYubikey,
          endpointName: 'yubikeyBegin',
          data: {
            sessionId,
            payload: {
              key_name: payload.keyName,
              use_2fa: payload.use2fa,
              use_confirmation: payload.useConfirmation
            }
          }
        })

        if (!createWebauthPayloadResponse.success) return vm.serverErrorHandler(createWebauthPayloadResponse.errors)

        const webauthRegister = async (): Promise<void> => {
          vm.$store.dispatch('popupModule/openPopup', {
            type: ActivePopupTypes.action,
            letDefaultClose: true,
            title: vm.$t('popups.titles.connectingWithYubikey'),
            slot: {
              name: 'yubikeyConnectionPopupChunk',
              data: {
                additionalPopupContent: vm.$t('popups.keyRequiredForFurther')
              },
              on: {
                onClickRetry: webauthRegister
              }
            }
          })

          const webauthRequestBody = cbor.decode(createWebauthPayloadResponse.data)

          let timeoutId: null | number = null
          if (timeoutId !== null) timeoutId = null

          timeoutId = setTimeout(() => {
            timeoutId = null
          }, SERVICES_CONSTANTS.WEBAUTH_CONNECTION_TIMEOUT)

          webauthRequestBody.publicKey.timeout = SERVICES_CONSTANTS.WEBAUTH_CONNECTION_TIMEOUT

          try {
            const navigatorResponse = await window.navigator.credentials.create(webauthRequestBody) as any

            const finalRegisterYubikeyResponse = await stepByStepValidate({
              name: StepByStepGlossary.addYubikey,
              endpointName: 'yubikeyComplete',
              data: {
                sessionId,
                payload: cbor.encode({
                  attestationObject: navigatorResponse.response.attestationObject,
                  clientDataJSON: navigatorResponse.response.clientDataJSON
                })
              }
            })

            if (!finalRegisterYubikeyResponse?.success) return vm.serverErrorHandler(finalRegisterYubikeyResponse.errors)

            vm.$store.commit('popupModule/setClosed')

            vm.$store.dispatch('alertsBus/openAlert', {
              title: vm.$t('alerts.titles.success'),
              type: 'success',
              autoDelete: true,
              content: vm.$t('alerts.content.keySuccessAdded', { keyName: payload.keyName })
            })

            if (payload.use2fa || payload.useConfirmation) {
              vm.$store.dispatch('alertsBus/openAlert', {
                title: vm.$t('alerts.titles.info'),
                type: 'info',
                autoDelete: true,
                content: vm.$t('alerts.content.2FASettingsUpdated')
              })
              vm.$store.dispatch('user/loadUser')
              vm.doCheck2FA()
            }

            vm.getYubikeysList()
          } catch (error: any) {
            console.error(error.message, error.code)

            const navigatorError = error.code === 11 ? vm.$t('popups.content.tryRegExistedKey') : vm.$t('popups.content.connectionLost')
            const popupContent = timeoutId ? vm.$t('popups.content.connectionLost') : vm.$t('popups.content.timoutEstimated')

            vm.$store.dispatch('popupModule/openPopup', {
              type: ActivePopupTypes.action,
              letDefaultClose: true,
              title: vm.$t('popups.titles.connectingWithYubikey'),
              content: navigatorError ?? popupContent,
              slot: {
                name: 'YubikeyErrorPopupChunk',
                data: {
                  sessionId,
                  showAccessRepair: this.yubiKeys.length > 0
                },
                on: {
                  onSubmit: webauthRegister
                }
              }
            })
          }
        }

        webauthRegister()
      }
    },
    operationTypeString (value: string, capitalizeFirstChar = false) {
      const operationTypeMap = {
        sms: this.$t('common.bySMS'),
        ga: this.$t('common.byGA'),
        yubikey: this.$t('common.byYubikey')
      } as any
      if (!operationTypeMap[value]) return ''
      return capitalizeFirstChar ? capitalizeFirstLetter(operationTypeMap[value]) : operationTypeMap[value]
    },
    cancelChangeOperation () {
      const vm = this as any
      vm.operationsForm.editing = false
      vm.operationsForm.fields.confirmationType.value = vm.getUserData.confirmation
    },
    async handlePhoneChangeButtonClick (): Promise<void> {
      const vm = this as any

      const response = await vm.$services.authorization.user.changePhoneBegin({ phone: vm.phoneForm.fields.phone.valueWithDial })

      if (!response.success) {
        vm.phoneForm.fields.phone.errors = response.data.errors
        return
      }

      const sessionId = response.data.session_id
      const currentConfirmationType = vm.getUserData.confirmation

      if (currentConfirmationType === SecondFactorTypes.yubikey) {
        const yubikeyChangePhone = (): void => {
          vm.YubikeyAuth(StepByStepGlossary.changePhone, null, async (authResponse: any) => {
            if (!authResponse.success) return vm.serverErrorHandler(authResponse.errors)
            vm.$store.dispatch('popupModule/openPopup', {
              type: ActivePopupTypes.action,
              letDefaultClose: true,
              title: vm.$t('popups.titles.changePhoneNumber'),
              content: vm.$t('popups.content.newPhoneConfirm'),
              slot: {
                name: 'commonConfirmSMSPopupChunk',
                data: {
                  phoneNumber: vm.phoneForm.fields.phone.valueWithDial,
                  codes: {
                    sms: {
                      type: 'phone_change_sms'
                    }
                  }
                },
                on: {
                  async onSubmit (data: any) {
                    vm.$store.commit('popupModule/setSlotData', { isLoading: true })
                    const validateResponse = await vm.$services.authorization.user.validateSession({ payload: { new_sms: data.code_sms }, sessionId })
                    if (!validateResponse.success) {
                      vm.$store.commit('popupModule/setSlotData', { responseErrors: validateResponse.data.errors, isLoading: false })
                      return
                    }
                    const response = await vm.$services.authorization.user.changePhoneComplete({ sessionId })
                    vm.$store.commit('popupModule/setClosed')
                    vm.phoneEdit = false
                    if (response.success) {
                      vm.$store.dispatch('alertsBus/openAlert', {
                        title: vm.$t('alerts.titles.success'),
                        type: 'success',
                        autoDelete: true,
                        content: `${vm.$t('alerts.content.phoneChangedTo')}
                        ${vm.phoneForm.fields.phone.valueWithDial}`
                      })
                      vm.$store.dispatch('user/loadUser')
                    } else {
                      vm.$store.dispatch('alertsBus/openAlert', {
                        title: vm.$t('popups.titles.error'),
                        type: 'error',
                        autoDelete: true,
                        content: vm.$t('popups.content.failedChangeNumber')
                      })
                    }
                  },
                  onCancel () {
                    vm.$store.commit('popupModule/setClosed')
                  }
                }
              }
            })
          }, sessionId, yubikeyChangePhone)
        }
        yubikeyChangePhone()
        return
      }

      vm.$store.dispatch('popupModule/openPopup', {
        type: ActivePopupTypes.action,
        letDefaultClose: true,
        title: vm.$t('popups.titles.changePhoneNumber'),
        content: vm.$t('popups.content.confirmOperation'),
        slot: {
          name: `email${currentConfirmationType.toUpperCase()}ConfirmPopupChunk`,
          data: {
            endpointName: StepByStepGlossary.validateSession,
            name: StepByStepGlossary.changePhone,
            codes: response.data.confirmations,
            sessionId,
            steps: 3
          },
          on: {
            async onSuccess () {
              vm.$store.dispatch('popupModule/openPopup', {
                type: ActivePopupTypes.action,
                letDefaultClose: true,
                title: vm.$t('popups.titles.changePhoneNumber'),
                content: vm.$t('popups.content.newPhoneConfirm'),
                slot: {
                  name: 'commonConfirmSMSPopupChunk',
                  data: {
                    phoneNumber: vm.phoneForm.fields.phone.valueWithDial,
                    codes: {
                      sms: {
                        type: 'phone_change_sms'
                      }
                    }
                  },
                  on: {
                    async onSubmit (data: any) {
                      vm.$store.commit('popupModule/setSlotData', { isLoading: true })
                      const validateResponse = await vm.$services.authorization.user.validateSession({ payload: { new_sms: data.code_sms }, sessionId })
                      if (!validateResponse.success) {
                        vm.$store.commit('popupModule/setSlotData', { responseErrors: validateResponse.data.errors, isLoading: false })
                        return
                      }
                      const response = await vm.$services.authorization.user.changePhoneComplete({ sessionId })
                      vm.$store.commit('popupModule/setClosed')
                      vm.phoneEdit = false
                      if (response.success) {
                        vm.$store.dispatch('alertsBus/openAlert', {
                          title: vm.$t('alerts.titles.success'),
                          type: 'success',
                          autoDelete: true,
                          content: `${vm.$t('alerts.content.phoneChangedTo')}
                          ${vm.phoneForm.fields.phone.valueWithDial}`
                        })
                        vm.$store.dispatch('user/loadUser')
                      } else {
                        vm.$store.dispatch('alertsBus/openAlert', {
                          title: vm.$t('popups.titles.error'),
                          type: 'error',
                          autoDelete: true,
                          content: vm.$t('popups.content.failedChangeNumber')
                        })
                      }
                    },
                    onCancel () {
                      vm.$store.commit('popupModule/setClosed')
                    }
                  }
                }
              })
            }
          }
        }
      })
    },

    async handleEmailChangeButtonClick (): Promise<void> {
      const vm = this as any
      vm.emailForm.fields.email.loading = true
      const newEmail = vm.emailForm.fields.email.value
      const response = await vm.$services.authorization.user.changeEmailBegin({ email: newEmail })
      vm.emailForm.fields.email.loading = false
      const sessionId = response.data.session_id
      if (!sessionId) return vm.serverErrorHandler(response.data.errors)

      const currentConfirmationType = vm.getUserData.confirmation

      vm.$store.dispatch('popupModule/openPopup', {
        type: 'confirmPopup',
        letDefaultClose: true,
        title: vm.$t('popups.titles.changeEmail'),
        content: vm.$t('popups.content.loginWillChanged'),
        actions: [
          {
            title: vm.$t('popups.actions.continue'),
            isAccent: true,
            callback () {
              if (currentConfirmationType === SecondFactorTypes.yubikey) {
                vm.$store.dispatch('popupModule/openPopup', {
                  type: ActivePopupTypes.action,
                  letDefaultClose: true,
                  title: vm.$t('popups.titles.changeEmail'),
                  content: vm.$t('popups.content.confirmOldEmail'),
                  slot: {
                    name: 'commonConfirmEmailPopupChunk',
                    data: {
                      codes: {
                        email: 'change_email_old'
                      }
                    },
                    on: {
                      async onSubmit (data: any) {
                        vm.$store.commit('popupModule/setSlotData', { isLoading: true })
                        const validateResponse = await vm.$services.authorization.user.validateSession({ payload: { email: data.code_email }, sessionId })
                        vm.$store.commit('popupModule/setSlotData', { isLoading: false })
                        if (validateResponse.status === 202) {
                          const yubiKeyChangeEmail = (): void => {
                            vm.YubikeyAuth(StepByStepGlossary.changeEmail, null, async (authResponse: any) => {
                              if (!authResponse.success) return vm.serverErrorHandler(authResponse.errors)
                              vm.$store.commit('popupModule/setClosed')
                              vm.$store.dispatch('popupModule/openPopup', {
                                type: ActivePopupTypes.action,
                                letDefaultClose: true,
                                title: vm.$t('popups.titles.changeEmail'),
                                content: vm.$t('popups.content.confirmNewEmail'),
                                slot: {
                                  name: 'commonConfirmEmailPopupChunk',
                                  data: {
                                    newEmail,
                                    codes: {
                                      email: 'change_email_new'
                                    }
                                  },
                                  on: {
                                    async onSubmit (data: any) {
                                      vm.$store.commit('popupModule/setSlotData', { isLoading: true })
                                      const validateResponse = await vm.$services.authorization.user.validateSession({ payload: { new_email: data.code_email }, sessionId })
                                      if (validateResponse.status === 200) {
                                        const response = await vm.$services.authorization.user.changeEmailComplete({ sessionId })
                                        if (response.status === 200) {
                                          vm.$store.dispatch('alertsBus/openAlert', {
                                            title: vm.$t('alerts.titles.success'),
                                            type: 'success',
                                            autoDelete: true,
                                            content: vm.$t('alerts.content.emailAddressChangedTo', { newEmail })
                                          })
                                          vm.$store.dispatch('user/loadUser')
                                          vm.$store.commit('popupModule/setClosed')
                                        } else {
                                          vm.$store.dispatch('alertsBus/openAlert', {
                                            title: vm.$t('popups.titles.error'),
                                            type: 'error',
                                            autoDelete: true,
                                            content: vm.$t('alerts.content.failedToChangeEmail')
                                          })
                                          vm.$store.commit('popupModule/setClosed')
                                        }
                                        vm.emailEdit = false
                                        vm.emailForm.fields.email.value = ''
                                      } else { vm.$store.commit('popupModule/setSlotData', { responseErrors: validateResponse.data.errors }) }
                                      vm.$store.commit('popupModule/setSlotData', { isLoading: false })
                                    }
                                  }
                                }
                              })
                            }, sessionId, yubiKeyChangeEmail)
                            vm.emailEdit = false
                            vm.emailForm.fields.email.value = ''
                          }
                          yubiKeyChangeEmail()
                        } else { vm.$store.commit('popupModule/setSlotData', { responseErrors: validateResponse.errors }) }
                      }
                    }
                  }
                })
                return
              }
              // SMS GA part
              vm.$store.dispatch('popupModule/openPopup', {
                type: ActivePopupTypes.action,
                letDefaultClose: true,
                title: vm.$t('popups.titles.changeEmail'),
                content: vm.$t('popups.content.confirmOperation'),
                slot: {
                  name: `email${currentConfirmationType.toUpperCase()}ConfirmPopupChunk`,
                  data: {
                    endpointName: StepByStepGlossary.validateSession,
                    name: StepByStepGlossary.changeEmail,
                    codes: response.data.confirmations,
                    sessionId,
                    steps: 3
                  },
                  on: {
                    async onSuccess () {
                      vm.$store.dispatch('popupModule/openPopup', {
                        type: ActivePopupTypes.action,
                        letDefaultClose: true,
                        title: vm.$t('popups.titles.changeEmail'),
                        content: vm.$t('popups.titles.confirmNewEmail'),
                        slot: {
                          name: 'commonConfirmEmailPopupChunk',
                          data: {
                            newEmail,
                            codes: {
                              email: 'change_email_new'
                            }
                          },
                          on: {
                            async onSubmit (data: any) {
                              vm.$store.commit('popupModule/setSlotData', { isLoading: true })
                              const validateResponse = await vm.$services.authorization.user.validateSession({ payload: { new_email: data.code_email }, sessionId })
                              if (validateResponse.status === 200) {
                                const response = await vm.$services.authorization.user.changeEmailComplete({ sessionId })
                                if (response.status === 200) {
                                  vm.$store.dispatch('alertsBus/openAlert', {
                                    title: vm.$t('alerts.titles.success'),
                                    type: 'success',
                                    autoDelete: true,
                                    content: vm.$t('alerts.content.emailAddressChangedTo', { newEmail })
                                  })
                                  vm.$store.dispatch('user/loadUser')
                                  vm.$store.commit('popupModule/setClosed')
                                } else {
                                  vm.$store.dispatch('alertsBus/openAlert', {
                                    title: vm.$t('popups.titles.error'),
                                    type: 'error',
                                    autoDelete: true,
                                    content: vm.$t('alerts.content.failedToChangeEmail')
                                  })
                                  vm.$store.commit('popupModule/setClosed')
                                }
                                vm.$store.commit('popupModule/setSlotData', { isLoading: true })
                                vm.emailEdit = false
                                vm.emailForm.fields.email.value = ''
                              } else {
                                vm.$store.commit('popupModule/setSlotData', { responseErrors: validateResponse.data.errors })
                              }
                              vm.$store.commit('popupModule/setSlotData', { isLoading: false })
                            }
                          }
                        }
                      })
                    }
                  }
                }
              })
            }
          },
          {
            title: vm.$t('popups.actions.cancel'),
            isAccent: false,
            callback () {
              vm.$store.commit('popupModule/setClosed')
            }
          }
        ]
      })
    },

    async handleClickSetGA (): Promise<void> {
      const vm = this as any
      vm.GAPlugLoading = true

      const currentConfirmationType = vm.getUserData.confirmation

      if (currentConfirmationType === SecondFactorTypes.yubikey) {
        const yubikeySetGA = (): void => {
          vm.YubikeyAuth(StepByStepGlossary.setGA, 'add_ga', async (authResponse: any, sessionId: string) => {
            vm.GAPlugLoading = false
            if (!authResponse.success) return vm.serverErrorHandler(authResponse.errors)
            const response = await vm.$services.authorization.user.create2FA({ sessionId })
            if (response.status === 200) {
              vm.$store.dispatch('popupModule/openPopup', {
                type: ActivePopupTypes.action,
                letDefaultClose: true,
                title: vm.$t('popups.titles.connectGA'),
                slot: {
                  name: 'GASetupContinuePopupChunk',
                  data: response.data
                }
              })
            } else {
              vm.$store.dispatch('alertsBus/openAlert', {
                title: vm.$t('popups.titles.error'),
                type: 'error',
                autoDelete: true,
                content: vm.$t('alerts.content.failedGAConnect')
              })
              vm.$store.commit('popupModule/setClosed')
            }
          }, null, yubikeySetGA)
        }
        yubikeySetGA()
        return
      }

      const response = await vm.$services.authorization.user.confirmSession({ sessionId: 'add_ga' })
      vm.GAPlugLoading = false
      const sessionId = response.data.session_id

      if (!sessionId) return vm.serverErrorHandler(response.data.errors)
      vm.$store.dispatch('popupModule/openPopup', {
        type: ActivePopupTypes.action,
        letDefaultClose: true,
        title: vm.$t('popups.titles.connectGA'),
        content: vm.$t('popups.content.confirmOperation'),
        slot: {
          name: 'commonConfirmSMSPopupChunk',
          data: {
            codes: response.data.confirmations,
            sessionId
          },
          on: {
            async onSubmit (data: any) {
              vm.$store.commit('popupModule/setSlotData', { isLoading: true })
              const sessionResponse = await vm.$services.authorization.user.validateSession({ payload: { sms: data.code_sms }, sessionId })
              vm.$store.commit('popupModule/setSlotData', { responseErrors: sessionResponse.data.errors })
              if (sessionResponse.status === 200) {
                const response = await vm.$services.authorization.user.create2FA({ sessionId })
                vm.$store.commit('popupModule/setSlotData', { isLoading: false })
                if (response.status === 200) {
                  vm.$store.dispatch('popupModule/openPopup', {
                    type: ActivePopupTypes.action,
                    letDefaultClose: true,
                    title: vm.$t('popups.titles.connectGA'),
                    content: '',
                    slot: {
                      name: 'GASetupContinuePopupChunk',
                      data: response.data
                    }
                  })
                } else {
                  vm.$store.dispatch('alertsBus/openAlert', {
                    title: vm.$t('popups.titles.error'),
                    type: 'error',
                    autoDelete: true,
                    content: vm.$t('alerts.content.failedGAConnect')
                  })
                  vm.$store.commit('popupModule/setClosed')
                }
              } else {
                vm.$store.commit('popupModule/setSlotData', { responseErrors: sessionResponse.data.errors, isLoading: false })
                vm.$store.commit('popupModule/setSlotData', { isLoading: false })
              }
            },
            onCancel () {
              vm.$store.commit('popupModule/setClosed')
            }
          }
        }
      })
    },

    async handleClickUnsetGA (): Promise<void> {
      const vm = this as any
      vm.GAPlugLoading = true
      const sessionResponse = await vm.$services.authorization.user.confirmSession({ sessionId: 'delete_ga' })
      vm.GAPlugLoading = false
      const sessionId = sessionResponse.data.session_id
      if (!sessionId) return vm.serverErrorHandler(sessionResponse.data.errors)

      const currentConfirmationType = vm.getUserData.confirmation
      const enterYubikeyConfirm = vm.current2FA === SecondFactorTypes.ga ? vm.$t('popups.content.enterWithout2FA') : ''

      const yubiKeyUnsetGA = (): void => {
        vm.YubikeyAuth(StepByStepGlossary.unsetGA, 'delete_ga', async (authResponse: any, sessionId: string) => {
          vm.GAPlugLoading = false
          if (!authResponse.success) return vm.serverErrorHandler(authResponse.errors)
          const response = await vm.$services.authorization.user.delete2FA({ sessionId })
          if (response.status === 204) {
            vm.$store.dispatch('alertsBus/openAlert', {
              title: vm.$t('alerts.titles.success'),
              type: 'success',
              autoDelete: true,
              content: vm.$t('alerts.content.gaDisconnected')
            })
            vm.$store.dispatch('user/loadUser')
            vm.$store.commit('popupModule/setClosed')
          } else { vm.$store.commit('popupModule/setSlotData', { responseErrors: sessionResponse.errors }) }
        }, null, yubiKeyUnsetGA)
      }

      const commonChangeMethod = async () => {
        vm.$store.dispatch('popupModule/openPopup', {
          type: ActivePopupTypes.action,
          letDefaultClose: true,
          title: vm.$t('popups.titles.disconnectGA'),
          content: vm.$t('popups.content.confirmOperation'),
          slot: {
            name: 'emailSMSConfirmPopupChunk',
            data: {
              endpointName: StepByStepGlossary.validateSession,
              name: StepByStepGlossary.delete2FA,
              sessionId,
              steps: 3,
              codes: sessionResponse.data.confirmations,
              isLoading: false,
              changeKycAccordionStatus: vm.changeKycAccordionStatus
            },
            on: {
              async onSuccess (): Promise<void> {
                vm.$store.commit('popupModule/setSlotData', { isLoading: true })
                const response = await vm.$services.authorization.user.delete2FA({ sessionId })
                vm.$store.commit('popupModule/setSlotData', { isLoading: false })
                if (response.status === 204) {
                  vm.$store.dispatch('alertsBus/openAlert', {
                    title: vm.$t('alerts.titles.success'),
                    type: 'success',
                    autoDelete: true,
                    content: vm.$t('alerts.content.gaDisconnected')
                  })
                  vm.$store.dispatch('user/loadUser')
                  vm.$store.commit('popupModule/setClosed')
                } else vm.$store.commit('popupModule/setSlotData', { responseErrors: sessionResponse.errors })
              }
            }
          }
        })
      }
      const startReset = currentConfirmationType === SecondFactorTypes.yubikey ? yubiKeyUnsetGA : commonChangeMethod

      if (enterYubikeyConfirm) {
        vm.$store.dispatch('popupModule/openPopup', {
          type: 'confirmPopup',
          letDefaultClose: true,
          title: vm.$t('popups.titles.disable2FAOnEnter'),
          content: `${enterYubikeyConfirm}`,
          actions: [
            {
              title: vm.$t('popups.actions.continue'),
              isAccent: true,
              callback: () => startReset()
            },
            {
              title: vm.$t('popups.actions.cancel'),
              isAccent: false,
              callback: () => vm.$store.commit('popupModule/setClosed')
            }]
        })
        return
      }

      startReset()
    },

    async handleClickSecondFactorToggler () {
      const vm = this as any

      vm.secondFactorEnabled = !vm.secondFactorEnabled
      vm.secondFactorForm.editing = vm.secondFactorEnabled

      if (!vm.secondFactorEnabled && vm.current2FA !== 'off') {
        vm.$store.dispatch('popupModule/openPopup', {
          type: 'confirmPopup',
          letDefaultClose: true,
          title: vm.$t('popups.titles.disable2FAOnEnter'),
          content: vm.$t('popups.content.confirmOperationWillNotAble'),
          actions: [
            {
              title: vm.$t('popups.actions.continue'),
              isAccent: true,
              async callback () {
                if (vm.current2FA === SecondFactorTypes.yubikey) {
                  const yubikeySecondFactorToggler = () => {
                    vm.YubikeyAuth(StepByStepGlossary.disable2FAOnEnter, 'set_two_factor_off', async (authResponse: any, sessionId: string) => {
                      if (!authResponse.success) return vm.serverErrorHandler(authResponse.errors)
                      const response = await vm.$services.authorization.user.switch2FA({ sessionId })
                      if (response.status === 200) {
                        vm.$store.dispatch('alertsBus/openAlert', {
                          title: vm.$t('alerts.titles.attention'),
                          type: 'warning',
                          autoDelete: true,
                          content: vm.$t('alerts.content.enterToLkWithoutConfirmation')
                        })
                        vm.$store.commit('popupModule/setClosed')
                      } else {
                        vm.$store.dispatch('alertsBus/openAlert', {
                          title: vm.$t('popups.titles.error'),
                          type: 'error',
                          autoDelete: true,
                          content: vm.$t('alerts.contents.failedToDisableAdditionalConfirm')
                        })
                        vm.$store.commit('popupModule/setClosed')
                      }
                    }, null, yubikeySecondFactorToggler)
                  }
                  yubikeySecondFactorToggler()
                  return
                }

                const response = await vm.$services.authorization.user.confirmSession({ sessionId: 'set_two_factor_off' })
                const sessionId = response.data.session_id

                if (!sessionId) return vm.serverErrorHandler(response.data.errors)

                vm.$store.dispatch('popupModule/openPopup', {
                  type: ActivePopupTypes.action,
                  letDefaultClose: true,
                  title: vm.$t('popups.titles.disable2FAOnEnter'),
                  content: vm.$t('popups.content.confirmOperation'),
                  slot: {
                    name: `email${vm.current2FA.toUpperCase()}ConfirmPopupChunk`,
                    data: {
                      endpointName: StepByStepGlossary.validateSession,
                      name: StepByStepGlossary.disable2FAOnEnter,
                      sessionId,
                      codes: response.data.confirmations,
                      steps: 3,
                      changeKycAccordionStatus: vm.changeKycAccordionStatus
                    },
                    on: {
                      async onSuccess () {
                        vm.$store.commit('popupModule/setSlotData', { isLoading: true })
                        const response = await vm.$services.authorization.user.switch2FA({ sessionId })
                        vm.$store.commit('popupModule/setSlotData', { isLoading: false })
                        if (response.status === 200) {
                          vm.$store.dispatch('alertsBus/openAlert', {
                            title: vm.$t('alerts.titles.attention'),
                            type: 'warning',
                            autoDelete: true,
                            content: vm.$t('alerts.content.enterToLkWithoutConfirmation')
                          })
                          vm.$store.commit('popupModule/setClosed')
                        } else {
                          vm.$store.dispatch('alertsBus/openAlert', {
                            title: vm.$t('popups.titles.error'),
                            type: 'error',
                            autoDelete: true,
                            content: vm.$t('alerts.contents.failedToDisableAdditionalConfirm')
                          })
                          vm.$store.commit('popupModule/setClosed')
                        }
                      }
                    }
                  }
                })
              }
            },
            {
              title: vm.$t('popups.actions.cancel'),
              isAccent: false,
              callback () {
                vm.$store.commit('popupModule/setClosed')
              }
            }
          ]
        })
      }
    },

    handleCancelEditSecondFactorForm (): void {
      const vm = this as any
      vm.secondFactorForm.editing = false
      if (vm.current2FA === 'off') {
        vm.secondFactorForm.fields.secondFactorType.value = ''
        vm.secondFactorEnabled = false
      }
    },

    async handleSubmitSecondFactorForm (): Promise<void> {
      const vm = this as any
      vm.secondFactorPlugLoading = true

      const secondFactor = vm.secondFactorForm.fields.secondFactorType.value
      const response = await vm.$services.authorization.user.confirmSession({ sessionId: `set_two_factor_${secondFactor}` })
      const sessionId = response.data.session_id

      if (!sessionId) return vm.serverErrorHandler(response.data.errors)

      const commonChange2FA = (sessionId: string, codes: Array<string>) => {
        vm.secondFactorPlugLoading = false
        vm.$store.dispatch('popupModule/openPopup', {
          type: ActivePopupTypes.action,
          letDefaultClose: true,
          title: vm.$t('popups.titles.connect2FAToEnter', { operation: vm.operationTypeString(secondFactor) }),
          slot: {
            name: `commonConfirm${secondFactor.toUpperCase()}PopupChunk`,
            data: {
              codes,
              isLoading: false,
              sessionId,
              changeKycAccordionStatus: vm.changeKycAccordionStatus,
              responseErrors: []
            },
            on: {
              async onSubmit (data: any) {
                vm.$store.commit('popupModule/setSlotData', { isLoading: true })
                const sessionResponse = await vm.$services.authorization.user.validateSession({ payload: { [secondFactor]: data[`code_${secondFactor}`] }, sessionId })
                if (!sessionResponse.success) {
                  vm.$store.commit('popupModule/setSlotData', { responseErrors: sessionResponse.data.errors, isLoading: false })
                  return
                }

                const response = await vm.$services.authorization.user.switch2FA({ sessionId })

                vm.$store.commit('popupModule/setClosed')
                vm.secondFactorForm.editing = false

                vm.$store.commit('popupModule/setSlotData', { isLoading: false })
                if (response.status === 200) {
                  vm.$store.dispatch('alertsBus/openAlert', {
                    title: vm.$t('alerts.titles.success'),
                    type: 'success',
                    autoDelete: true,
                    content: vm.$t('alerts.content.enterToLkNowIs', { secondFactor: vm.operationTypeString(secondFactor) })
                  })
                  vm.doCheck2FA()
                } else {
                  vm.$store.dispatch('alertsBus/openAlert', {
                    title: vm.$t('popups.titles.error'),
                    type: 'error',
                    autoDelete: true,
                    content: vm.$t('alerts.contents.failedToChangeMethod', { secondFactor: vm.operationTypeString(secondFactor) })
                  })
                }
              },
              onCancel () {
                vm.$store.commit('popupModule/setClosed')
                vm.secondFactorForm.editing = false
              }
            }
          }
        })
      }

      if (secondFactor === SecondFactorTypes.yubikey || vm.current2FA === SecondFactorTypes.yubikey) {
        const yubikeySubmitSecondFactor = () => {
          vm.secondFactorPlugLoading = false
          vm.YubikeyAuth(StepByStepGlossary.submitSecondFactor, `set_two_factor_${secondFactor}`, async (authResponse: any, sessionId: string, codes: any) => {
            if (!authResponse.success) return vm.serverErrorHandler(authResponse.errors)

            if (vm.current2FA === SecondFactorTypes.yubikey) return commonChange2FA(sessionId, codes)

            const response = await vm.$services.authorization.user.switch2FA({ sessionId })

            vm.$store.commit('popupModule/setClosed')
            vm.secondFactorForm.editing = false

            if (response.status === 200) {
              vm.$store.dispatch('alertsBus/openAlert', {
                title: vm.$t('alerts.titles.success'),
                type: 'success',
                autoDelete: true,
                content: vm.$t('alerts.content.enterToLkNowIs', { secondFactor: vm.operationTypeString(secondFactor) })
              })
              vm.doCheck2FA()
            } else {
              vm.$store.dispatch('alertsBus/openAlert', {
                title: vm.$t('popups.titles.error'),
                type: 'error',
                autoDelete: true,
                content: vm.$t('alerts.contents.failedToChangeMethod', { secondFactor: vm.operationTypeString(secondFactor) })
              })
            }
          }, sessionId, yubikeySubmitSecondFactor)
        }
        yubikeySubmitSecondFactor()
      } else { commonChange2FA(sessionId, response.data.confirmations) }
    },

    async handleSubmitOperationsForm (): Promise<void> {
      const vm = this as any
      vm.operationChangeLoading = true

      if (vm.operationsForm.fields.confirmationType.value !== vm.getUserData.confirmation) {
        const settedSecondFactor = vm.operationsForm.fields.confirmationType.value
        const currentSecondFactor = vm.getUserData.confirmation
        const sessionResponse = await vm.$services.authorization.user.confirmSession({ sessionId: `set_confirmation_${settedSecondFactor}` })
        vm.operationChangeLoading = false
        const sessionId = sessionResponse.data.session_id
        if (!sessionId) return vm.serverErrorHandler(sessionResponse.data.errors)

        const yubikeySubmitOperations = (sessionId: string, useCommonCheck?: boolean) => {
          vm.YubikeyAuth(StepByStepGlossary.submitOperations, `set_confirmation_${settedSecondFactor}`, async (authResponse: any, sessionId: string) => {
            vm.operationChangeLoading = false
            if (!authResponse.success) return vm.serverErrorHandler(authResponse.errors)
            if (useCommonCheck) {
              commonCheck()
              return
            }
            const response = await vm.$services.authorization.user.switchUserConfirmMethod({ sessionId })
            if (response.status === 200) {
              vm.$store.dispatch('alertsBus/openAlert', {
                title: vm.$t('alerts.titles.success'),
                type: 'success',
                autoDelete: true,
                content: vm.$t('alerts.content.operationNowConfirmedVia', { settedSecondFactor: vm.operationTypeString(settedSecondFactor) })
              })
              vm.$store.dispatch('user/loadUser')
            } else {
              vm.secondFactorForm.fields.secondFactorType.value = vm.getUserData.confirmation
              vm.$store.dispatch('alertsBus/openAlert', {
                title: vm.$t('popups.titles.error'),
                type: 'error',
                autoDelete: true,
                content: vm.$t('alerts.content.failedToChangeOperation')
              })
            }
            vm.$store.commit('popupModule/setClosed')
            vm.operationsForm.editing = false
          }, sessionId, yubikeySubmitOperations)
        }

        const commonCheck = () => vm.$store.dispatch('popupModule/openPopup', {
          type: ActivePopupTypes.action,
          letDefaultClose: true,
          title: vm.$t('popups.content.connect2FAOnOperation', { settedSecondFactor: vm.operationTypeString(settedSecondFactor) }),
          content: '',
          slot: {
            name: `commonConfirm${settedSecondFactor.toUpperCase()}PopupChunk`,
            data: {
              codes: sessionResponse.data.confirmations,
              isLoading: false,
              responseErrors: []
            },
            on: {
              async onSubmit (data: any): Promise<void> {
                vm.$store.commit('popupModule/setSlotData', { isLoading: true })
                const validateSessionResponse = await vm.$services.authorization.user.validateSession({ payload: { [settedSecondFactor]: data[`code_${settedSecondFactor}`] }, sessionId })
                if (validateSessionResponse.status === 202) {
                  commonCheck()
                  return
                }
                if (validateSessionResponse.status === 200) {
                  const response = await vm.$services.authorization.user.switchUserConfirmMethod({ sessionId })
                  if (response.status === 200) {
                    vm.$store.dispatch('alertsBus/openAlert', {
                      title: vm.$t('alerts.titles.success'),
                      type: 'success',
                      autoDelete: true,
                      content: vm.$t('alerts.content.operationNowConfirmedVia', { settedSecondFactor: vm.operationTypeString(settedSecondFactor) })
                    })
                    vm.$store.dispatch('user/loadUser')
                  } else {
                    vm.secondFactorForm.fields.secondFactorType.value = vm.getUserData.confirmation
                    vm.$store.dispatch('alertsBus/openAlert', {
                      title: vm.$t('popups.titles.error'),
                      type: 'error',
                      autoDelete: true,
                      content: vm.$t('alerts.content.failedToChangeOperation')
                    })
                  }
                  vm.$store.commit('popupModule/setClosed')
                } else {
                  vm.$store.commit('popupModule/setSlotData', { responseErrors: validateSessionResponse.data.errors, isLoading: false })
                }
                vm.operationsForm.editing = false
              },
              onCancel () {
                vm.operationsForm.editing = false
                vm.operationsForm.fields.confirmationType.value = vm.getUserData.confirmation
                vm.$store.commit('popupModule/setClosed')
              }
            }
          }
        })

        if (settedSecondFactor === SecondFactorTypes.yubikey) {
          yubikeySubmitOperations(sessionId)
          return
        }

        if (currentSecondFactor !== SecondFactorTypes.yubikey) {
          commonCheck()
          return
        }

        vm.$store.dispatch('popupModule/openPopup', {
          type: 'confirmPopup',
          letDefaultClose: true,
          title: vm.$t('popups.content.connect2FAOnOperation', { settedSecondFactor }),
          content: vm.$t('popups.content.confirmOperationWillNotAble'),
          actions: [
            {
              title: vm.$t('popups.actions.continue'),
              isAccent: true,
              callback: () => yubikeySubmitOperations(sessionId, true)
            },
            {
              title: vm.$t('popups.actions.cancel'),
              isAccent: false,
              callback: () => vm.$store.commit('popupModule/setClosed')
            }]
        })
      }
      vm.operationsForm.editing = false
    },

    doCheck2FA (closeSecondFactorEnabled = true): void {
      const vm = this as any
      async function getState2FA (): Promise<void> {
        const response2FA = await vm.$services.authorization.user.getState2FA(null)
        if (response2FA.success) {
          vm.available2FAMethods = response2FA.data.available.filter((method: any) => method !== 'off')
          if (Object.values(SecondFactorTypes).includes(response2FA.data.current)) {
            vm.secondFactorEnabled = true
            vm.current2FA = response2FA.data.current
            vm.secondFactorForm.fields.secondFactorType.value = vm.current2FA
          } else {
            vm.current2FA = 'off'
            vm.secondFactorForm.fields.secondFactorType.value = ''
            if (closeSecondFactorEnabled) {
              vm.secondFactorEnabled = false
            }
          }
        }
      }
      async function check2FA (): Promise<void> {
        const response = await vm.$services.authorization.user.check2FA(null)
        if (response.success) {
          vm.GASet = response.data.ga
          getState2FA()
        }
      }
      check2FA()
    }
  },
  mounted (): void {
    const vm = this as any
    if (vm.$store.getters['user/getUserData']?.confirmation) {
      vm.operationsForm.fields.confirmationType.value = vm.$store.getters['user/getUserData'].confirmation
    }
  },
  created (): void {
    const vm = this as any
    const urlParams = new URLSearchParams(window.location.search)
    const verificationShow = urlParams.get('verification-show') === 'true'
    if (verificationShow) {
      this.panel = null
    }
    const twoFactorOpen = urlParams.get('two-factor-show') === 'true'
    if (twoFactorOpen) {
      this.handleClickSecondFactorToggler()
    }
    vm.doCheck2FA(!twoFactorOpen)
    vm.getYubikeysList()
  }
})
