import Vue from 'vue'
import { toMarkdown } from 'mdast-util-to-markdown'
import { IQuestionItem } from '@/components/pages/whitelistID/components/account/components/access-repair/accessRepairQuestionnaire/accessRepairQuestions/types'

export const lastCallToSupportMixin = Vue.extend({
  watch: {
    'form.fields.questionnaire.lastCallToSupport.value': {
      handler (newState) {
        this.form.fields.questionnaire.lastCallToSupport.valid = Boolean(newState)
      },
      deep: true
    }
  },
  methods: {
    toMDlastCallToSupport (field: IQuestionItem): any {
      if (!field) return []
      return [
        {
          type: 'heading',
          depth: 3,
          children: [
            {
              type: 'text',
              value: field.title
            }
          ]
        },
        {
          type: 'paragraph',
          children: [
            {
              type: 'text',
              value: field.value
            }
          ]
        }
      ]
    }
  }
})
