
import Vue from 'vue'
import { mapGetters } from 'vuex'
import socialNetworks from './components/socialNetworks/index.vue'
import safety from './components/safety/index.vue'
import kyc from './components/kyc/index.vue'
import accessRepairStatus from '@/components/pages/whitelistID/components/account/components/access-repair/accessRepairStatus/index.vue'
import PassportNotification from '@/components/pages/whitelistID/components/account/components/notifications/index.vue'
import { AccessStatus } from './components/types'
import { systemPromoCodes } from '@/constants/baseConstatnts'

export default Vue.extend({
  name: 'myAccountPage',
  data () {
    return {
      kycLevels: [],
      currentKycLevelData: {},
      kycOpen: false,
      accessRepairOpen: false,
      currentYPosition: 0,
      scrollTo: { x: 0, y: 0 },
      accessRepairData: {
        confirmationMethod: '',
        status: '',
        reason: '',
        loginMethod: '',
        email: '',
        currentRecoveryTypes: []
      }
    }
  },
  watch: {
    'getUserData.currentAccessRepairMethods' (value: any) {
      if (value.length > 1) this.kycOpen = true
    }
  },
  computed: {
    ...mapGetters('user', ['getUserData']),
    ...mapGetters('notifications', ['getPassport']),
    getUserFullName () {
      const vm = this as any
      return vm.getUserData.last_name + ' ' + vm.getUserData.first_name + ' ' + (vm.getUserData.patronymic ?? '')
    },
    tooltipPromocode () {
      const { promo } = this.getUserData
      return systemPromoCodes.includes(promo) ? this.$t('registration.refSystemCodeExplain') : this.$t('registration.refCodeExplain', { name: promo })
    }
  },
  components: {
    socialNetworks,
    safety,
    kyc,
    accessRepairStatus,
    PassportNotification
  },
  mounted () {
    const urlParams = new URLSearchParams(window.location.search)
    const verificationShow = urlParams.get('verification-show') === 'true'
    if (verificationShow) {
      this.kycOpen = true
    }
  },
  methods: {
    scrollToCurrentRepair () {
      this.scrollTo.y += 0.1
      this.accessRepairOpen = true
    },
    changeKycAccordionStatus (value: boolean) {
      const vm = this as any
      vm.scrollTo.y += 0.1
      const scrollToElement: HTMLElement | null = document.getElementById('kyc-extended')
      const nextScrollPosition = Math.abs(scrollToElement?.getBoundingClientRect().top ?? 0)
      vm.kycOpen = value
      if (!value) return
      vm.scrollTo.y = nextScrollPosition
    },
    async getKycLevels () {
      const kycLevelsResponse = await this.$services.authorization.user.kycLevels()
      if (!kycLevelsResponse.success) return
      this.kycLevels = kycLevelsResponse.data
      this.currentKycLevelData = this.kycLevels.find((level: any) => level.levelName === this.getUserData.verificationRights.levelName)
    },
    async getAccessInfo (created?: boolean) {
      await this.$store.dispatch('user/loadUser')
      this.recoveryId = this.getUserData?.verificationRights?.currentRecovery || this.$route.query.recoveryId
      if (this.$route.query.recoveryId) this.$router.replace({ recoveryId: null })
      if (!this.recoveryId) return
      const getAccessInfo = await this.$services.authorization.user.getAccessInfo({ recoveryId: this.recoveryId })
      const isActive = getAccessInfo.data.status === AccessStatus.work || getAccessInfo.data.status === AccessStatus.repeat
      if (isActive) this.$store.commit('user/setCurrentAccessRepairMethods', getAccessInfo.data.currentRecoveryTypes)
      this.accessRepairData = getAccessInfo.data
      if (created && isActive) this.$emit('openAccessRepair')
    }
  },
  async created () {
    await this.getKycLevels()
    this.getAccessInfo(true)
  }
})
