
import Vue from 'vue'
import { mapGetters } from 'vuex'
import FileUpload from '@/components/global/fileUpload/index.vue'

export default Vue.extend({
  name: 'accessRepairStatus',
  props: {
    accessRepairData: {
      type: Object,
      required: true,
      default: () => ({})
    },
    accessRepairAccordionStatus: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  data () {
    return {
      openedPanel: null as number | null,
      recoveryId: '',
      form: {
        isLoading: false,
        fields: {
          comment: '',
          file: null as File | null
        }
      }
    }
  },
  watch: {
    accessRepairAccordionStatus (value: boolean) {
      if (value) this.openedPanel = 0
    },
    openedPanel (value: number) {
      if (!value) this.$emit('hideAccessRepairPanel')
    }
  },
  computed: {
    ...mapGetters('user', ['getUserData']),
    multipleRepairContent () {
      return this.accessRepairData.currentRecoveryTypes.map((type: string) => this.getContentText(type))
    },
    isRepairInProgress () {
      return this.accessRepairData.currentRecoveryTypes[0] && (this.accessRepairData.status === 'work' || this.accessRepairData.status === 'repeat')
    }
  },
  methods: {
    accessRepairTitle (): string {
      const type = this.accessRepairData.currentRecoveryTypes[0]
      const title = type === 'sms' ? this.$t('accessRepair.phoneRepair') : this.getContentText(type)
      return this.accessRepairData.currentRecoveryTypes.length === 1 ? title : this.$t('accessRepair.multipleReset')
    },
    getContentText (methodType: string, lowerCase?: boolean) {
      const isOperation = methodType === this.accessRepairData.confirmationMethod
      const isLoginText = methodType === this.accessRepairData.loginMethod ? 'и на вход' : ''
      const formattedName = methodType === 'sms' ? this.$t('common.SMS') : methodType === 'ga' ? 'Google Authenticator' : 'Yubikey'
      const text = isOperation ? this.$t('accessRepair.reset2FaOnOperation', { formattedName, isLoginText }) : this.$t('accessRepair.resetAccess', { formattedName })
      return lowerCase ? text.charAt(0).toLowerCase() + text.slice(1) : text
    },
    async resendResetAccess () {
      const formData = new FormData()
      formData.append('files', this.form.fields.file)
      formData.append('comment', this.form.fields.comment)
      this.form.isLoading = true
      const recoveryId = await this.getUserData.verificationRights.currentRecovery
      const resetAccessResponse = await this.$services.authorization.user.resetAccess({ recoveryId, payload: formData })
      this.form.isLoading = false
      this.openedPanel = null

      setTimeout(() => {
        this.form.fields.comment = ''
        this.form.fields.file = null
      }, 400)
      const vm = this as any
      this.$store.dispatch('alertsBus/openAlert', {
        title: vm.$t('alerts.titles.info'),
        type: resetAccessResponse.success ? 'info' : 'error',
        autoDelete: true,
        content: resetAccessResponse.success ? vm.$t('alerts.content.requestResetUpdated') : resetAccessResponse.data.errors.join('')
      })
      if (resetAccessResponse.success) {
        setTimeout(() => {
          this.$emit('updateInfo')
        }, 1000)
      }
    }
  },
  components: { FileUpload }
})
