
import Vue from 'vue'
import { mapGetters } from 'vuex'
import { LevelStatuses } from '../../types'
import loadLine from './../loadLine/index.vue'

export default Vue.extend({
  name: 'kyc-extended',
  data () {
    return {
      openedPanel: null as number | null
    }
  },
  props: {
    status: {
      type: String,
      required: true,
      default: ''
    },
    levelData: {
      type: Object,
      required: true,
      default: null
    },
    linkData: {
      type: Object,
      required: true,
      default: () => ({
        status: ''
      })
    }
  },
  mounted () {
    const openedPanelValue = this.status === LevelStatuses.inProgress ? 0 : 1
    this.openedPanel = openedPanelValue
  },
  computed: {
    ...mapGetters('user', ['getUserData']),
    buttonTitle () {
      const titleMap = {
        request: 'continue',
        process: 'checkDetails'
      } as { [key: string]: string }
      return this.linkData.hasError ? this.$t('main.accountVerify.continue') : this.$t(`main.accountVerify.${titleMap[this.linkData.status]}`)
    }
  },
  components: { loadLine }
})
