
import Vue from 'vue'

export default Vue.extend({
  name: 'APIKeysQuantity',
  props: {
    value: {
      type: Number,
      required: true
    }
  },
  data: () => ({
    numberboxConfig: {
      rules: [],
      touched: false,
      stepSize: 1,
      maskConfig: {
        alias: 'numeric',
        allowMinus: false,
        digits: 2,
        min: 0,
        max: Infinity
      },
      min: 0,
      max: 25
    }
  })
})
