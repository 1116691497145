export const formatDiscordNickname = (username: string): string => {
  if (username.length === 0) {
    return username
  }

  if (username.startsWith('@')) {
    return username
  }

  if (username.includes('#')) {
    return username
  }

  return '@' + username
}
