import a from '@/services/base/config'
const request = async (url: string, method: string, data: any, config?: { [key: string]: any }) => {
  const result: any = {}
  try {
    const response = await a({
      url,
      method,
      data,
      headers: {
        'Cache-Control': 'no-store'
      },
      ...config
    } as any)
    result.success = true
    result.data = response.data
    result.status = response.status
  } catch (error: any) {
    result.status = error.response.status
    result.success = false
    if (error) {
      if (error.response?.data) {
        result.data = error.response.data
      } else {
        result.data = {}
      }
    } else {
      result.data = {}
    }
  }
  return result
}

export default request
