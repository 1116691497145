
import Vue from 'vue'
import moment from 'moment'

export default Vue.extend({
  name: 'lastCallToSupport',
  props: {
    value: {
      type: String,
      required: true
    }
  },
  data: () => ({
    tempDateValue: ''
  }),
  watch: {
    tempDateValue (newValue) {
      this.$emit('onInput', moment(newValue).format('DD.MM.YYYY'))
    }
  },
  methods: {
    getToday (): string {
      return moment().format('YYYY-MM-DD')
    },
    presetDate (subtract: number): void {
      this.tempDateValue = moment().subtract(subtract, 'days').format('YYYY-MM-DD')
    }
  }
})
