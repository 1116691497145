
import Vue from 'vue'
export default Vue.extend({
  name: 'YubikeySetupPermissionsPopupChunk',
  data: () => ({
    form: {
      valid: false,
      fields: {
        permissions: {
          allowLogin: {
            value: false
          },
          allowConfirmations: {
            value: false
          }
        }
      },
      responseErrors: []
    }
  })
})
